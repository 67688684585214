import React, { FC, HTMLAttributes } from 'react'

import { pulse } from 'Assets/styles/keyframes'
import t from 'prop-types'
import styled from 'styled-components'

export interface PlaceholderProps extends HTMLAttributes<HTMLDivElement> {}

export const Placeholder: FC<PlaceholderProps> = () => {
  return <E data-testid="placeholder" />
}

Placeholder.propTypes = {
  children: t.node
}

Placeholder.defaultProps = {
  children: null
}

const E = styled.div`
  animation: ${pulse} 1s infinite;
  display: block;
  min-height: 1.5em;
  width: 100%;
`
