import React, { FC, HTMLAttributes, ReactNode } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

export interface BackdropProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
  icon?: ReactNode;
  nopadding?: boolean;
}

export const Backdrop: FC<BackdropProps> = ({ children, nopadding, icon, title, ...props }) => {
  return (
    <BackdropComponent
      data-testid="Backdrop"
      {...props}
    >
      {children || 'Welcome to @smart Project: <Backdrop /> file'}
    </BackdropComponent>
  )
}

Backdrop.propTypes = {
  children: t.node
}

Backdrop.defaultProps = {
  children: null
}

const BackdropComponent = styled.div`
align-items: flex-start;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
height: 100vh;
justify-content: center;
left: 0;
overflow-x: hidden;
overflow-y: auto;
padding-top: 7vh;
position: fixed;
top: 0;
width: 100vw;
z-index: 100;
`
