import React, { Fragment } from 'react'

import { useT } from './translations'
import Feature from './screens/DashboardPage'

const routes = [
  {
    path: '/dashboard',
    Component: Feature,
    Title: function Title () {
      const t = useT()

      return <Fragment>{t.hello}</Fragment>
    }
  }
]

export default routes
