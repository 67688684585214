/* eslint-disable styled-components-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-for */
import React, { KeyboardEventHandler, ReactNode, useEffect } from 'react'

import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface DropDownProps{
  id?: string
  showOnHover?: boolean,
  arrowPosition?: 'left' | 'right',
  arrowOffset?: number,
  classes?: string
}

interface Props extends DropDownProps{
  title: ReactNode;
  label: string;
  links: {props: any, label: ReactNode}[] | any
  renderItem?: (row: any) => ReactNode;
  children: ReactNode | Function;
  style?: any,
  onEmpty?: ReactNode,
  legendItem?: ReactNode
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function MenuWithArrow ({
  id,
  showOnHover,
  title,
  label,
  links,
  style,
  renderItem,
  children,
  arrowPosition = 'right',
  arrowOffset = 30,
  onEmpty,
  legendItem,
  classes
}:Props) {
  const [checked, toggle] = React.useState(false)
  const hide = () => toggle(false)
  const show = () => toggle(true)
  const refMenu = React.useRef<HTMLElement>(null)
  const ref = React.useRef<HTMLElement & {idx: number}>(null)

  useEffect(() => {
    if (checked) {
      const handleClickOutside = (event) => {
        if (refMenu.current && ref.current && !ref.current.contains(event.target) && !refMenu.current.contains(event.target)) {
          hide()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
    return () => {}
  }, [ref, checked])

  const tab: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!ref.current) return
    const links = ref.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]')
    ref.current.idx = ref.current.idx || 0
    let isNavigationEvent = true
    switch (e.key) {
    case 'Tab':
      e.shiftKey ? ref.current.idx-- : ref.current.idx++
      break
    case 'ArrowDown':
      ref.current.idx++
      break
    case 'ArrowUp':
      ref.current.idx--
      break
    case 'Enter':
      links[ref.current.idx]?.click()
      isNavigationEvent = false
      return hide()
    case 'Escape':
      isNavigationEvent = false
      return hide()
    default:
      isNavigationEvent = false
      return
    }
    if (!isNavigationEvent) return

    e.stopPropagation()
    e.preventDefault()
    // roll
    if (ref.current.idx > links.length) {
      ref.current.idx = 0
    }
    if (ref.current.idx < 0) {
      ref.current.idx = links.length - 1
    }
    if (!links[ref.current.idx]) {
      ref.current.idx = 0
    }

    links[ref.current.idx]?.focus()
  }
  React.useEffect(() => {
    if (!ref.current) {
      return
    }
    ref.current.querySelector<HTMLAnchorElement>('[tabindex]')?.focus()
  }, [checked])
  return (
    <DropDown
      id={id}
      ref={refMenu}
      onClick={() => { checked ? hide() : show() }}
      className={classes}
      style={style}
      arrowOffset={arrowOffset}
      arrowPosition={arrowPosition}
      showOnHover={showOnHover}
      role="presentation"
    >
      <input
        tabIndex={-1}
        name={label}
        title={label}
        aria-label={label}
        role="switch"
        className="arrowMenutoggler"
        type="checkbox"
        checked={checked}
        aria-checked={checked}
        onChange={() => checked ? hide() : show()}
        onClick={e => e.stopPropagation()}
      />

      {checked
        ? (
          <nav
            ref={ref}
            role="presentation"
            onKeyDown={tab}
          >
            <div
              className="dropdown-header"
              style={title ? {} : { border: 0 }}
            >{title}</div>
            <div style={{
              maxHeight: 'calc(100vh - 153px)',
              overflowY: 'auto'
            }}
            >

              {links.length === 0 ? onEmpty : null}
              {links.map((link: any, idx) => renderItem
                ? renderItem(link)
                : link.props.href
                  ? (
                    <a
                      tabIndex={1 + idx}
                      key={link.label}
                      target="_blank"
                      title={link.label}
                      aria-label={link.label}
                      {...link.props}
                    >{link.label}</a>
                  )
                  : (
                    <NavLink
                      tabIndex={1 + idx}
                      key={link.label}
                      title={link.label}
                      aria-label={link.label}
                      {...link.props}
                    >{link.label}</NavLink>
                  ))}
              {legendItem || null}
            </div>

          </nav>
        )
        : null}
      {typeof children !== 'function'
        ? (
          <button
            aria-haspopup
            title={label}
            aria-label={label}
            tabIndex={0}
          >
            {children}
          </button>
        )
        : children()}
    </DropDown>
  )
}

export const DropDown = styled.section<DropDownProps>`
border: 0 !important;
cursor: pointer;
display: inline-flex;
line-height: 44px;
position: relative;
z-index: 99;

> button {
  cursor: pointer;
  display: block;
  font-size: 1.5em;
}

> [type='checkbox'] {
  bottom: 0;
  cursor: pointer;
  height: 99%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 99%;
}

> [type='checkbox']:checked ~ button {
  color: var(--primary);
  font-weight: bold;
}

aside {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

nav::before {
  background-color: #fff;
  border-top: 1px solid;
  border-left: 1px solid;
  content: ' ';
  height: 20px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  transform: rotate(45deg);
  width: 20px;
  z-index: -1;
  ${(p:DropDownProps) => `${p.arrowPosition}: ${p.arrowOffset || 30}px;`}
}

nav::after {
  background-color: transparent;
  content: ' ';
  display: block;
  height: 3em;
  position: absolute;
  top: -2.5em;
  width: 20px;
  ${(p:DropDownProps) => `${p.arrowPosition}: ${p.arrowOffset || 30}px;`}
}

nav {
  background-color: #fff;
  border: 1px solid var(--gray-500, #7c7c7c);
  border-radius: 4px;
  box-shadow: 0 0 12px var(--gray-500, #7c7c7c);
  display: none;
  font-size: 18px;
  line-height: inherit;
  min-width: 272px;
  position: absolute;
  text-align: left;
  top: 56px;
  z-index: 9;
  ${(p:DropDownProps) => `${p.arrowPosition}: 0;`}
  .dropdown-header {
    border-bottom: 1px solid var(--gray-500, #7c7c7c);
    color: var(--gray-600, #6c6c6c);
    font-size: 18px;
    font-weight: normal;
    line-height: inherit;
    margin: 0 0 0.5em;
    padding: 0 0.5em;
    position: relative;
    text-transform: capitalize;
  }

  > div > *:not(:last-child) {
    /* space for heightlight box */
    margin-bottom: 4px;
  }

  a {
    color: #222;
    display: block;
    line-height: inherit;
    padding: 0 0.5em;
    text-decoration: none;
    margin: 2px;

    span {
      color: var(--primary);
      display: inline-block;
    }
  }

  a:hover {
    font-weight: bold;
  }
}
> [type='checkbox']:checked ~ aside { display: block; }

> [type='checkbox']:checked ~ nav {
  display: block;
}

${({ showOnHover }) => showOnHover && `
&:hover > nav {
  display: block!important;
}

&:hover > button {
  color: var(--primary)!important;
}
`}

`
