import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RegionState{
  byId: Record<string, Region>,
}

export const initialState:RegionState = {
  byId: {}
}

const slice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Region[]>) => {
      state.byId = action.payload.reduce((carry, item) => {
        carry[item.region_key] = item
        return carry
      }, {})
    },
    setCurrentRegion: (state, action: PayloadAction<string>) => {
      console.assert(!!state.byId[action.payload], 'cannot select a region we dont have !')

      for (const key in state.byId) {
        if (Object.prototype.hasOwnProperty.call(state.byId, key)) {
          state.byId[key].selected = action.payload === state.byId[key].region_key
        }
      }
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
