/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

// list of all representative records
// params: initiator_id ?: allows to filter representatives which were initiated only by appropriate user
export const fetch = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<StaticPage[]>('/pages').then(({ data }) => {
    dispatch(actions.reset(data))
  }).catch((e) => console.error(e))
