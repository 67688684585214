import { Header, PrimaryButton, ResetButton } from 'Components'
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { FiFilter } from 'react-icons/fi'
import styled from 'styled-components'
import { ProductState } from '../redux/reducer'
import { product } from '../redux'
import { useAppDispatch, useAppSelector } from 'Config'
import { isEqual } from 'lodash'

const sortKeys = {
  artno: 'Art.-Nr.',
  name: 'Name',
  price: 'Preis',
  created_at: 'Erstellt am',
  updated_at: 'Geändert am'
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Filters = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const filters = useAppSelector(product.selectFilters)
  const initalCategory = useRef(filters.category)
  const toggleFilter = (data: ProductState['filter'] | {__reset: true}) => {
    if ('keyword' in data && data.keyword) {
      navigate([...location.pathname.split('/').slice(0, 3), data.keyword].join('/'))
    } else {
      navigate(location.pathname.split('/').slice(0, 3).join('/'))
    }
    dispatch(product.setFilter(data))
  }
  const [data, setData] = useState< ProductState['filter'] >(filters)
  const changeData: ChangeEventHandler<HTMLInputElement|HTMLSelectElement> = (e) => {
    const name = e.target.name
    let value = e.target.value.replace(',', '') // just in case user pasted value with comma
    // only accept numbers for max, min
    if (['max', 'min'].indexOf(name) > -1 && isNaN(Number(value))) {
      value = '0'
    }

    if (data[name] !== value) {
      setData({
        ...data,
        [name]: value
      })
    }
  }

  useEffect(() => {
    if (filters.keyword !== data.keyword) {
      setData(oldState => Object.assign({}, oldState, { keyword: filters.keyword || '' }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.keyword])

  const hasValueEqualState = useCallback((): 'RESET' | 'DISABLED' | 'SEARCH' => {
    let changed = false
    let propsCount = 0
    for (const i in data) {
      i !== 'category' && i !== 'keyword' && ++propsCount
      i === 'keyword' && ((data[i] !== undefined && data[i] !== '') || (filters[i] !== undefined && filters[i] !== '')) && ++propsCount
      if (i !== 'category' && data[i] !== filters[i]) {
        changed = true
        break
      }
    }
    if (propsCount > 0) { return changed ? 'SEARCH' : 'RESET' }
    return 'DISABLED'
  }, [data, filters])()

  useEffect(() => {
    if (initalCategory.current !== filters.category) {
      initalCategory.current = filters.category
      if (isEqual(data, filters) === false) {
        setData({ ...filters })
      }
    }
  }, [filters, data])

  const onRESET = () => {
    toggleFilter({ __reset: true })
    setData({})
  }

  return (
    <>
      <Header as='h2'><FiFilter />{' Filter'}</Header>

      <Row>
        <input
          value={data.keyword || ''}
          title="Suchwort"
          aria-label="Suchwort"
          name='keyword'
          step=".1"
          type="text"
          onChange={changeData}
          placeholder="Suchwort"
        />
        <select
          name="fastDelivery"
          title="Schnelle Lieferung direkt aus dem Lager"
          aria-label="Schnelle Lieferung direkt aus dem Lager"
          value={filters.fastDelivery === true ? '1' : filters.fastDelivery === false ? '0' : undefined}
          onChange={(e) => {
            toggleFilter({ fastDelivery: e.target.value === '' ? undefined : e.target.value === '1' })
          }}
        >
          <option
            value=""
            aria-label="Schnelle Lieferung direkt aus dem Lager"
          >{'Schnelle Lieferung direkt aus dem Lager'}</option>
          <option
            value="1"
            aria-label="Ja"
          >{'Ja'}</option>
          <option
            value="0"
            aria-label="Nein"
          >{'Nein'}</option>
        </select>
        <input
          value={data.min || ''}
          name='min'
          onChange={changeData}
          placeholder="Von (€)"
          aria-label="Preis ab in Euro"
          title="Preis ab in Euro"
        />
        <input
          value={data.max || ''}
          onChange={changeData}
          name='max'
          placeholder="Bis (€)"
          aria-label="Preis bis in Euro"
          title="Preis bis in Euro"
        />
        <select
          value={data.sortKey || ''}
          onChange={changeData}
          name="sortKey"
          aria-label="Sortieren"
          title="Sortieren"
        >
          <option>{'Sortieren nach'}</option>
          {Object.entries(sortKeys).map(([val, label]) => (
            <option
              key={val}
              value={val}
              aria-label={val}
            >{label}</option>
          ))}
        </select>
        <select
          value={data.sortDirection || 'ASC'}
          onChange={changeData}
          name="sortDirection"
          aria-label="Sortieren Aufsteigend, Absteigend"
          title="Sortieren Aufsteigend, Absteigend"
        >
          <option
            value="ASC"
            aria-label={'Aufsteigend'}
          >{'Aufsteigend'}</option>
          <option
            value="DESC"
            aria-label={'Absteigend'}
          >{'Absteigend'}</option>
        </select>
        {hasValueEqualState === 'RESET'
          ? (
            <ResetButton
              aria-label={'Filter zurücksetzen'}
              onClick={onRESET}
            >X Zurücksetzen</ResetButton>
          )
          : (
            <PrimaryButton
              disabled={hasValueEqualState === 'DISABLED'}
              onClick={() => toggleFilter(data)}
              title="Filtern"
              aria-label="Filtern"
            > Filtern </PrimaryButton>
          )}
      </Row>
    </>
  )
}

const Row = styled.div`
display: flex;
flex-flow: row wrap;
gap: 1.5em;
margin-bottom: 59px;

> [type='number'] {
  min-height: 36px;
  width: 136px;
}

>select {
  background: #ededed;
  min-width: 10em;
}

> * {
  border-radius: 3px;
  min-height: 40px;
  min-width: 7em;
}

>input {
  background: #fff;
  border: 1px solid var(--gray-500, #7c7c7c);
}
`
