import React, { useCallback, useEffect, useState } from 'react'

import { Tabs, PrimaryButton, Modal } from 'Components'
import { useNavigate } from 'react-router'
import { ProductList } from '../components'

import { useT } from '../translations'
import { useAppDispatch, useAppSelector } from 'Config'
import { cart } from '../redux'
import api from 'Config/api'
import { useToasts } from 'react-toast-notifications'
export function CheckoutModal ({ toggleCart }) {
  const t = useT()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const CARTTYPES = Object.values(t.cartTypes).filter(r => r !== 'Individuelle Werbemittel')
  const CARTTYPESIDS = Object.keys(t.cartTypes)
  const dispatch = useAppDispatch()
  const [active, setActive] = useState(0)
  const state = useAppSelector(cart.products)
  const hasInactiveProducts = !!state.filter(r => r.state !== 'active').length
  const windowWidth = window?.innerWidth
  const width = windowWidth < 900 ? '90vw' : Math.min(window?.innerWidth - 100, 820)

  const update = useCallback(() => {
    dispatch(cart.fetch())
  }, [dispatch])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { dispatch(cart.fetch()) }, [])

  useEffect(() => {
    if (hasInactiveProducts) {
      const inactiveProducts = state.filter(r => r.state !== 'active')
      for (const product of inactiveProducts) {
        removeProduct(product)
      }
      update()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInactiveProducts])
  const list = state.filter(r => r.line_item_type === CARTTYPESIDS[active] && r.state === 'active')

  const removeProduct = (product: CartItem) => {
    api.delete('/carts', {
      data: {
        line_item: {
          id: product.id,
          type: product.line_item_type
        }
      }
    }).then(() => {
      addToast(
        (
          <div role="alert">
            {`Das Produkt "${product.name}" ist inaktiv geworden.`}
          </div>
        ), {
          appearance: 'info',
          autoDismiss: true
        }
      )
    })
  }

  return (
    <Modal
      boxStyle={{
        width,
        marginTop: '2em'
      }}
      backdropStyle={{ alignItems: 'flex-start' }}
      title="WARENKORB"
      onRequestClose={toggleCart}
    >
      <Tabs
        data={CARTTYPES}
        onChange={setActive}
        active={active}
      />

      <ProductList
        fetch={update}
        products={list}
      />

      <div style={row}>
        <PrimaryButton
          aria-label={'Warenkorb leeren'}
          disabled={!CARTTYPESIDS[active]}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            dispatch(cart.cleanCart(CARTTYPESIDS[active]))
          }}
          bg="#4C4C4C"
        >
          Warenkorb leeren
        </PrimaryButton>

        <PrimaryButton
          aria-label={'Artikel bestellen'}
          disabled={list.length === 0}
          onClick={() => {
            toggleCart()
            navigate('/checkout/step1/' + Object.keys(t.cartTypes)[active])
          }}
        >
          {'Artikel bestellen'}
        </PrimaryButton>

      </div>

    </Modal>
  )
}

const row = {
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'space-between'
}
