import React, { FC, ReactNode, useEffect, useState } from 'react'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import MatomoTracker from '@datapunt/matomo-tracker-js'
import { useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import TrackPage from './Tracker'

export interface MatomoGatewayProps {
  children?: ReactNode;
}

export const MatomoGateway: FC<MatomoGatewayProps> = ({ children }) => {
  const user = useAppSelector(auth.selectUser)
  const [matomoInstance, setMatomoInstance] = useState<MatomoTracker>()

  useEffect(() => {
    if (process.env.REACT_APP_MATOMO_URL && !isNaN(Number(process.env.REACT_APP_MATOMO_SITE_ID))) {
      setMatomoInstance(
        createInstance({
          urlBase: process.env.REACT_APP_MATOMO_URL,
          siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID), // 2 Stage
          userId: user?.id,
          heartBeat: {
            active: true,
            seconds: 30
          }
        })
      )
    }
  }, [user])

  return (
    <>
      {matomoInstance
        ? (
          // @ts-ignore.
          <MatomoProvider value={matomoInstance}>
            <TrackPage />
            {children}
          </MatomoProvider>
        )
        : (
          children
        )
      }
    </>
  )
}
