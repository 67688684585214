import Feature from './screens/OrderPage'
import Feature2 from './screens/OrderDetail'
const routes = [{
  path: '/orders/:id',
  Component: Feature2
},
{
  path: '/orders',
  Component: Feature
},
{
  path: '/orderscanceled',
  Component: Feature
},
{
  path: '/ordersactive',
  Component: Feature
}
]

export default routes
