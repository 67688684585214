/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

export const fetch = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<Region[]>('/regions').then(({ data }) => {
    dispatch(actions.set(data))
  })

export const setCurrentRegion = (id: string): AppAsyncThunk => (dispatch, __getState, { api }) =>
  api.put(`select_region?region_key=${id}`)
    .then(() => {
      dispatch(actions.setCurrentRegion(id))
    })
