/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'
// import { selectLastProducts } from './selectors'

export const fetchProducts = (): AppAsyncThunk => (dispatch, _getState, { api }) => {
  // const lastProduct = selectLastProducts(getState())
  // const since = lastProduct ? `${lastProduct?.updated_at}`.split('+')[0] : null
  return api.get<Product[]>('/line_items' /* since ? { params: { since } } : {} */).then(({ data }) => {
    data.length && dispatch(actions.set(data))
  })
}

export const fetchCategories = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<Category[]>('/categories').then(({ data }) => {
    dispatch(actions.setCategories(data))
  })

export const fetchTargetGroups = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<TargetGroup[]>('/target_groups').then(({ data }) => {
    dispatch(actions.setGroups(data))
  })
