export const FormatMoney = (n:any) => n
  ? Number(n).toLocaleString('de-DE', {
    currency: 'EUR',
    style: 'currency'
  })
  : '--'

export const FormatDate = (n: string | Date, short = false) => {
  const date = (typeof n === 'string') ? new Date(n) : n
  if (!date?.toLocaleDateString) {
    return null
  }

  return short
    ? date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    : date.toLocaleDateString('de-DE', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    })
}
export function zeroFill (number, width = 6) {
  width -= number.toString().length
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
  }
  return number + '' // always return a string
}
