
import * as thunks from './actions'
import { actions } from './reducer'
import * as selectors from './selectors'

export const region = {
  ...actions,
  ...thunks,
  ...selectors
}
