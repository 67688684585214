import { useLocale } from 'Config'

import de from './de'
import en from './en'

export const t = (locale:'de'|'en') => {
  if (locale === 'en') { return en }

  return de
}

export const useT = () => {
  const [state] = useLocale()

  return t(state)
}
