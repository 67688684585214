import { PrimaryButton } from 'Components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { useAppDispatch, useAppSelector } from 'Config'
import { cart } from 'Features/cart'
import { CheckoutTable } from 'Features/cart/components/ProductList/CheckoutTable'
import {
  Form,
  getInitialFormValue,
  getInitialFormValueWithPrefix
} from 'Features/cart/screens/formula'
import { FormatMoney } from 'Helpers'
import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Link,
  useNavigate,
  useParams
} from 'react-router-dom'
import styled from 'styled-components'
import { orders } from '../redux'
import { useT } from '../translations'
import ImageModal from 'Features/product/components/ImageModal'
import ImageZoom from 'Features/product/components/ImageZoom'
import { ChatModal } from 'Features/chat/screens/chatModal'
import {
  subDays,
  setHours,
  setMinutes,
  compareAsc
} from 'date-fns'
import {
  dataHasFile,
  appendToForm
} from 'Features/cart/screens/CheckoutStep1'
import { IOrder, UpdateForm } from './interfaces'

export const addCollectionPropsToOrder = (idx: number, order: Order) => {
  return {
    ...order.detailed_order?.detailed_order_infos[idx],
    additional_speaker_0:
      order.detailed_order?.detailed_order_infos[idx]?.speakers.length > 0,
    speakers: order.detailed_order?.detailed_order_infos[idx]?.speakers?.map(
      (speaker, index) => {
        const x =
          index <
          order.detailed_order?.detailed_order_infos[idx].speakers.length - 1
        return {
          ...speaker,
          ...(x && { [`additional_speaker_${index + 1}`]: true })
        }
      }
    ),
    additional_video_0:
      order.detailed_order?.detailed_order_infos[idx]?.videos.length > 0,
    videos: order.detailed_order?.detailed_order_infos[idx]?.videos?.map(
      (video, index) => {
        const x =
          index <
          order.detailed_order?.detailed_order_infos[idx].videos.length - 1
        return {
          ...video,
          ...(x && { [`additional_video_${index + 1}`]: true }),
          video_presis: video
        }
      }
    )
  }
}
export const addCollectionPropsToOrderCommMedium = (
  formularName: string,
  order: Order,
  idx: number,
  category: string
) => {
  if (formularName === 'Produktanzeige erweitert') {
    return {
      ...order.detailed_order?.detailed_order_infos[idx],
      ...(category === 'CommMedium' && {
        additional_publication_date_0:
          order.detailed_order?.detailed_order_infos[idx]?.publication_dates
            ?.length > 0,
        publication_dates: order.detailed_order?.detailed_order_infos[
          idx
        ]?.publication_dates?.map((publicationDate, index) => {
          const x =
            index <
            order.detailed_order?.detailed_order_infos[idx]?.publication_dates
              ?.length -
              1
          return {
            ...publicationDate,
            ...(x && { [`additional_publication_date_${index + 1}`]: true }),
            file: publicationDate
          }
        })
      })
    }
  } else {
    return {
      ...order.detailed_order?.detailed_order_infos[idx],
      ...(category === 'CommMedium' && {
        additional_file_0:
          order.detailed_order?.detailed_order_infos[idx]?.files?.length > 0,
        files: order.detailed_order?.detailed_order_infos[idx]?.files?.map(
          (file, index) => {
            const x =
              index <
              order.detailed_order?.detailed_order_infos[idx].files.length - 1
            return {
              ...file,
              ...(x && { [`additional_file_${index + 1}`]: true }),
              file
            }
          }
        )
      })
    }
  }
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function OrderDetailsPage () {
  const fieldRef = React.useRef<HTMLInputElement | null>(null)
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const id = `${Number(params.id)}`
  const [orderDetailsView, setOrderDetailsView] = useState(true)
  const dispatch = useAppDispatch()
  const order = useAppSelector(orders.selectOrders)[id]
  const category = order?.order_items
    ? order.order_items[0]?.line_item_type
    : 'N/A'
  const form = useAppSelector(cart.formula)[category] || {}
  const items = order?.order_items || []
  const [digitalEventToEditMode, setDigitalEventToEditMode] = useState(false)
  const [formulaIsLoaded, setFormulaIsLoaded] = useState(false)
  const [orderIsLoaded, setOrderIsLoaded] = useState(false)
  const [popUp, setPopUp] = useState<{
    title?: string
    zoom: boolean
    src: LineItemPicture[]
  }>({
    zoom: false,
    src: []
  })
  const t = useT()
  const [chatVisible, toggleChat] = useState(false)
  const showChat = useCallback((e?: any) => {
    if (e) e?.preventDefault()
    toggleChat(true)
  }, [])
  const hideChat = useCallback(() => toggleChat(false), [])

  useEffect(() => {
    setOrderIsLoaded(false)
    dispatch(orders.fetchOrderDetails(id)).finally(() => setOrderIsLoaded(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setFormulaIsLoaded(false)
    if (Object.keys(form).length === 0 && category !== 'N/A') {
      dispatch(cart.getFormula(category)).finally(() =>
        setFormulaIsLoaded(true))
    } else {
      setFormulaIsLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  useEffect(() => {
    if (digitalEventToEditMode) {
      const getFirstInput = document.getElementById(
        '0.additional_speaker_0'
      ) as HTMLInputElement
      fieldRef.current = getFirstInput
      fieldRef.current?.focus()
    }
  }, [digitalEventToEditMode])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = (e) => {
    e.preventDefault()

    const clonedArray: IOrder[] = order.detailed_order.detailed_order_infos.map(
      (a) => {
        return {
          ...a,
          speakers: undefined,
          videos: undefined,
          logo_needed: false
        }
      }
    )
    // eslint-disable-next-line compat/compat
    const dataFromForm = new FormData(e.target).entries()
    const formData: UpdateForm[] = []
    let countVideos = 0
    Array.from(dataFromForm).forEach(([key, val]) => {
      const parts = key.split('.')

      parts.reduce((c, i, idx) => {
        if (idx === parts.length - 1) {
          if (val instanceof File) {
            if (val.size > 0) {
              if (e.target.elements[key].multiple) {
                if (c[i]) {
                  c[i] = [...c[i], val]
                } else {
                  c[i] = [val]
                }
              } else {
                c[i] = val
              }
            }
          } else {
            if (i.startsWith('additional_video')) return c
            if (i.startsWith('video_presis')) {
              countVideos += 1
              return c
            }
            c[i] = val
          }
          return c
        }
        if (!c[i]) {
          c[i] = isNaN(Number(parts[idx + 1])) ? {} : []
        }
        return c[i]
      }, formData)
    })

    const updatedOrder = clonedArray.map((o, index) => ({
      ...o,
      ...formData[index],
      videos: formData[index].videos?.slice(countVideos)
    }))

    updatedOrder.forEach((u) => {
      Object.entries(u).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          delete Object.assign(u, { [`${k}_attributes`]: u[k] })[k]
        }
      })
    })

    const dataToSendApi = {
      digital_info_event_order_infos_attributes: updatedOrder
    }
    const headers = {}
    const hasFile = dataHasFile(dataToSendApi)
    const data = new FormData()

    if (hasFile) {
      headers['Content-Type'] = 'multipart/form-data'
      data.append('type', category)
      appendToForm(data, dataToSendApi, ['data'])
    }

    dispatch(
      orders.updateOrder(
        category,
        hasFile ? data : dataToSendApi,
        order.id,
        headers
      )
    ).then(() => dispatch(orders.fetchOrderDetails(id)))
    setDigitalEventToEditMode(false)
  }

  if (!order?.region) {
    return null
  }
  const TWO_DAYS_AGO = 1000 * 60 * 60 * 24 * 2
  const deadline = Date.now() - TWO_DAYS_AGO
  const canCancel =
    new Date(order.created_at).getTime() > deadline &&
    order.translated_order_type !== 'Individuelle Werbemittel' &&
    order.state !== 'canceled' &&
    order.translated_order_type !== 'Digitale-Infoveranstaltung-Bestellung'

  let canCancelDigital = false
  if (order.order_items !== undefined && order.order_items.length > 0) {
    const orderMinDate = order.order_items.reduce((first, second) =>
      first.start_date < second.start_date ? first : second)

    let startDateDigitalOrder = subDays(new Date(orderMinDate.start_date), 1)
    startDateDigitalOrder = setHours(startDateDigitalOrder, 23)
    startDateDigitalOrder = setMinutes(startDateDigitalOrder, 59)

    canCancelDigital =
      compareAsc(new Date(), startDateDigitalOrder) === -1 &&
      order.translated_order_type === 'Digitale-Infoveranstaltung-Bestellung' &&
      order.state !== 'canceled'
  }

  return (
    <Container>
      {chatVisible
        ? (
          <ChatModal
            toggleChat={hideChat}
            order={order}
          />
        )
        : null}
      {popUp.zoom && (
        <ImageModal
          title={popUp.title}
          src={popUp.src}
          onCloseModal={() =>
            setPopUp({
              zoom: false,
              src: popUp.src,
              title: ''
            })
          }
        />
      )}
      <Header>
        <h2>Allgemein</h2>
        <div style={{ padding: '1em 0' }}>
          <PrimaryButton
            aria-label={t.actions.send_notification}
            style={{
              border: '1px solid',
              marginRight: '1em'
            }}
            onClick={showChat}
            flat
          >
            {t.actions.send_notification}
          </PrimaryButton>

          {category === 'DigitalInfoEvent' && (
            <PrimaryButton
              aria-label={t.edit_order}
              style={{
                border: '1px solid',
                marginRight: '1em'
              }}
              onClick={() => {
                setDigitalEventToEditMode(true)
                setOrderDetailsView(false)
              }}
              flat
            >
              {t.edit_order}
            </PrimaryButton>
          )}
          {canCancel || canCancelDigital
            ? (
              <PrimaryButton
                aria-label={t.actions.cancel_order}
                style={{
                  border: '1px solid',
                  marginRight: '1em'
                }}
                onClick={() => {
                  dispatch(orders.cancelOrder(order.id)).then(() =>
                    order.state === 'canceled'
                      ? navigate('/orderscanceled', { replace: true })
                      : navigate('/orders', { replace: true }))
                }

                }
                flat
              >
                {t.actions.cancel_order}
              </PrimaryButton>
            )
            : null}
          <PrimaryButton
            aria-label={t.actions.back}
            as={Link}
            to={order.state === 'canceled' ? '/orderscanceled' : '/orders'}
          >
            {t.actions.back}
          </PrimaryButton>
        </div>
      </Header>

      <Ul>
        <li>
          <span>{t.general_info.area}</span>
          {order.region.name}
        </li>
        <li>
          <span>{t.general_info.order_type}</span>
          {order.translated_order_type}
        </li>
        <li>
          <span>{t.general_info.order_number}</span>
          {id}
        </li>
        <li>
          <span>{t.general_info.order_status}</span>
          {order.state === 'canceled'
            ? t.order_status_canceled
            : t.order_status_active}
        </li>
      </Ul>

      <CheckoutTable style={{ width: '100%' }}>
        <thead>
          <tr>
            <th colSpan={2}>Artikel</th>
            <th>Anzahl</th>
            <th>Preis</th>
            <th>Gesamt</th>
            <th>Versandstatus</th>
          </tr>
        </thead>
        <tbody>
          {order.order_items.map((p) => (
            <tr key={`p_${p.id}`}>
              <td colSpan={2}>
                <div className="product-profile">
                  <ImageZoom
                    title={p.name}
                    src={p.line_item_pictures}
                    size={60}
                    onClickZoom={() => {
                      setPopUp({
                        zoom: true,
                        src: p.line_item_pictures,
                        title: p.name
                      })
                    }}
                  />
                  <b>{p.name}</b>
                </div>
              </td>
              <td>{p.amount}</td>
              <td> {FormatMoney(p.price)} </td>
              <td> {FormatMoney(p.amount * Number(p.price))} </td>
              <td>
                <State className={p.supplier_state}>
                  {t.status[p.supplier_state] || p.supplier_state}
                </State>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>Gesamt</th>
            <th>4</th>
            <th> </th>
            <th>{FormatMoney(order.total_price)} </th>
            <th> </th>
          </tr>
        </tfoot>
      </CheckoutTable>
      {Boolean(formulaIsLoaded) && Boolean(orderIsLoaded) && (
        <>
          {category === 'DigitalInfoEvent'
            ? (
              <form
                onSubmit={onSubmit}
                style={{ padding: 4 }}
                id="checkout-form"
              >
                {(form?.basic ? [items[0]] : items).map((item, idx) => {
                  if (!item) return null
                  const formula =
                  form[item.formular_identifier] ||
                  form[item.formular_identifier + '_attributes'] ||
                  form.basic
                  const val = Array.isArray(
                    order.detailed_order?.detailed_order_infos
                  )
                    ? addCollectionPropsToOrder(idx, order)
                    : order.detailed_order
                  const initialValueWithPrefix = getInitialFormValueWithPrefix(
                    formula,
                    val,
                    idx.toString()
                  ) as Record<string, any>
                  const readOnlyCustomDigitalEvents2 = {}
                  Object.keys(initialValueWithPrefix).reduce((c, item) => {
                    if (
                      digitalEventToEditMode &&
                    (item === `${idx}.logo_needed` ||
                      item === `${idx}.videos_or_presis` ||
                      item === `${idx}.additional_speaker_0` ||
                      item.includes('speakers') ||
                      item === `${idx}.logo` ||
                      (initialValueWithPrefix.videos.length === 0 &&
                        item === `${idx}.additional_video_0`))
                    ) {
                      return Object.assign(c, { [item]: false })
                    }
                    return Object.assign(c, { [item]: true })
                  }, readOnlyCustomDigitalEvents2)
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  return (
                    <fieldset
                      key={`fieldset_${idx}`}
                      id={`fieldset_${idx}`}
                      style={{ marginBottom: 40 }}
                    >
                      <legend
                        style={{
                          fontSize: 24,
                          fontWeight: 'bold'
                        }}
                      >
                        {item.name}
                      </legend>
                      <Form
                        prefix={idx.toString()}
                        initialValue={initialValueWithPrefix}
                        data={formula?.attributes}
                        readOnly={
                          digitalEventToEditMode
                            ? readOnlyCustomDigitalEvents2
                            : true
                        }
                        isEditForm={true}
                        orderDetailsView={orderDetailsView}
                        orderId={order.id}
                      />
                    </fieldset>
                  )
                })}
                {category === 'DigitalInfoEvent' && digitalEventToEditMode && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <PrimaryButton
                      aria-label={t.update_order}
                      style={{ minWidth: 120 }}
                      type="submit"
                    >
                      {t.update_order}
                    </PrimaryButton>
                  </div>
                )}
              </form>
            )
            : (
              (form?.basic && category !== 'MobileMedium'
                ? [items[0]]
                : items
              ).map((item, idx) => {
                if (!item) return null
                const formula =
                category === 'MobileMedium'
                  ? form.main_attributes
                  : form[item.formular_identifier] ||
                    form[item.formular_identifier + '_attributes'] ||
                    form.basic
                const val = Array.isArray(
                  order.detailed_order?.detailed_order_infos
                )
                  ? addCollectionPropsToOrderCommMedium(
                    formula?.formular_name || 'undefined',
                    order,
                    idx,
                    category
                  )
                  : order.detailed_order

                return (
                  <fieldset
                    key={`fieldset_${idx}`}
                    style={{ marginBottom: 40 }}
                  >
                    <legend
                      style={{
                        fontSize: 24,
                        fontWeight: 'bold'
                      }}
                    >
                      {item.name}
                    </legend>
                    <Form
                      initialValue={getInitialFormValue(formula, val)}
                      data={formula?.attributes}
                      readOnly
                      orderDetailsView={true}
                    />
                  </fieldset>
                )
              })
            )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  .zoom-container {
    background-color: rgb(0 0 0 / 10%);
    flex: 0 0 130px;
    height: 130px;
    margin: 0;
    position: relative;

    .image-slider {
      button {
        border: 1px solid var(--gray-230, #ededed);
        border-radius: 100%;
        height: 26px;
        top: calc(50% - 13px);
        width: 26px;
        &.button-left {
          left: 0px !important;
        }
        &.button-right {
          right: 0px !important;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.size.sm}) {
    .product-profile {
      height: auto;
    }

    tbody td > div {
      flex-flow: wrap;
    }
    .zoom-container {
      flex: 0 0 100px;
      height: 100px;
    }
  }
`

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
`
const Ul = styled.ul`
  list-style: none;
  margin: 1em 0 2em;
  padding: 0;

  li > span {
    display: inline-block;
    font-weight: bold;
    line-height: 2em;
    text-transform: capitalize;
    width: 100px;
  }
`
const State = styled.span`
  border-top: 3px solid var(--cyan);
  color: var(--cyan);
  display: block;
  font-weight: bold;
  margin: 0 auto;
  padding-top: 6px;
  width: 60%;

  &.accepted {
    border-color: var(--success);
    color: var(--success);
  }

  &.declined {
    border-color: var(--danger);
    color: var(--danger);
  }

  &.pending {
    border-color: #006dff;
    color: #006dff;
  }

  &.partially {
    border-color: #bd5b0b;
    color: #bd5b0b;
  }
`
