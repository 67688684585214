import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const ID_PARAM = 'id'

export interface NewsState{
  byYear: Record<string, number[]>,
  byId: Record<string, NewsItem>
}

export const initialState:NewsState = {
  byYear: {},
  byId: {}
}

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<NewsItem[]>) => {
      action.payload.forEach((item) => {
        // prepare
        const m = item.created_at.split('-')[0]
        if (!state.byYear[m]) state.byYear[m] = []
        // save
        if (!state.byId[item[ID_PARAM]]) {
          state.byYear[m].push(item.id)
        }
        state.byId[item[ID_PARAM]] = item
      }, state)
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
