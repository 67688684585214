import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'

export const ID_PARAM = 'uid'

export interface ProductState{
  filter: {
    category?: string;
    max?: number;
    min?: number;
    fastDelivery?: boolean;
    sortKey?: string;
    sortDirection?: 'ASC' | 'DESC',
    keyword?: string;
  };
  byId: Record<string, Article>,
  category: Record<string, Category>,
  groups: Record<string, TargetGroup>,
  ids: string[];
}

export const initialState:ProductState = {
  filter: {},
  byId: {},
  category: {},
  groups: {},
  ids: []
}

function filterProducts (map: ProductState['byId'], filter: ProductState['filter']):string[] {
  const ids = Object.keys(map).filter(id => {
    if (filter.max && Number(map[id].price) > filter.max) { return false }
    if (filter.min && Number(map[id].price) < filter.min) { return false }
    if (filter.category && `${map[id].category_id}` !== `${filter.category}`) { return false }
    if (filter.fastDelivery !== undefined && (!!map[id].fast_delivery) !== (!!filter.fastDelivery)) { return false }
    if (filter.keyword && filter.keyword.length > 1 && `${map[id].name}`.toLowerCase().indexOf(`${filter.keyword}`.toLowerCase()) === -1) { return false }
    return (map[id].state === 'active')
  })

  return sortProducts(map, filter, ids)
}

function sortProducts (map: ProductState['byId'], filter: ProductState['filter'], ids:string[]) {
  const sorted = sortBy(ids, (id) => {
    // @TODO: remove this when api is fixed; currently api return floats as string not number
    const key = filter.sortKey || ID_PARAM
    const val = Number(map[id][key])
    return isNaN(val) ? map[id][key] : val
  })

  return filter.sortDirection === 'ASC' ? sorted : sorted.reverse()
}

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Product[]>) => {
      state.byId = {}
      action.payload.forEach((item) => {
        item[ID_PARAM] = item[ID_PARAM] || item.id + item.line_item_type
        state.byId[item[ID_PARAM]] = item
      }, state)
      // state.filter = { category: state.filter.category }
      state.ids = filterProducts(state.byId, state.filter)
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      action.payload.forEach((item) => {
        state.category[item.id] = item
      }, state)

      state.ids = filterProducts(state.byId, state.filter)
    },
    setGroups: (state, action: PayloadAction<TargetGroup[]>) => {
      if (!state.groups) {
        state.groups = {}
      }
      action.payload.forEach((item) => {
        state.groups[item.id] = item
      }, state)
    },
    setKeyword: (state, { payload }: PayloadAction<string>) => {
      state.filter.keyword = payload
      state.ids = filterProducts(state.byId, state.filter)
    },
    setFilter: (state, { payload }: PayloadAction<ProductState['filter'] | { __reset: true }>) => {
      if ('__reset' in payload) {
        state.filter = { category: state.filter.category }
      } else if (payload.category !== state.filter.category) {
        state.filter = {
          ...payload
        }
      } else {
        state.filter = {
          ...state.filter,
          ...payload
        }
      }
      state.ids = filterProducts(state.byId, state.filter)
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
