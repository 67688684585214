import React, { FC } from 'react'

import logo from 'Assets/brand/dt-logo.svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

const currentYear = new Date().getFullYear()

export interface FooterLink {
  name: string;
  slug: string;
}

export interface FooterLink2 {
  name: string;
  props: Record<string, any>
}

export interface FooterProps {
  pages?: Array<FooterLink | FooterLink2 >;
  as?: any;
  host?: string;
}
/* istanbul ignore next */
export const AppFooter: FC<FooterProps> = ({ pages = [], host = '' }) => (
  <>
    <NavCorner>
      <svg
        viewBox="0 0 10 10"
        className="nav-corner nav-corner--left"
      >
        <path d="M0,0c0,6.7,3.3,10,10,10l0,0H0L0,0"></path>
      </svg>
      <svg
        viewBox="0 0 10 10"
        className="nav-corner nav-corner--right"
      >
        <path d="M10 0 V10 H0 V10 Q10 10 10 0"></path>
      </svg>
    </NavCorner>
    <Footer data-testid="footer">

      <div
        className="nav-corners"
        aria-hidden="true"
      >

      </div>

      <div className="container">
        <div className="brand-images">
          <div className="brand-logo">
            <img
              src={logo}
              alt="Telekom Logo"
            />
            <span className="sr-only">Telekom Logo</span>
          </div>
        </div>
        <div className="copyright-navigation">
          <p className="copyright"> {`© ${currentYear} Deutsche Telekom AG`} </p>

          <nav className="navigation">
            {pages && pages.length > 0 && (
              pages.map((page, index) => 'props' in page
                ? (
                  <A
                    data-testid='pages'
                    {...page.props}
                    key={`page_${index}`}
                  >
                    {page.name}
                  </A>
                )
                : (
                  <A
                    data-testid='pages'
                    href={`${host}/static_page/${page.slug}`}
                    rel="noopener noreferrer"
                    className="text-light"
                    key={page.slug}
                  >
                    {page.name}
                  </A>
                ))
            )}
          </nav>
        </div>
      </div>
    </Footer>
  </>
)

AppFooter.propTypes = {
  pages: PropTypes.array
}

AppFooter.defaultProps = {
  pages: [],
  as: 'a'
}

const NavCorner = styled.div`
margin-top: 1rem;
position: relative;
.nav-corner {
  bottom: 100%;
  fill: var(--gray-900, #262626);
  height:10px;
  position: absolute;
  width: 10px;
}

.nav-corner--left {
  left: 0;
}
.nav-corner--right {
  right: 0;
}

`

const Footer = styled.footer`
  background-color: var(--gray-900, #262626);
  bottom: 0;
  color: #fff;
  font-size: 1rem;
  margin: 0;
  padding: 1.5rem;
  padding-right: 2rem;
  position: relative;
  width: 100%;
  z-index: 1;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .brand-images {
    display: flex;
    flex-grow: 1;
    padding: 6px;
    justify-content: flex-start;
    align-items: center;

    .brand-logo {
      padding: 6px;
      img {
        height: 30px;
      }
    }

    .claim {
      flex-grow: 1;
      padding-left: 20px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .copyright-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
  }

  .copyright {
    margin: 0;
    flex-grow: 1;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 14px;
    padding: 9px 12px 8px 12px;
    line-height: 1.125;
    font-weight: 600;
  }

  .navigation {
    align-items: center;
    justify-content: end;

    nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      row-gap: 1rem;
    }
  }

  @media (max-width: ${breakpoint.size.lg}) {
    .brand-images {
      flex: 1 1 100%;
    }
  }
`
const A = styled.a`
  color: #fafafa;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.125;
  padding: 9px 12px 8px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;

  &:hover {
    color: #fff !important;
    text-decoration: underline;
  }

  @media (max-width: ${breakpoint.size.xs}) {
    width: 100%;
  }
`
