import { AppAsyncThunk } from 'Config'
import { auth } from 'Features/auth/redux'
import { chat } from 'Features/chat/redux'
import { news } from 'Features/news'
import { orders } from 'Features/orders'
import { region } from 'Features/region'
import { staticPages } from 'Features/staticPages'
import { batch } from 'react-redux'

export const fetch = (): AppAsyncThunk => (dispatch, _getState, { api }) => api.get<Dashboard>('/dashboard').then(({ data }) => {
  batch(() => {
    dispatch(auth.update(data.user))
    dispatch(staticPages.reset(data.pages))
    dispatch(region.set(data.regions))
    dispatch(orders.set(data.orders))
    dispatch(news.set(data.news))
    dispatch(chat.setUnreadCount(data?.notifications.unread_count))
  })
})
