import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState) => state[name] || initialState

export const selectOrders = (state: RootState) => {
  return slice(state).byId
}

export const selectSortedOrders = (state: RootState) => {
  return Object.values(slice(state).byId).sort((a, b) => a.id > b.id ? -1 : 1)
}

export const selectLast15Order = (state: RootState) => {
  return Object.values(slice(state).byId).filter((order) =>
    order.state !== 'canceled').sort(
    (a, b) =>
      a.id > b.id ? -1 : 1
  ).slice(0, 15)
}

export const selectOrdersPreview = (state: RootState) =>
  slice(state).preview || {}

export const selectTotalOrders = (state: RootState) =>
  slice(state).total
