import { REACT_APP_KAMBOX_FE_UID, REACT_APP_SMART_SSO_URL } from 'Env'
import React, { useEffect } from 'react'
import { redirect_uri } from '../const'

export default function LoginPage () {
  const formRef = React.createRef<HTMLFormElement>()
  sessionStorage.setItem('originalpath', window.location.pathname)
  useEffect(() => {
    formRef.current?.submit()
  }, [formRef])
  return (
    <form
      ref={formRef}
      action={`${REACT_APP_SMART_SSO_URL}oauth/authorize`}
      method="GET"
    >
      <input
        type="hidden"
        name="client_id"
        value={REACT_APP_KAMBOX_FE_UID}
      />
      <input
        type="hidden"
        name="redirect_uri"
        value={redirect_uri}
      />
      <input
        type="hidden"
        name="response_type"
        value="code"
      />
    </form>
  )
}
