/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { Header } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { news } from '../redux'
import { NewsList } from '../components/NewsList'
import { batch } from 'react-redux'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Helmet } from 'react-helmet'

export default function NewsPage () {
  const dispatch = useAppDispatch()
  const data = useAppSelector(news.slice)
  const [date, toggleDate] = useState(`${(new Date()).getFullYear()}`)

  useEffect(() => {
    batch(() => {
      dispatch(news.fetchNews())
    })
  }, [dispatch])

  return (
    <Container>
      <Helmet>
        <title>Kam Box News</title>
      </Helmet>
      <Header as="h2">
        {'News'}
      </Header>

      <RowOfTwo>

        <div>
          <ul>
            {
              Object.keys(data.byYear).sort((a, b) => Number(a) < Number(b) ? -1 : 1).map(row => (
                <li
                  className={`${row === date ? 'active' : ''}`}
                  key={row}
                >
                  <button
                    title={'Nachrichten ' + row}
                    aria-label={'Nachrichten ' + row}
                    onClick={() => toggleDate(`${row}`)}
                  >{row}
                  </button>
                </li>
              ))
            }
          </ul>
        </div>

        <div>
          <NewsList
            data={data}
            year={date}
          />
        </div>
      </RowOfTwo>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;

h2 {
  display: flex;
  justify-content: space-between;
}

ul {
  font-size: 18px;
  letter-spacing: 0;
  list-style: none;
  padding-left: 0;
  text-align: left;

  button {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 12px;
    padding-left: 0;
  }

  .active button {
    color: var(--primary);
  }
}
`
const RowOfTwo = styled.div`
  border: 0;
  display: flex;
  flex-flow: row wrap;

  > *:not(:first-of-type) {
    flex: 1;
  }

  > div:first-of-type {
    min-width: 211px;
    padding-right: 2em;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    > div:first-of-type {
      flex-basis: 100%;
    }
  }
`
