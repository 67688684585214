import React, { useEffect, useState } from 'react'

import { GlobalStyle } from 'Assets/styles/GlobalStyles'
import dashboardFeature from 'Features/dashboard/routes'
import cartFeature from 'Features/cart/routes'
import productFeature from 'Features/product/routes'
import newsFeature from 'Features/news/routes'
import faqFeature from 'Features/faq/routes'
import ordersFeature from 'Features/orders/routes'
import documentsFeature from 'Features/documents/routes'
import authFeature from 'Features/auth/routes'
import representativeFeature from 'Features/representative/routes'
import notificationsPagesFeature from 'Features/chat/router'
import staticPagesFeature from 'Features/staticPages/routes'
import { ToastProvider, DefaultToast, ToastProps } from 'react-toast-notifications'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

import { AppFooter, DefaultLayout, Spinner, MatomoGateway } from 'Components'
import { BrandHeader } from 'Components/AppHeader/BrandHeader'
import ConnectivityMonitor from 'Components/ConnectivityMonitor'
import api from 'Config/api'
import { ScrollToTop } from 'Helpers'
import { product } from 'Features/product'
import { useAppDispatch, useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import { SupplierView } from 'Features/supplier'
import SupplierOrderDetailsPage from 'Features/supplier/details'
import { chat } from 'Features/chat/redux'
import AppRouter from 'Router'
import { fetch } from 'Features/dashboard/actions'
import { staticPages } from 'Features/staticPages'

const staffRoutes = [...staticPagesFeature, ...dashboardFeature, ...authFeature, ...newsFeature, ...representativeFeature, ...cartFeature, ...productFeature, ...faqFeature, ...documentsFeature, ...ordersFeature, ...notificationsPagesFeature]
const supplierRoutes = [
  ...authFeature,
  ...notificationsPagesFeature,
  {
    path: '/supplier/:id',
    Component: SupplierOrderDetailsPage
  },
  {
    path: '/supplier',
    Component: SupplierView
  }
]
function App () {
  const dispatch = useAppDispatch()
  const isSupplier = useAppSelector(auth.isSupplier)
  const pages = useAppSelector(staticPages.slice)
  const [loaded, toggleLoaded] = useState(false)

  useEffect(() => {
    if (isSupplier) {
      dispatch(auth.fetchTargetGroups())
      dispatch(chat.fetchUnreadCount())
      toggleLoaded(true)
      return
    }

    dispatch(fetch()).finally(() => {
      toggleLoaded(true)
    })
    dispatch(product.fetchCategories())
    dispatch(product.fetchProducts())
    dispatch(product.fetchTargetGroups())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const routes = isSupplier ? supplierRoutes : staffRoutes
  const CustomToast = ({ children, ...props } : ToastProps) =>
    (
      <DefaultToastCustom
        { ...props}
      >{children}
      </DefaultToastCustom>
    )

  return (
    <ToastProvider
      components={{ Toast: CustomToast }}
      autoDismiss
      autoDismissTimeout={15000}
    >

      <MatomoGateway>
        <DefaultLayout maxWidth={'2500px'}>
          <BrandHeader
            subheader="KAM Box"
            isSupplier={isSupplier}
          />
          <div className="app">
            <GlobalStyle />
            <ScrollToTop />
            <ConnectivityMonitor api={api} />
            {loaded
              ? (
                <AppRouter
                  routes={routes}
                  isSupplier={isSupplier}
                />
              )
              : <Spinner /> }
          </div>

          <AppFooter pages={pages.map(({ title, slug, id }) => ({
            name: title,
            props: {
              to: '/static/' + slug,
              key: id,
              as: Link
            }
          }))}
          />
        </DefaultLayout>
      </MatomoGateway>
    </ToastProvider>
  )
}

export default App

const DefaultToastCustom = styled(DefaultToast)`
border: 1px solid;
`
