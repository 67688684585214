import {
  Action,
  combineReducers,
  configureStore,
  PayloadAction,
  ThunkAction
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import api from './api'

import cartSlice from 'Features/cart/redux/reducer'
import regionSlice from 'Features/region/redux/reducer'
import productSlice from 'Features/product/redux/reducer'
import faqSlice from 'Features/faq/redux/reducer'
import orderSlice from 'Features/orders/redux/reducer'
import authSlice from 'Features/auth/redux/reducer'
import docsSlice from 'Features/documents/redux/reducer'
import chatSlice from 'Features/chat/redux/reducer'
import rSlice from 'Features/representative/redux/reducer'
import newsSlice from 'Features/news/redux/reducer'
import pagesSlice from 'Features/staticPages/redux/reducer'
import { createReduxEnhancer } from '@sentry/react'

const rootReducer = combineReducers({
  [cartSlice.name]: cartSlice.reducer,
  [productSlice.name]: productSlice.reducer,
  [faqSlice.name]: faqSlice.reducer,
  [regionSlice.name]: regionSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [rSlice.name]: rSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [docsSlice.name]: docsSlice.reducer,
  [pagesSlice.name]: pagesSlice.reducer,
  [newsSlice.name]: newsSlice.reducer
})

const rootReducerWithReset: typeof rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducerWithReset)
export const store = configureStore({
  reducer: persistedReducer,
  enhancers: [createReduxEnhancer()],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    },
    thunk: {
      extraArgument: { api }
    }
  })
})
export const persistor = persistStore(store)

export type AppGetState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<AppGetState>

export type AppAsyncThunk<ReturnType = void, A = unknown> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  { api: typeof api },
  PayloadAction<A>
>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { api: typeof api },
  Action
>;
