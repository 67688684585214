import { IconButton } from 'Components'
import React from 'react'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import styled from 'styled-components'

interface PagintaionProps{
  pageSize: number,
  totalCount: number,
  currentPage: number,
  onSelect: (n:number)=>void;
  loading?: boolean;
}

export const Pagintaion: React.FC<PagintaionProps> = ({ pageSize, loading, totalCount, currentPage, onSelect }:PagintaionProps) => {
  const i = Math.ceil(totalCount / pageSize)
  if (!totalCount) {
    return null
  }
  return (
    <UL>
      <li>
        <IconButton
          disabled={!currentPage}
          onClick={() => onSelect(0)}
          title={'Erste'}
          aria-label={'Erste'}
        >
          <FiChevronsLeft />
        </IconButton>
      </li>
      <li>
        <IconButton
          disabled={!currentPage}
          onClick={() => onSelect(currentPage - 1)}
          title={'Zurück'}
          aria-label={'Zurück'}
        >
          <FiChevronLeft />
        </IconButton>
      </li>
      {loading
        ? <li> ... </li>
        : (
          <li style={{ padding: '1px 1em' }}>
            <strong>{(currentPage) * pageSize}-{ Math.min((currentPage + 1) * pageSize, totalCount) }</strong>/{totalCount}
          </li>
        )}
      <li>
        <IconButton
          disabled={currentPage === (i - 1)}
          onClick={() => onSelect(currentPage + 1)}
          title={'Nächste'}
          aria-label={'Nächste'}
        >
          <FiChevronRight />
        </IconButton>
      </li>
      <li>
        <IconButton
          disabled={currentPage === (i - 1)}
          onClick={() => onSelect(i - 1)}
          title={'Letzte'}
          aria-label={'Letzte'}
        >
          <FiChevronsRight />
        </IconButton>
      </li>
    </UL>
  )
}

const UL = styled.ul`
display: flex;
flex-flow: row nowrap;
font-size: 18px;
list-style: none;
margin: 1em 0;
padding: 0;

> li {
  border-radius: 8px;
  line-height: 36px;
  margin-right: 2px;
}

button {
  height: 38px;
  width: 38px;
}
`
