import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type InitialState = StaticPage[];

export const initialState: InitialState = []

const slice = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {
    reset: (_, { payload }: PayloadAction<StaticPage[]>) => {
      return payload
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
