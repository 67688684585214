/* eslint-disable sonarjs/no-identical-functions */
import React from 'react'

// import styled from 'styled-components'

import { InlineSearchInput } from '../components'
import { useAppSelector } from 'Config'
import { product } from 'Features/product'
import { Link, useNavigate } from 'react-router-dom'
// import { FiExternalLink } from 'react-icons/fi'
// import { useT } from 'Features/cart/translations'

export function InlineSearchInputContainer () {
  const navigate = useNavigate()
  const products = useAppSelector(product.selectProducts)
  const [keyword, setKeyword] = React.useState('')
  // const filters = useAppSelector(product.selectFilters)
  const go = () => {
    navigate('/products/Product/' + keyword)
  }
  const result = Object.entries(products).filter(([_key, row]) => (row.state === 'active' && (keyword.length > 0 ? row.name.toLowerCase().indexOf(keyword) > -1 : true)))
  return (
    <InlineSearchInput
      onChange={e => setKeyword(`${e.target.value}`.toLowerCase())}
      value={keyword}
      placeholder="Schnellsuche"
      aria-label="PRODUKT-SCHNELLSUCHE"
      numberOItems={result.length}
      visible={keyword.length > 0 && result.length > 0}
      onClick={go}
    >
      <div>
        {result
          .map(([key, row], idx) => (
            <Link
              tabIndex={idx + 1}
              key={key}
              to={`/products/${row.line_item_type}/${row.uid}`}
              style={{
                maxWidth: '50vw',
                display: 'flex',
                flexFlow: 'row nowrap',
                padding: '0.75em 1em',
                alignItems: 'center'
              }}
              onClick={e => e.stopPropagation()}
              aria-label={row.name}
              title={row.name}
            >
              <span
                className="clip-text"
                style={{ flex: 1 }}
              >{row.name}</span>

            </Link>
          ))}
      </div>
    </InlineSearchInput>
  )
}
