/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, PrimaryButton, TextInput } from 'Components'
import { useT } from 'Features/orders/translations'
import ImageSlider from 'Features/product/components/Image'
import styled from 'styled-components'
import { useAppDispatch } from 'Config'
import { orders } from 'Features/orders'

export function SupplierArticle ({ data, id }:{data: OrderItem, id: any}) {
  const t = useT()
  const [state, setState] = useState(data.supplier_state)
  const [text, setText] = useState(data.supplier_comment || '')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useAppDispatch()
  const send = () => {
    if (
      (state === 'partially' || state === 'declined') &&
      !text
    ) {
      return setError('Erforderlich')
    }

    setError('')
    setLoading(true)
    dispatch(orders.edit({
      orderId: id,
      itemId: data.ordered_item_id,
      supplier_comment: text === data.supplier_comment ? undefined : text,
      supplier_state: state === data.supplier_state ? undefined : state
    }))
      .finally(() => { setLoading(false) })
  }
  useEffect(() => {
    setText(data.supplier_comment || '')
    setState(data.supplier_state || '')
  }, [data])

  // useEffect(() => {
  //   if (state !== data.supplier_state) { send() }
  // }, [state])

  return (
    <ItemCard title={data.name}>
      <Row>
        <ImageSlider
          src={data.line_item_pictures}
          name={data.name}
        />
        <div style={{ flex: 1 }}>
          <ul className={state}>
            {Object.entries(t.status).map(([en, de]) => (
              <li
                className={`${en} ${state === en && 'active-status'}`}
                key={en}
              >
                <a
                  href="#"
                  role="button"
                  aria-label={de}
                  title={de}
                  onClick={(e) => {
                    e.preventDefault()
                    setState(en as 'pending')
                  }}
                >{de}</a>
              </li>
            ))}
          </ul>
          <Row>
            <TextInput
              value={text}
              onChange={e => setText(e.target.value)}
              placeholder="Kommentar"
              title="Kommentar"
              aria-label="Kommentar"
              name="comment"
              message={error}
              mode={error ? 'error' : 'default'}
            />
          </Row>
          <PrimaryButton
            aria-label="Aktualisieren"
            disabled={data.supplier_comment === text && data.supplier_state === state}
            onClick={send}
          >{loading ? ' ... ' : 'Aktualisieren'}</PrimaryButton>
        </div>
      </Row>
    </ItemCard>
  )
}

const ItemCard = styled(Card)`
h2 {
  border-bottom: 1px solid #ccc;
  margin: 0 -24px 24px;
  padding: 0 0 1em 1em;
}

li { color: #4c4c4c; }

.accepted .accepted {
  color: var(--success);
}

.declined .declined {
  color: var(--danger);
}

.pending .pending {
  color: #006DFF;
}

.partially .partially {
  color: #BD5B0B;
}

.partially .partially,
.pending .pending,
.declined .declined,
.accepted .accepted {
  border-bottom: 4px solid;
  border-top: 0;
  font-weight: bold;
  opacity: 1;
}

`

const Row = styled.div`
display: flex;
flex-flow: row nowrap;
font-size: 16px;

img,
figure {
  min-height: 200px;
  min-width: 200px;
  overflow: hidden;
}

img {
  height: 100%;
  width: 100%;
}

label {
  margin-right: 1em;
}

> div {
  padding: 1em;

  > button {
    float: right;
    margin: 0 0 0 12px;
  }
}

a {
  color: inherit;
  padding: 12px;
  padding-top: 6px;
  text-decoration: none;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 2px solid;
    margin: 0 12px;
    opacity: 0.9;
    padding-top: 4px;
  }
}
`
