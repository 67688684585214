/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

import { FaSave, FaTrash } from 'react-icons/fa'

import { IconButton } from 'Components'
import ImageModal from 'Features/product/components/ImageModal'
import ImageZoom from 'Features/product/components/ImageZoom'

import { CheckoutTable } from './CheckoutTable'

import { useAppSelector } from 'Config'
import { region } from 'Features/region'
import { clickTrapStopPropagation, FormatMoney } from 'Helpers'
import api from 'Config/api'
interface Props{
  disabled?: boolean;
  noDelete?: boolean;
  noTotal?: Boolean
  products: CartItem[]; fetch?: any
}
const successColor = { color: 'var(--success, #dcc)' }
const noob = {}
export function ProductList ({ fetch, products, noDelete, disabled = false, noTotal = false }:Props) {
  const TOTALPRICE = products.reduce((c, i) => c + (Number(i.price) * i.requested_amount), 0)
  const ITEMSCOUNT = products.reduce((c, i) => c + i.requested_amount, 0)
  const selectedRegion = useAppSelector(region.selectCurrentRegion)
  const [amounts, setAmounts] = useState(noob)
  const [popUp, setPopUp] = useState<{title?:string; zoom: boolean; src: LineItemPicture[]}>({
    zoom: false,
    src: []
  })
  const setAmount = (id, val) => {
    setAmounts(a => ({
      ...a,
      [id]: val === '' ? '' : Number(val)
    }))
  }
  const updateAmount = (idx: any) => {
    const p = typeof amounts[idx] === 'number'
      ? api.post('/carts', {
        line_item: {
          id: products[idx].id,
          type: products[idx].line_item_type,
          amount: amounts[idx]
        }
      })
      : api.delete('/carts', {
        data: {
          line_item: {
            id: products[idx].id,
            type: products[idx].line_item_type
          }
        }
      })

    return p.then(() => { setAmount(idx, '') }).then(fetch).catch((e) => console.error(e))
  }
  return (
    <Container>
      {popUp.zoom && (
        <ImageModal
          title={popUp.title}
          src={popUp.src}
          onCloseModal={() => setPopUp({
            zoom: false,
            src: popUp.src,
            title: ''
          })}
        />
      )}
      <CheckoutTable onClick={clickTrapStopPropagation}>
        <thead>
          <tr>
            <th colSpan={2}>Artikel</th>
            <th>Preis</th>
            <th>Stück</th>
            <th>Gesamtpreis</th>
          </tr>
        </thead>
        <tbody>
          {products.map((p:CartItem, idx) => (
            <tr key={`p_${p.id}`}>
              <td colSpan={2}>
                <div className="product-profile">
                  <ImageZoom
                    title={p.name}
                    src={p.line_item_pictures}
                    size={60}
                    onClickZoom={() => {
                      setPopUp({
                        zoom: true,
                        src: p.line_item_pictures,
                        title: p.name
                      })
                    }}
                  />
                  <b>{p.name}</b>
                </div>
              </td>
              <td>
                {FormatMoney(p.price)}
              </td>
              <td><div style={{ justifyContent: 'center' }}>
                <input
                  title="amount"
                  aria-label="amount"
                  disabled={noDelete || disabled}
                  pattern="[0-9]*"
                  placeholder={'< ' + p.max_per_order}
                  onChange={e => setAmount(idx, e.target.value)}
                  value={amounts[idx] || p.requested_amount}
                  readOnly
                />
                {noDelete
                  ? null
                  : (
                    <IconButton
                      aria-label={typeof amounts[idx] === 'number' ? 'Betrag aktualisieren' : 'Entfernen'}
                      title={typeof amounts[idx] === 'number' ? 'Betrag aktualisieren' : 'Entfernen'}
                      onClick={() => updateAmount(idx)}
                    >
                      {typeof amounts[idx] === 'number' ? <FaSave /> : <FaTrash />}
                    </IconButton>
                  )}</div></td>
              <td> {FormatMoney(p.requested_amount * Number(p.price))} </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>Gesamt</th>
            <th> {ITEMSCOUNT} </th>
            <th> {FormatMoney(TOTALPRICE)} </th>
          </tr>
          {noTotal
            ? null
            : (
              <tr>
                <th colSpan={4}>Verbleibend</th>
                <th style={successColor}> {FormatMoney(Number(selectedRegion?.rest_budget) - Number(TOTALPRICE))} </th>
              </tr>
            )}
        </tfoot>
      </CheckoutTable>
    </Container>
  )
}

const Container = styled.div`
margin-left: 0;
padding: 0;
width: auto;

td,
th {
  margin: 0;
  white-space: nowrap;
}

td {
  white-space: inherit;
}

.zoom-container {
  background-color: rgb(0 0 0 / 10%);
  flex: 0 0 130px;
  height: 130px;
  margin: 0;
  position: relative;

  .button-zoom {
    bottom: 25%;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: fit-content;
    z-index: 99;
  }

  &:hover .button-zoom,
  .button-zoom:focus {
    opacity: 1;
  }

  .image-slider {

    button {
      border: 1px solid var(--gray-230, #ededed);
      border-radius: 100%;
      height: 26px;
      top: calc(50% - 13px);
      width: 26px;
      &.button-left {
        left: 0px! important;
      }
      &.button-right {
        right: 0px! important;
      }
    }
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  .product-profile {
    height: auto;
  }

  tbody td > div {
    flex-flow: wrap;
  }

  .zoom-container {
    flex: 0 0 100px;
    height: 100px;
  }
}
`
