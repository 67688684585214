import React, { useContext, useMemo, useState } from 'react'

interface LocalState{
  state: 'de' | 'en';
  onChange: (lang:'de' | 'en') => void
}

export const localContext = React.createContext({ } as LocalState)
const { Provider } = localContext

export const LocalProvider:React.FC<{ children: any }> = ({ children }) => {
  const [state, onChange] = useState<'de' | 'en'>('de')

  return (
    <Provider value={{
      state,
      onChange
    }}
    >
      {children}
    </Provider>
  )
}

export const useLocale = () => {
  const { state, onChange } = useContext(localContext)

  return useMemo<[typeof state, typeof onChange]>(() => [state, onChange], [state, onChange])
}
