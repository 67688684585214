import { Spinner } from 'Components'
import React from 'react'

const SplashScreen = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}
  >
    <Spinner />
  </div>
)

export default SplashScreen
