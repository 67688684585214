import Feature from './screens/CheckoutStep1'
import Feature2 from './screens/CheckoutStep2'

const routes = [
  {
    path: '/checkout/success',
    Component: Feature2
  },
  {
    path: '/checkout/:step/:category',
    Component: Feature
  }
]

export default routes
