import React, { FC, HTMLAttributes, ReactNode } from 'react'

import { Container, Content } from './StyledComponents'

export interface DefaultLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: [ReactNode, ReactNode, ReactNode];
  maxWidth?: string;
}

/**
 * Defaultlayout expect exactly 3 children
 * - header
 * - content
 * - footer
 *
 * it accpet maxWidth as param to center page; default = 1200px;
 */
// istanbul ignore next
export const DefaultLayout: FC<DefaultLayoutProps> = ({
  children = [],
  maxWidth = '2500px'
}) => {
  return (
    <Container
      maxWidth={maxWidth}
      data-testid="defaultLayout-container"
    >
      {children[0] || null}
      <Content data-testid="defaultLayout-content">
        {children[1] || 'the snozzberries taste like snozzberries'}
      </Content>
      {children[2] || null}
    </Container>
  )
}

DefaultLayout.defaultProps = {
  children: [null, null, null],
  maxWidth: '2500px'
}
