import React from 'react'

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AppFooter, AppHeader, ErrorBoundary } from 'Components'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { defineCustomElements, applyPolyfills } from '@telekom/scale-components/loader'
import '@telekom/scale-components/dist/scale-components/scale-components.css'

import { LocalProvider, persistor, store } from 'Config'
import { REACT_APP_PWA, REACT_APP_VITALS } from 'Env'

import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import 'Assets/styles/print.css'
import { AuthProvider } from 'Features/auth'
import { BrowserRouter, Route, Routes, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { LayersManager } from 'react-layers-manager'

console.log('starting app in ' + process.env.NODE_ENV + ' mode.')

applyPolyfills().then(() => {
  defineCustomElements(window)
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    beforeSend: (event) => {
      const escapedEvents = event.breadcrumbs?.filter((e) => e?.data?.status_code === 401)
      if (escapedEvents && escapedEvents?.length > 0) {
        return null
      }
      return event
    },
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE || process.env.npm_package_version,
    tracesSampleRate: 0.2
  })
}
const ErrorPage = () => (
  <div>
    <AppHeader subheader="KAM Box"></AppHeader>
    <div style={{
      flex: 1,
      minHeight: '69vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    >
      <h1>error happened, please report this to admin</h1>
    </div>
    <AppFooter />
  </div>
)

const MyApp = Sentry.withProfiler(() => (
  <BrowserRouter>
    <LocalProvider>
      <Provider store={store}>
        <ErrorBoundary fallback={ErrorPage}>
          <LayersManager>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <Routes>
                <Route
                  path="*"
                  element={<AuthProvider App={App} />}
                />
              </Routes>
            </PersistGate>
          </LayersManager>
        </ErrorBoundary>
      </Provider>
    </LocalProvider>
  </BrowserRouter>
))

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>
)

if (REACT_APP_PWA) {
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}

if (REACT_APP_VITALS) {
  import('reportWebVitals').then(m => m.default(console.log))
}
