import { ProductDetail } from './components/ProductDetails'
import ProductsListingPage from './screens/ProductsListingPage'

const routes = [
  {
    path: '/products/:category/:keyword?/*',
    Component: ProductsListingPage
  },
  {
    path: '/products/:type/:id',
    Component: ProductDetail
  }
]

export default routes
