import { AxiosInstance } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

export default function ConnectivityMonitor ({ api }:{api?:AxiosInstance}) {
  const toastManager = useToasts()
  const [isOnline, toggleOnline] = useState(!!window.navigator.onLine)
  const mounted = useRef(false)
  useEffect(() => {
    // prevent show message on inital mount
    if (!mounted.current && isOnline) {
      mounted.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline])
  useEffect(() => {
    const offline = () => toggleOnline(false)
    const online = () => toggleOnline(true)
    window.addEventListener('offline', offline)
    window.addEventListener('online', online)

    return () => {
      window.removeEventListener('offline', offline)
      window.removeEventListener('online', online)
    }
  }, [])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    let i:null|number = null
    if (api) {
      i = api.interceptors.response.use(
        response => {
          if (response?.data?.message) {
            toastManager.addToast(
              (
                <div role="alert">
                  <div>
                    {response?.data?.message}
                  </div>
                </div>
              ), {
                appearance: 'success',
                autoDismiss: true
              }
            )
          }
          return Promise.resolve(response)
        },
        (error) => {
          if (!error.response || error.response.status === 500) {
            toastManager.addToast(
              (
                <div role="alert">
                  <strong>{'Ops, Serverfehler!, ' + error?.response?.status}</strong>
                  <div>
                    Anfrage fehlgeschlagen. Bitte kontaktieren Sie den Systemadministrator
                  </div>
                </div>
              ), {
                appearance: 'error',
                autoDismiss: true
              }
            )
          } else if (error?.response?.status === 401) {
            console.warn('session expired...')
          } else if (error?.response?.data?.message) {
            const success = error.response?.status < 299
            toastManager.addToast(
              (
                <div role="alert">
                  <strong>{success ? 'Erfolg' : 'Warnung'}</strong>
                  <div>
                    {error.response.data.message}
                  </div>
                </div>
              ), {
                appearance: success ? 'success' : 'error',
                autoDismiss: true
              }
            )
          }
          return Promise.reject(error)
        }
      )
    }
    return () => {
      i !== null && api?.interceptors.response.eject(i)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
