/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable styled-components-a11y/no-autofocus */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router'

import { Modal, PrimaryButton, Pagintaion } from 'Components'
import styled from 'styled-components'
import { FormatDate } from 'Helpers'
import { useAppDispatch, useAppSelector } from 'Config'
import { chat } from '../redux'
import { FaSpinner } from 'react-icons/fa'
import { ChatSearchBar } from './components/Searchbar'
import { useT } from 'Features/orders/translations'

const keyupEvent = new Event('KeyboardEvent')
keyupEvent.initEvent('keydown')
Object.defineProperties(keyupEvent, {
  keyCode: {
    value: 9
  },
  shiftKey: {
    value: true
  }
})

export function Thread ({ thread, back, dispatch, hide }) {
  const [chatBox, showChat] = useState(false)
  const [val, changeVal] = useState('')
  const [updateThread, setUpdatedThread] = useState(thread)

  const reply = () => {
    if (val && val.length > 1) {
      dispatch(chat.reply(thread.id, val)).then(() => {
        changeVal('')
        showChat(false)
        dispatch(chat.read(thread.id)).then(setUpdatedThread)
      })
      document.getElementById('chat-modal')?.scrollTo(0, 0)
    }
  }
  return (
    <>
      {!hide && (<H5>{thread?.subject} <time>{FormatDate(updateThread?.updated_at || updateThread?.created_at || '')}</time></H5>)}
      <Ul role="log">
        {updateThread?.fragments.map(i => (
          <li
            key={i.id}
            className="col"
          >
            <p>{i.sender_full_name} am <time>{FormatDate(i.updated_at || i.created_at)}</time></p>
            <b
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: i.body }}
            />
          </li>
        ))}
      </Ul>

      {chatBox && (
        <TextArea
          required
          minLength={1}
          value={val}
          onChange={e => changeVal(e.target.value)}
          placeholder="Ihre Antwort:"
          aria-label="Ihre Antwort"
          autoFocus
        />
      )}
      <Row>
        <PrimaryButton
          aria-label={'Zurück'}
          color="#FFF"
          bg="#4c4c4c"
          onClick={() => {
            back()
          }}
        >
          Zurück
        </PrimaryButton>
        <div style={{ flex: 1 }} />
        {
          updateThread?.can_reply
            ? (
              <PrimaryButton
                aria-label={'Antworten'}
                onClick={() => chatBox ? reply() : showChat(true)}
              >
                Antworten
              </PrimaryButton>
            )
            : null}
      </Row>
    </>
  )
}
interface IChatModal {
  toggleChat: () => void;
  order?: Order;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function ChatModal ({ toggleChat, order }: IChatModal) {
  const t = useT()
  const params = useParams<{keyword?: string}>()
  const keyword = params.keyword || ''
  const list = useAppSelector(chat.selectThreads)
  const filters = useAppSelector(chat.selectChatFilters)
  const [pageSize] = useAppSelector(chat.selectPaginationValuse)
  const unreadCount = useAppSelector(chat.selectCount)
  const [thread, setThread] = useState<ChatListItem|null>(null)
  const width = Math.min(window?.innerWidth - 100, 800)
  const [loading, setLoading] = useState<boolean|1>(1)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const page = filters?.page || 0
  const step = pageSize * page
  const listPage = list.slice(step, step + pageSize)
  const notificationsPage = '/notifications'
  const [conversation, setConversation] = useState('')
  const [startConversationModal, setStartConversationModal] = useState(list.length !== 0)

  useEffect(() => {
    if (keyword) {
      setLoading(true)
      dispatch(chat.read(keyword)).then(setThread)
        .catch(() => {
          navigate(notificationsPage)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [keyword])

  useEffect(() => {
    if (order && order.id) {
      dispatch(chat.setFilters({ order_id: order.id }))
    }
  }, [order, thread])

  useEffect(() => {
    if (loading === 1) { // initial Loading
      dispatch(chat.fetchThreads({
        page: 1,
        order_id: order?.id || undefined,
        limit: pageSize
      })).finally(() => setLoading(false))
      return
    }
    // show spinner only if first page and no cache
    setLoading(true)
    dispatch(chat.fetchThreads({
      page: page + 1,
      limit: pageSize,
      region_id: filters?.region_id || undefined,
      order_id: filters?.order_id || undefined
    })).finally(() => setLoading(false))
  }, [filters])

  const openThread = useCallback((t: ChatListItem) => {
    setLoading(true)
    dispatch(chat.read(t.id)).then(setThread).finally(() => {
      setLoading(false)
    })
  }, [dispatch])

  const modalRequestClose = () => {
    const locationPath = location.pathname.split('/')
    if ([...locationPath.slice(0, 2)].join('/') === notificationsPage) {
      navigate('/')
    } else {
      toggleChat()
    }
  }

  return (
    <Modal
      id="chat-modal"
      boxStyle={{ width }}
      title="Benachrichtigungen"
      onRequestClose={modalRequestClose}
    >
      <div
        style={{
          fontSize: 16
        }}
      >
        {loading ? <LoadingOverlay> <FaSpinner className="spin" /> </LoadingOverlay> : null}
        {thread
          ? (
            <Thread
              dispatch={dispatch}
              back={() => {
                setThread(null)
                if (keyword) {
                  navigate('/notifications')
                }
              }}
              thread={thread}
              hide={!!order}
            />
          )
          : (
            <>
              {!order?.id && <ChatSearchBar />}
              {order && (
                <>
                  {startConversationModal && (
                    <TextArea
                      required
                      minLength={1}
                      value={conversation}
                      onChange={e => setConversation(e.target.value)}
                      placeholder={`${t.start_conversation_placeholder}:`}
                      aria-label={t.start_conversation_placeholder}
                      autoFocus
                    />
                  )}

                  <PrimaryButton
                    aria-label={t.actions.cancel_order}
                    onClick={() => {
                      if (!startConversationModal) { setStartConversationModal(true) } else {
                        dispatch(chat.createThread(order.id, conversation, order.order_items[0].target_group_id))
                        setStartConversationModal(false)
                        setConversation('')
                      }
                    }}
                    flat={false}
                  >{startConversationModal ? t.start_conversation_button : t.new_conversation_button}</PrimaryButton>
                </>

              )}
              <Ul role="log">
                {listPage.filter(Boolean).map((i) => (
                  <li key={i.id}>
                    <button
                      onClick={() => openThread(i)}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.stopPropagation()
                          setThread(i)
                        }
                      }}
                      tabIndex={0}
                      aria-label={i.subject}
                      title={i.subject}
                    >

                      <span
                        title={i.read ? 'old' : 'new'}
                        aria-label={i.read ? 'old' : 'new'}
                        className={`${i.read ? '' : 'green'}`}
                      />
                      <p>{i.subject}</p>
                      <time>{FormatDate(i.newest_fragment_created_at || i.updated_at || i.created_at)}</time>
                    </button>
                  </li>
                ))}
                {list.length === 0 && <li><p style={{ textAlign: 'center' }}>Keine Nachrichten</p></li> }
              </Ul>

              <Row>
                <Pagintaion
                  totalCount={list?.length}
                  pageSize={pageSize}
                  onSelect={page => dispatch(chat.setFilters({
                    ...(filters || {}),
                    page
                  }))}
                  currentPage={page}
                />

                <div style={{ flex: 1 }} />

                {unreadCount > 0 && !order
                  ? (
                    <PrimaryButton
                      aria-label={'Alle Nachrichten als gelesen markieren'}
                      flat
                      onClick={() => dispatch(chat.markAllAsRead())}
                    >
                      {'Alle Nachrichten als gelesen markieren'}
                    </PrimaryButton>
                  )
                  : null}
              </Row>
            </>
          )}
      </div>
    </Modal>
  )
}

const H5 = styled.h5`
border-bottom: 1px solid #ccc;
font-size: 16px;
font-weight: normal;
padding-bottom: 1em;
time { float: right; }
`

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1em;
`

const Ul = styled.ul`
display: block;
list-style: none;
margin: 2em 0;
overflow: auto;
padding: 0;
width: 100%;

> .col {
  align-items: flex-start;
  flex-flow: column nowrap;
}

> li {
  button {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    text-align: left;
    width: 100%;

    > p {
      flex: 1;
      margin: 0.5em 0;
      padding-top: 4px;
    }

    > b {
      display: block;
      margin-left: 1em;
    }

    > span {
      background-color: #6b6b6b;
      border-radius: 100%;
      display: inline-block;
      height: 1em;
      margin-right: 1em;
      width: 1em;
    }

    >.green {
      background-color: var(--success, #46a800);
    }
  }
}
`
const TextArea = styled.textarea`
border: 1px solid #ccc;
border-top: 1px solid #ccc;
display: block;
font-size: 16px;
margin: 3em 0;
min-height: 10vh;
padding: 0.5em 1em;
width: 100%;
`

const LoadingOverlay = styled.div`
align-items: center;
background-color: rgba(0, 0, 0, 0.4);
color: #fff;
display: flex;
font-size: 55px;
height: 100%;
justify-content: center;
left: 0;
position: absolute;
top: 0;
width: 100%;
z-index: 100;
`
