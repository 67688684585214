/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'
// import DashboardBreadCrumb from '../components/breadcrumb'
import { Spinner } from 'Components'
import { useAppSelector } from 'Config'
import { staticPages } from '../redux'
import { Helmet } from 'react-helmet'

export default function Feature () {
  const pages = useAppSelector(staticPages.slice)
  const { slug } = useParams<{slug: string}>()
  const page = pages.find(r => `${r.slug}`.toLowerCase() === `${slug}`.toLowerCase())

  if (!page) {
    return <Spinner />
  }

  return (
    <Container>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>

      <article dangerouslySetInnerHTML={{ __html: page.body }}></article>

    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
font-size: 18px;
margin: 2rem auto;
width: 100%;

h1 {
  margin-bottom: 0;
}

h2 {
  color: var(--green, #558f1c);
  margin-bottom: 0 0 1rem;
}

a {
  color: var(--primary);
}
`
