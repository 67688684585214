import Feature from './screens/ListPage'
import CreateFeature from './screens/Create'
import EditFeature from './screens/Edit'

const routes = [
  {
    path: '/representative/create',
    Component: CreateFeature
  },
  {
    path: '/representative/:id',
    Component: EditFeature
  },
  {
    path: '/representative',
    Component: Feature
  }
]

export default routes
