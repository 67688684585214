/* eslint-disable sonarjs/no-identical-functions */
import { PrimaryButton, Spinner } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { cart } from 'Features/cart'
import { Form, getInitialFormValue } from 'Features/cart/screens/formula'
import { orders } from 'Features/orders'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { FiPrinter } from 'react-icons/fi'
import { SupplierArticle } from './components/SupplierArticle'
import { useT } from './translations'
import { addCollectionPropsToOrder, addCollectionPropsToOrderCommMedium } from 'Features/orders/screens/OrderDetail'

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function SupplierOrderDetailsPage () {
  const params = useParams<{ id: string }>()
  const id = `${Number(params.id)}`
  const t = useT()
  const dispatch = useAppDispatch()
  const order = useAppSelector(orders.selectOrders)[id]
  const category = order?.order_items ? order.order_items[0]?.line_item_type : 'N/A'
  const form = useAppSelector(cart.formula)[category] || {}
  const print = () => {
    // const s = document.createElement('style')
    // s.type = 'text/css'
    // s.innerHTML = `
    // @media print {
    //   header button, header a {
    //     opacity: 0;
    //   }
    // }
    // `
    // document.body.appendChild(s)
    window.print()
    // document.body.removeChild(s)
  }

  useEffect(() => {
    if (Object.keys(form).length === 0 && category !== 'N/A') {
      dispatch(cart.getFormula(category))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  useEffect(() => {
    dispatch(
      orders.fetchOrderDetails(id)
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (!order) {
    return (
      <>
        <h1>order not found</h1>
        <PrimaryButton
          aria-label={t.back}
          as={Link}
          to="/supplier"
        >{t.back}</PrimaryButton>
      </>
    )
  }

  if (Object.keys(form).length === 0) {
    return <Spinner />
  }
  const content = category === 'MobileMedium'
    ? (
      <>
        {order.order_items.map((m) => (
          <SupplierArticle
            key={m.id}
            id={order.id}
            data={m}
          />
        ))}
        {order.order_items.map((item, idx) => {
          return (
            <fieldset
              style={{ marginBottom: 40 }}
              key={item.id + '_fieldset'}
            >
              <legend style={{
                fontSize: 24,
                fontWeight: 'bold'
              }}
              >{item.name}</legend>
              <Form
                key={item.id + '_form'}
                data={(form.main_attributes)?.attributes}
                readOnly
                initialValue={getInitialFormValue(form.main_attributes, Array.isArray(order.detailed_order?.detailed_order_infos) ? order.detailed_order?.detailed_order_infos[idx] : order.detailed_order)}
                supplierForm= {true}
              />
            </fieldset>
          )
        })}
      </>
    )
    : category === 'DigitalInfoEvent'
      ? (
        <>
          {order.order_items.map((m) => (
            <SupplierArticle
              key={m.id}
              id={order.id}
              data={m}
            />
          ))}
          {order.order_items.map((item, idx) => {
            const formula = form[item.formular_identifier] ||
             form[item.formular_identifier + '_attributes'] ||
             form.basic
            const val = Array.isArray(
              order.detailed_order?.detailed_order_infos
            )
              ? addCollectionPropsToOrder(idx, order)
              : order.detailed_order
            return (
              <fieldset
                style={{ marginBottom: 40 }}
                key={item.id + '_fieldset'}
              >
                <legend style={{
                  fontSize: 24,
                  fontWeight: 'bold'
                }}
                >{item.name}</legend>
                <Form
                  key={item.id + '_form'}
                  data={form.digi_biv_attributes.attributes}
                  readOnly
                  initialValue={getInitialFormValue(formula, val)}
                  supplierForm= {true}
                />
              </fieldset>
            )
          })}
        </>
      )
      : form.basic
        ? (
          <>
            {order.order_items.map((p) => (
              <SupplierArticle
                key={p.id}
                id={order.id}
                data={p}
              />

            ))}
            <Form
              data={(form.basic)?.attributes}
              readOnly
              initialValue={getInitialFormValue(form.basic, order.detailed_order)}
              supplierForm= {true}
            />
          </>
        )
        : order.order_items.map((p, idx) => {
          const formula = form[p.formular_identifier] || form[p.formular_identifier + '_attributes'] || form.basic
          const val = Array.isArray(order.detailed_order?.detailed_order_infos)
            ? addCollectionPropsToOrderCommMedium(formula?.formular_name || 'undefined', order, idx, category)
            : order.detailed_order
          return (
            <>
              <SupplierArticle
                key={p.id}
                id={order.id}
                data={p}
              />
              <Form
                key={p.id + 'form'}
                data={formula?.attributes}
                readOnly
                initialValue={getInitialFormValue(formula, val)}
                supplierForm= {true}
              />
            </>
          )
        })

  return (
    <div className="print">
      <HeaderContainer>
        <h2>{order.translated_order_type} #{order.id}</h2>
        <PrimaryButton
          aria-label={'Zurück zur Übersicht'}
          as={Link}
          to="/supplier"
        >Zurück zur Übersicht</PrimaryButton>
        <PrimaryButton
          aria-label={'Drucken'}
          bg="#4C4C4C"
          onClick={print}
          color="#FFF"
        > <FiPrinter /> Drucken</PrimaryButton>
      </HeaderContainer>

      <Ul>
        <li>
          <span>Gebiet</span>
          {order.region?.name}
        </li>
        <li>
          <span>Bestellungsart</span>
          {order.translated_order_type}
        </li>
        <li>
          <span>Bestellnummer</span>
          {id}
        </li>
      </Ul>
      {content}
    </div>
  )
}

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;

  > button,
  >a { margin: 0 0 0 12px; }

  > h2 {
    flex: 1;
    font-size: 2em;
    margin: .67em 0;
  }
`
const Ul = styled.ul`
list-style: none;
margin: 1em 0 2em;
padding: 0;

li > span {
  display: inline-block;
  font-weight: bold;
  line-height: 2em;
  width: 100px;
}
`
