import styled from 'styled-components'

import { Table } from 'Components'

export const CheckoutTable = styled(Table)`
table-layout: fixed;
text-align: center;
width: 100%;

th,
td {
  text-align: center;
}

button {
  border-radius: 4px;
}

thead th {
  font-size: 18px;
  text-align: center;
}

tbody td > div {
  align-items: center;
  display: flex;
}

tbody td input {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 38px;
  margin: 0 8px;
  max-width: 60px;
  min-width: 40px;
  outline: none;
  padding: 0;
  text-align: center;
}

tr > th:first-of-type,
tr > td:first-of-type {
  text-align: left;
}

.product-profile {
  height: 130px;
  gap: 1em;

  img {
    background-color: var(--primary);
    border: 1px solid #ccc;
    height: unset;
    width: unset;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    object-fit: unset;
  }
}

tfoot > tr {
  border-top: 2px solid #222;
  padding: 2px 0;
  position: relative;
  top: 1px;
}

tbody tr:last-of-type {
  border: 0;
}

`
