/* eslint-disable sonarjs/no-identical-functions */
import React from 'react'

import { FaSearch, FaMapMarkerAlt, FaUserAlt, FaCalendar, FaInfo } from 'react-icons/fa'

import styled from 'styled-components'

import { Card as OriginalCard, ShoppingCart, Table } from 'Components'

import { useNavigate } from 'react-router'
import { AppDispatch, useAppSelector } from 'Config'

import { Link } from 'react-router-dom'
import { FormatDate, FormatMoney } from 'Helpers'
import { orders } from 'Features/orders/redux'
import { region } from 'Features/region'
import { auth } from 'Features/auth/redux'
import { news } from 'Features/news'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { useDispatch } from 'react-redux'
import { fetch } from '../actions'
import { InlineSearchInputContainer } from '../containers/InlineSearch'
import { Helmet } from 'react-helmet'

export default function DashboardFeature () {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const o = useAppSelector(orders.selectLast15Order)
  const r = useAppSelector(region.myRegions)
  const u = useAppSelector(auth.selectUser)
  const n = useAppSelector(news.last5)

  React.useEffect(() => {
    dispatch(fetch())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container>
      <Helmet>
        <title>Telekom KAM Box</title>
      </Helmet>

      <RowOfTwo>

        <FirstRow>
          <Card
            id={'search-card'}
            title="Produkt-Schnellsuche"
            icon={<FaSearch />}
          >
            <InlineSearchInputContainer />
          </Card>

          <Card
            style={{ paddingBottom: 0 }}
            title="Meine Ausbaugebiete"
            icon={<FaMapMarkerAlt />}
          >
            <Table>
              <thead>
                <tr>
                  <th>Ausbaugebiet</th>
                  <th>Gesamtbudget</th>
                  <th>Ausgaben</th>
                  <th>Restbudget</th>
                  <th>aktueller Warenkorbwert</th>
                </tr>
              </thead>
              <tbody>
                {r.map(row => (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>{FormatMoney(row.budget) }</td>
                    <td>{FormatMoney(row.spent_budget) }</td>
                    <td>{FormatMoney(row.rest_budget) }</td>
                    <td>{FormatMoney(row.cart_budget) }</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>

          <Card
            style={{ paddingBottom: 0 }}
            title="Ihr Profil"
            icon={<FaUserAlt />}
            action="Mein Profil bearbeiten"
            onClick={() => navigate('/profile')}
          >
            <Table>
              <thead>
                <tr>
                  <th>Kontaktdaten</th>
                  <th>Standard Lieferadresse</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Block>
                      <span>Vorname</span>
                      {u?.first_name}
                    </Block>
                    <Block>
                      <span>Nachname</span>
                      {u?.last_name}
                    </Block>
                    <Block>
                      <span>E-Mail</span>
                      {u?.email}
                    </Block>
                  </td>
                  <td>
                    <Block>
                      <span>Firma</span>
                      {u?.company}
                    </Block>
                    <Block>
                      <span>Straße</span>
                      {u?.street} {u?.houseno}
                    </Block>
                    <Block>
                      <span>PLZ, Ort</span>
                      {u?.zip ? u?.zip + ', ' : ''}{u?.city || ''}
                    </Block>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card
            style={{ paddingBottom: 0 }}
            title="News"
            icon={<FaCalendar />}
            action='Mehr News'
            onClick={() => navigate('/pages/news')}
          >
            <Table>
              <thead>
                <tr>
                  <th>Titel</th>
                  <th>Datum</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {n.map(row => (
                  <tr key={row.id}>
                    <td>{row.title}</td>
                    <td>{FormatDate(row.created_at)}</td>
                    <td>

                      <Span
                        as={Link}
                        aria-label={'News ' + row.title }
                        title={'News ' + row.title }
                        to={'/pages/news/' + row.id}
                      >
                        <FaInfo />
                      </Span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>

        </FirstRow>

        <div>

          <Card
            style={{ paddingBottom: 0 }}
            title="Letzte Bestellungen"
            icon={<ShoppingCart />}
          >

            <Table>
              <thead>
                <tr>
                  <th>Region</th>
                  <th>Typ</th>
                  <th>Anzahl</th>
                  <th>Datum</th>
                  <th>Kosten</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {o.map(row => (
                  <tr
                    key={row.id}
                  >
                    <th>{row.region?.name}</th>
                    <td>{row.translated_order_type}</td>
                    {row.order_type === 'SpecialOrder'
                      ? (
                        <>
                          <td colSpan={3}>{'description' in row.detailed_order && row.detailed_order.description}</td>
                          <td>{FormatDate(row.created_at)}</td>
                        </>
                      )
                      : (
                        <>
                          <td>{row.count_items}</td>
                          <td>{FormatDate(row.created_at)}</td>
                          <td>
                            {FormatMoney(row.total_price)}
                          </td>
                          <td>
                            <Span
                              as={Link}
                              aria-label={row.region?.name + ' BESTELLUNGEN ' + row.id}
                              title={row.region?.name + ' BESTELLUNGEN ' + row.id}
                              to={'/orders/' + row.id}
                              role="button"
                              tabIndex={0}
                            >
                              <FaInfo />
                            </Span>
                          </td>
                        </>
                      )}
                  </tr>
                ))}
              </tbody>
            </Table>

          </Card>

        </div>
      </RowOfTwo>
    </Container>
  )
}

const Block = styled.div`
display: block;
font-size: 16px;

> span {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 0.5em;
  width: 85px;
}
`

const Card = styled(OriginalCard)`
.clip-text {
  flex: 1;
  overflow: hidden;
  text-overflow: elipse;
  white-space: nowrap;
}
> ${Table} {
  height: 0;
  margin-left: -24px;
  min-height: 100px;
  width: calc(100% + 48px);

  tbody::before {
    content: '';
  }
}

&.card-orders {
  overflow-y: auto;
}
`

const Span = styled.span`
align-items: center;
background-color: #4c4c4c;
border: 1px solid #4c4c4c;
border-radius: 12px;
color: #fff;
display: inline-flex;
float: right;
height: 36px;
justify-content: center;
margin: 0 12px;
min-width: 36px;
width: 36px;
`
const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;
`
const RowOfTwo = styled.div`
  border: 0;
  display: flex;
  flex-flow: row wrap;

  > * {
    flex: 1 1 50%;
    padding-right: 2em;
  }

  > div:first-of-type {
    padding-right: 2em;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    > * {
      flex-basis: 100%;
      overflow-y: auto;
    }
  }
`
const FirstRow = styled.div`
  @media (max-width: ${breakpoint.size.sm}) {
    overflow-y: auto;

    > * {
      overflow-y: auto;
    }

    > #search-card {
      overflow-y: unset;
    }
  }
`
