/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Card, IconButton, Table, Pagintaion, Spinner } from 'Components'
import { useT } from '../translations'
import { FaInfo } from 'react-icons/fa'
import { FormatDate, FormatMoney, zeroFill } from 'Helpers'

interface P {
   data: any[]
   region: Region
   total: any,
   fetch: (n:number)=>Promise<any>
}

export const RegionOrdersCard: React.FC<P> = ({ data = [], fetch, region = {}, total = 5 }) => {
  const mounted = useRef(false)
  const t = useT()
  const [page, setPage] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const pageSize = 5
  const slice = data?.slice(page * pageSize, (page + 1) * pageSize) || []
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    setLoading(true)
    fetch(page)
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Card2
      key={region.region_key}
      style={{ paddingBottom: 0 }}
      title={region.name}
      onClick={console.log}
    >
      <Table>
        <thead>
          <tr>
            <th> {t.order_info.order_number} </th>
            <th> {t.order_info.order_type} </th>
            <th> {t.order_info.article} </th>
            <th> {t.order_info.article_types} </th>
            <th> {t.order_info.price} </th>
            <th> {t.order_info.orderer} </th>
            <th> {t.order_info.shipping_status} </th>
            <th> {t.order_info.order_date} </th>
            <th> {t.order_info.order_status}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {slice.length === 0 ? <tr><th>--</th></tr> : null}
          {slice.filter(Boolean).map(row =>
            (
              <tr
                key={row.id}
                className={row.state === 'canceled' ? 'disable-row' : ''}
              >
                <th>{zeroFill(row.id)}</th>
                <td> {row.translated_order_type} </td>
                {row.order_type === 'SpecialOrder'
                  ? <td colSpan={2}>{row.description}</td>
                  : (
                    <>
                      <td> {row.count_items} </td>
                      <td> {row.count_types} </td>
                    </>
                  )}
                <td> {FormatMoney(row.total_price)}</td>
                <td> {row.user?.name} </td>
                <TabelStatusCell style={{ minWidth: 100 }}>
                  {row.order_type === 'SpecialOrder'
                    ? null
                    : (
                      <>
                        <span
                          aria-label="Bearbeitet"
                          title="Bearbeitet"
                          className="success"
                        >{row.status.accepted} Bearbeitet</span>
                        <span
                          aria-label="Teilweise"
                          title="Teilweise"
                          className="warn"
                        >{row.status.partially} Teilweise</span>
                        <span
                          aria-label="Abgelehnt"
                          title="Abgelehnt"
                          className="danger"
                        >{row.status.declined} Abgelehnt</span>
                        <span
                          aria-label="Offen"
                          title="Offen"
                          className="info"
                        >{row.status.pending} Offen</span>
                      </>
                    )}
                </TabelStatusCell>
                <td>
                  {FormatDate(row.created_at || row.updated_at, true)}
                </td>
                <td className={row.state === 'canceled' ? 'disable-data' : ''}> {row.state === 'canceled' ? t.order_status_canceled : t.order_status_active}</td>
                <td>
                  {row.order_type === 'SpecialOrder'
                    ? null
                    : (
                      <IconButton
                        aria-label={region.name + ' Bestelldetails ' + row.id}
                        title={region.name + ' Bestelldetails ' + row.id}
                        as={Link}
                        to={'/orders/' + row.id}
                        bg={'#4C4C4C'}
                      >
                        <FaInfo />
                      </IconButton>
                    )}
                </td>
              </tr>
            ))}
        </tbody>

        {total > pageSize
          ? (
            <tfoot>
              <tr style={{ borderTop: '1px solid #ccc' }}>
                <td colSpan={9}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  >

                    <div style={{
                      width: 36,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    >
                      {loading ? <Spinner size={24} /> : null}
                    </div>
                    <Pagintaion
                      currentPage={page}
                      onSelect={setPage}
                      totalCount={total}
                      pageSize={pageSize}
                    />

                  </div>
                </td>
              </tr>
            </tfoot>
          )
          : null}
      </Table>
    </Card2>
  )
}

const Card2 = styled(Card)`
> ${Table} {
  height: 0;
  margin-left: -24px;
  width: calc(100% + 48px);

  tbody {
    min-height: 60px;
    .disable-row {
      background-color: #ffd1d133; 
      .disable-data {
        color: red;
        font-weight: 500;
        font-style: italic;
      }
    }
  }

  tbody::before {
    content: '';
  }

  tbody td a {
    height: 35px;
    margin: 0;
    width: 35px;
  }

}
`

const TabelStatusCell = styled.td`
column-gap: 4px;
display: flex;
flex-flow: wrap;

.success {
  color: var(--success);
}

.danger {
  color: var(--danger);
}

.info {
  color: #006DFF;
}

.warn {
  color: #BD5B0B;
}

span {
  border-top: 3px solid;
  color: #fff;
  display: inline-block;
  flex: 1 0;
  font-weight: bold;
  min-width: 0;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: ${breakpoint.size.lg}) {
  span {
    flex: 1 0 calc(50% - 2px);
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  span {
    flex: 1 0 100%;
  }
}
`
