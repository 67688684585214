import { clickTrapStopPropagation } from 'Helpers'
import React, { useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import styled from 'styled-components'
import brokenImage from '../../../Assets/images/no-photo-available.png'
import defaultImage from '../../../Assets/images/defaultImage.jpg'

export default function ImageSlider ({ src = [], name = '', className, ribbon }: { src: LineItemPicture[], name?: string, className?: any, ribbon?: Ribbon }) {
  const [index, setActive] = useState(0)
  const img = src[index] || {
    picture_file_name: 'no image',
    picture_url: undefined
  }
  const prev = (e) => {
    clickTrapStopPropagation(e)
    setActive(src[index - 1] ? index - 1 : src.length - 1)
  }
  const next = (e) => {
    clickTrapStopPropagation(e)
    setActive(src[index + 1] ? index + 1 : 0)
  }

  return (
    <Figure
      className={`${src.length > 1 ? 'show-nav' : ''} ${className}`}
    >
      <button
        title={'Vorschauen'}
        aria-label={'Vorschauen'}
        className="button-left"
        onClick={prev}
        style={{ left: 6 }}
      > <FiChevronLeft /> </button>
      <Img
        alt={`${name} Bild ${index + 1}/${src.length || 1}`}
        src={img.picture_url || defaultImage}
        onError={(e: any) => {
          e.target.onerror = null
          e.target.src = brokenImage
        }}
      />
      {ribbon && <div className={`ribbon ribbon-${ribbon.location}`}><span>{ribbon.content}</span></div> }
      <button
        title={'Nächste'}
        aria-label={'Nächste'}
        className="button-right"
        onClick={next}
        style={{ right: 6 }}
      > <FiChevronRight /> </button>
    </Figure>
  )
}
const Figure = styled.figure`
display: block;
height: 100%;
margin: 0;
position: relative;

button {
  align-items: center;
  background-color: var(--gray-230, #ededed);
  color: #262626;
  display: none;
  font-size: 24px;
  font-weight: 800;
  height: 48px;
  justify-content: center;
  position: absolute;
  top: 40%;
  width: 38px;
  z-index: 8;
}

button:hover {
  background-color: var(--gray-300, #ededed);
}

&.show-nav button {
  display: flex;
}

&.zoom > img {
  position: relative;
  width: auto !important;
}

/* common */
.ribbon {
  height: 150px;
  overflow: hidden;
  position: absolute;
  width: 150px;
}
.ribbon::before,
.ribbon::after {
  border: 5px solid var(--magenta_dark);
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
}
.ribbon span {
  background-color: var(--primary);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  padding: 15px 0;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  width: 225px;
}

/* top left*/
.ribbon-top-left {
  left: -10px;
  top: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-left-color: transparent;
  border-top-color: transparent;
}
.ribbon-top-left::before {
  right: 0;
  top: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  right: -10px;
  top: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-right-color: transparent;
  border-top-color: transparent;
}
.ribbon-top-right::before {
  left: 0;
  top: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
`
const Img = styled.img`
bottom: 0;
display: block;
height: 100%;
left: 0;
margin: auto;
object-fit: cover;
position: absolute;
right: 0;
top: 0;
width: 100%;
`
