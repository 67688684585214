/* eslint-disable react-hooks/exhaustive-deps */
import { PrimaryButton, TextInput, Spinner } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import api from 'Config/api'
import { REACT_APP_SMART_SSO_URL } from 'Env'
import { FormatDate } from 'Helpers'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { auth } from '../redux'
import { editProfile } from '../redux/actions'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useT } from '../translations'

const DATA_CHANGED_STATUS = 'Bitte speichern..'

export default function ProfilePage () {
  const user = useAppSelector(auth.selectUser)
  const isSupplier = useAppSelector(auth.isSupplier)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [data, setData] = useState<Record<string, any>>({ ...user })
  const [saving, toggleSaving] = useState('')
  const [enableTaskEmailsChanging, toggleEnableTaskEmailsChanging] = useState<boolean>(false)
  const oldData = useRef<any>({})
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()
  const t = useT()

  // enable_kam_emails
  useEffect(() => {
    api.get(`/api/v2/profiles?filter[user]=${user?.id}&filter[membership]=${user?.membership_id}`, {
      baseURL: REACT_APP_SMART_SSO_URL
    }).then(
      ({ data }) => {
        oldData.current = data.data[0].attributes
        setData({
          ...data.data[0].attributes,
          id: data.data[0].id
        })
      }
    ).catch(console.warn)
  }, [user?.id, user?.membership_id])

  const onInputChange = useCallback((e) => {
    const val = e.target.value
    const name = e.target.name
    setData(odata => {
      // toggle status indicate 'data changed'
      odata[name] !== val && toggleSaving(DATA_CHANGED_STATUS)

      return {
        ...odata,
        [name]: val
      }
    })
  }, [])

  const save = useCallback((attributes) => {
    if (saving !== DATA_CHANGED_STATUS) {
      return
    }
    const profileId = data.id
    oldData.current = attributes
    toggleSaving(t.save_please_wait)
    setErrors({})
    return dispatch(editProfile(profileId, attributes))
      .then(() => {
        addToast(
          (
            <div role="alert">
              {t.successfully_saved}
            </div>
          ), {
            appearance: 'success',
            autoDismiss: true
          }
        )
      })
      .catch(newErrors => {
        addToast(
          (
            <div role="alert">
              {t.not_valid}
            </div>
          ), {
            appearance: 'error',
            autoDismiss: true
          }
        )
        setErrors(newErrors)
      })
      .finally(() => {
        toggleSaving('')
      })
  }, [data?.id, dispatch, addToast, saving])

  const submit = useCallback((e:any) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const entries = Object.fromEntries(formData.entries())
    save(entries)
  }, [save])

  const saveEmailSettings = useCallback(
    (e) => {
      if (enableTaskEmailsChanging) return
      toggleEnableTaskEmailsChanging(true)
      const enableKamEmails = e.target.value === 'yes'
      const profileId = data.id
      return dispatch(editProfile(profileId, {
        company: data.company,
        phone_number: data.phone_number,
        street: data.street,
        houseno: data.houseno,
        city: data.city,
        zip: data.zip,
        enable_kam_emails: enableKamEmails
      }))
        .then(({ data }) => {
          oldData.current = data.data.attributes
          setData({
            ...data.data.attributes,
            id: data.data.id
          })
          addToast(
            (
              <div role="alert">
                {t.successfully_saved}
              </div>
            ), {
              appearance: 'success',
              autoDismiss: true
            }
          )
        })
        .catch(() => {
          addToast(
            (
              <div role="alert">
                {t.not_valid}
              </div>
            ), {
              appearance: 'error',
              autoDismiss: true
            }
          )
        })
        .finally(() => {
          toggleEnableTaskEmailsChanging(false)
        })
    },
    [data, dispatch, addToast, enableTaskEmailsChanging]
  )

  return (
    <Container>

      <Helmet>
        <title>{t.profile}</title>
      </Helmet>
      {/* <DashboardBreadCrumb /> */}
      <h1>
        <span>{t.profile}</span>
      </h1>
      <Center>
        <article>
          <div>
            <Div>
              <h2>{t.personal_data}</h2>

              <p>
                <span>{t.full_name}</span>
                <br />
                <span className="user-info">{data?.first_name + ' ' + data?.last_name}</span>
              </p>

              <p>
                <span>{t.email_address}</span>
                <br />
                <span className="user-info">{data?.email}</span>
              </p>

              <p>
                <span>{t.registration_date}</span>
                <br />
                <span className="user-info">{FormatDate(data.created_at, true)}</span>
              </p>
            </Div>

            <h2 style={{ fontSize: 24 }}>{t.delivery_address}</h2>

            <MyForm
              id="myform"
              style={{ maxWidth: 700 }}
              onSubmit={submit}
            >
              <TextInput
                block
                onChange={onInputChange}
                label={t.company}
                name="company"
                autoComplete="organization"
                value={data.company}
                width="100%"
              />

              <TextInput
                block
                onChange={onInputChange}
                label={t.phone_number}
                title={t.phone_number_invalid}
                pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3,})[-. )]*(\d{3,})[-. ]*(\d{2,})(?: *x(\d+))?\s*$"
                name="phone_number"
                autoComplete="phone_number"
                value={data.phone_number}
                width="100%"
              />

              <Row2>
                <TextInput
                  onChange={onInputChange}
                  label={t.street}
                  name="street"
                  autoComplete="street-address"
                  value={data.street}
                  width="60%"
                />
                <TextInput
                  onChange={onInputChange}
                  label={t.house_number}
                  autoComplete="house-number"
                  name="houseno"
                  value={data.houseno}
                  feedbackAtTop
                  width="35%"
                />
              </Row2>
              <Row2>
                <TextInput
                  onChange={onInputChange}
                  label={t.city}
                  autoComplete="address-level2"
                  name="city"
                  value={data.city}
                  width="55%"
                />
                <TextInput
                  autoComplete="postal-code"
                  pattern="[0-9]{5}"
                  title={t.zip_code_invalid}
                  onChange={onInputChange}
                  label={t.zip_code}
                  name="zip"
                  value={data.zip}
                  width="35%"
                  error={errors.zip}
                />
              </Row2>

              <Row2>
                <div id="result">{saving}</div>
                <div>
                  <PrimaryButton
                    type="submit"
                    disabled={saving !== DATA_CHANGED_STATUS}
                    aria-label={t.save}
                  >{t.save}</PrimaryButton>
                </div>
              </Row2>
            </MyForm>

            <Section id="select-email-settings">
              <div className="section-title">
                <h2 style={{ fontSize: 24 }}>
                  {t.email_settings.title}
                </h2>
              </div>
              <div className="section-content">
                <p className='section-info'>
                  {isSupplier ? t.email_settings.details_supplier : t.email_settings.details_others}
                </p>
                <label htmlFor={'yes-receive-emails'}>
                  <input
                    type="radio"
                    name="emailSettings"
                    id={'yes-receive-emails'}
                    value={'yes'}
                    disabled={enableTaskEmailsChanging}
                    checked={data?.enable_kam_emails}
                    onChange={saveEmailSettings}
                  />
                  <span>{isSupplier ? t.email_settings.yes_receive_emails_supplier : t.email_settings.yes_receive_emails_others}</span>
                </label>
                <label htmlFor={'no-receive-emails'}>
                  <input
                    type="radio"
                    name="emailSettings"
                    id={'no-receive-emails'}
                    value={'no'}
                    disabled={enableTaskEmailsChanging}
                    checked={!data?.enable_kam_emails}
                    onChange={saveEmailSettings}
                  />
                  <span>{isSupplier ? t.email_settings.no_receive_emails_supplier : t.email_settings.no_receive_emails_others }</span>
                </label>
                {enableTaskEmailsChanging && (
                  <Spinner
                    size={50}
                    color={'var(--magenta)'}
                  />
                )}
              </div>
            </Section>

          </div>
          <aside>
            <h2>{t.privacy_policy}</h2>
            <p>{t.privacy_policy_p1}</p>
            <p>{t.privacy_policy_p2}</p>
            <StaticPageLink
              aria-label={t.privacy_policy}
              to="/static/datenschutzerklaerung"
              rel="noopener noreferrer"
            >
              {t.privacy_policy}
            </StaticPageLink>
          </aside>
        </article>
      </Center>
    </Container>
  )
}

const Section = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 3em;
  position: relative;
  .section-content {
    display: flex;
    flex-flow: column;
    gap: 1.5em;
  }

  .section-info {
    color: #262626;
    flex: 1;
    font-size: 18px;
    margin-bottom: 0px;
  }

  label {
    color: #6b6b6b;
    display: inline-flex;
    font-size: 18px;
    gap: 1em;

    &:hover {
      cursor: pointer;
    }
  }
`

const Container = styled.div`
margin: 1em 0;

h1 {
  border-bottom: 1px solid #8c8c8c;
  font-size: 18px;
  font-weight: bold;

  span {
    border-bottom: 2px solid var(--primary);
    display: inline-block;
    padding: 0.5em 0;
  }
}

article {
  display: flex;
  flex-flow: row wrap;

  h2 {
    border-bottom: 1px solid #8c8c8c;
    padding-bottom: 0.5em;
  }

  aside {
    max-width: calc(300px + 5vw);
    padding-left: 5vw;

    h2 {
      border-bottom: 0;
      margin-top: 1.7em;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  article {
    > div:first-of-type {
      flex-basis: 100%;
    }

    aside {
      flex-basis: 100%;
      max-width: unset;
      padding-left: 0;
    }
  }
}
`

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`

const Row2 = styled.div`
display: flex;

> *:first-of-type {
  flex: 1;
  margin-right: 20px;
}
`
const Div = styled.div`
color: #6b6b6b;
flex: 1;
font-size: 18px;
margin-bottom: 3em;
min-width: 400px;

> p{
  margin-bottom: 0;
}

span.user-info,
h2 {
  color: #262626;
  font-weight: bold;
}

span.user-info {
  display: block;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

@media (max-width: ${breakpoint.size.xs}) {
  min-width: unset;
}
`

const MyForm = styled.form`
margin-bottom: 3em;
@media (max-width: ${breakpoint.size.xs}) {
  label[width='35%'] {
    width: 45%;
  }
}

`

const StaticPageLink = styled(Link)`
background-color: transparent;
color: #00739f;
font-size: 18px;
text-decoration: none;

:hover {
  color: #003c53;
  text-decoration: underline;
}
`
