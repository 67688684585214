/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import DashboardBreadCrumb from '../components/breadcrumb'
import { Card, Header, PrimaryButton, Table } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { region } from 'Features/region'
import { representative } from '../redux'
import { FormatDate } from 'Helpers'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Helmet } from 'react-helmet'

export default function Feature () {
  const dispatch = useAppDispatch()
  const regions = useAppSelector(region.selectRegions)
  const data = useAppSelector(representative.slice)

  useEffect(() => {
    dispatch(representative.fetch(undefined, true))
  }, [dispatch])

  return (
    <Container>
      <Helmet>
        <title>{'Vertretungen'}</title>
      </Helmet>
      <Header
        as='div'
        style={{
          justifyContent: 'space-between',
          flexFlow: 'row nowrap',
          display: 'flex'
        }}
      >
        <h2>Vertretungen Für Ihre Regions</h2>
        <PrimaryButton
          aria-label={'Vertretung erstellen'}
          as={Link}
          to="/representative/create"
        >
          Vertretung erstellen
        </PrimaryButton>
      </Header>
      <Content>
        {Object.values(regions).map(r => (
          <Card2
            key={r.region_key}
            style={{ paddingBottom: 0 }}
            title={r.name}
            onClick={console.log}
          >
            <Table>
              <thead>
                <tr>
                  <th> Vertretung </th>
                  <th> Vertreter für </th>
                  <th> Von </th>
                  <th> Bis </th>
                  <th colSpan={2}></th>
                </tr>
              </thead>
              <tbody>
                {(data.byRegion[r.region_key] || []).map(id => data.byId[id]).filter(Boolean).map(row => (
                  <tr key={row.id}>
                    <td>{`${row.substitute.first_name} ${row.substitute.last_name}`}</td>

                    <td>{`${row.initiator.first_name} ${row.initiator.last_name}`}</td>

                    <td>{FormatDate(row.start_date)}</td>

                    <td>{FormatDate(row.end_date)}</td>

                    <td>
                      <div className="actions">
                        <PrimaryButton
                          aria-label={'Bearbeiten'}
                          to={`/representative/${row.id}`}
                          as={Link}
                        >Bearbeiten</PrimaryButton>
                        <PrimaryButton
                          aria-label={'Löschen'}
                          onClick={() => dispatch(representative.removeRecord(`${row.id}`))}
                          style={{ marginLeft: 20 }}
                          bg="white"
                          color="red"
                        >Löschen</PrimaryButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card2>
        ))}
        <Header
          as='h2'
          style={{ marginTop: 48 }}
        >
          <span>Ihre Vertretungen</span>
        </Header>
        <Card2
          style={{
            paddingBottom: 0,
            paddingTop: 0
          }}
          onClick={console.log}
        >
          <Table>
            <thead>
              <tr>
                <th> Vertreter </th>
                <th> Vertreter für </th>
                <th> Von </th>
                <th> Bis </th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {data.assignedToMe.map(id => data.byId[id]).map(row => (
                <tr key={row.id}>
                  <td>{`${row.initiator.first_name} ${row.initiator.last_name}`}</td>

                  <td>{`${row.substitute.first_name} ${row.substitute.last_name}`}</td>

                  <td>{FormatDate(row.start_date)}</td>

                  <td>{FormatDate(row.end_date)}</td>
                  <td>
                    <div className="actions">

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card2>
      </Content>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;
`
const Content = styled.div`
border: 0;

@media screen and (max-width: 600px) {
  justify-content: center;
}

> div {
  flex: 1;
}

> div > div {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  font-size: 18px;
  margin-right: 2em;
  max-width: 400px;
  min-width: 300px;

  > h2 {
    border-bottom: 1px solid #ccc;
    font-weight: 600;
    padding: 24px;
    margin-top: 0;
  }

  > p {
    padding: 12px 24px 0;
    white-space: pre-line;
  }

  >a {
    line-height: 38px;
    margin: 24px 0;
    text-decoration: none;
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  overflow-y: auto;

  > div {
    overflow-y: auto;
  }
}

`

const Card2 = styled(Card)`
> ${Table} {
  height: 0;
  margin-left: -24px;
  min-height: 100px;
  width: calc(100% + 48px);

  tbody::before {
    content: '';
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
`
