import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState) => state[name] || initialState

export const selectCount = (state: RootState) => {
  const n = slice(state).unread
  return n < 0 || typeof n !== 'number' ? 0 : n
}
export const selectThreads = (state: RootState) => {
  return slice(state).data
}

export const selectPaginationValuse = (state: RootState) => {
  const s = slice(state)
  return [s.pageSize || 15, s.data?.length || 0]
}

export const selectChatFilters = (state: RootState) => slice(state).filter

export const selectChatFiltersKey = (state: RootState) => slice(state).cacheKey || ''
