import { ChatModal } from './screens/chatModal'

const routes = [
  {
    path: '/notifications/:keyword?',
    Component: ChatModal
  }
]

export default routes
