import * as thunks from './actions'
import { actions } from './reducer'
import * as selectors from './selectors'

export const cartTypes = ['Product', 'Event', 'CommMedium', 'MobileMedium', 'OnlineCommunication', 'PrintingHouseOrder', 'DigitalInfoEvents'] as const

export const product = {
  ...thunks,
  ...actions,
  ...selectors
}
