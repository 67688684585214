/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { FiSearch } from 'react-icons/fi'

// import DashboardBreadCrumb from '../components/breadcrumb'
import { Header, PrimaryButton, ResetButton } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { faq } from '../redux'
import { FaqList } from '../components/FaqList'
import { useLocation } from 'react-router'
import { batch } from 'react-redux'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Helmet } from 'react-helmet'

export default function FaqFeature () {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const categories = useAppSelector(faq.selectCategories)
  const searchText = useAppSelector(faq.selectFilters).searchText
  const ALLPRODUCTS = 'Alle'
  const selectedCategory = useAppSelector(faq.selectCategory)?.name || ALLPRODUCTS
  const [data, setData] = useState('')

  const toggleCategory = (category) => dispatch(faq.setFilter({ category }))

  useEffect(() => {
    dispatch(faq.setFilter({ category: '' }))
  }, [dispatch, location])

  useEffect(() => {
    batch(() => {
      dispatch(faq.fetchFaq())
    })
  }, [dispatch])

  const search = () => {
    dispatch(faq.setFilter({ searchText: data }))
  }

  const reset = () => {
    setData('')
    dispatch(faq.setFilter({ searchText: '' }))
  }
  const submitSearch = (e) => {
    console.log(e)
    // dispatch(faq.setFilter({ searchText: '' }))
  }

  return (
    <Container>
      {/* <DashboardBreadCrumb /> */}

      <Helmet>
        <title>Kam Box FAQ</title>
      </Helmet>
      <div className="title">
        <Header
          as="h2"
          style={{
            flex: 1,
            margin: 'auto'
          }}
        >
          <span>FAQ's</span>
        </Header>
        <div>
          <SearchBar
            as="form"
            onSubmit={submitSearch}
          >
            <div>
              <FiSearch />
              <input
                aria-label="Suchen"
                name="search"
                placeholder="Suchen"
                type="search"
                onChange={(e) => setData(e.target.value)}
              />
            </div>
            {searchText && searchText === data
              ? (
                <ResetButton
                  aria-label={'Zurücksetzen'}
                  onClick={reset}
                >Zurücksetzen</ResetButton>
              )
              : (
                <PrimaryButton
                  aria-label={'Suchen'}
                  disabled={data.length < 2}
                  onClick={search}
                >Suchen</PrimaryButton>
              )}
          </SearchBar>
        </div>
      </div>
      <RowOfTwo>

        <div>
          <Header as="h3">Kategorien</Header>
          <ul>
            <li className={`${selectedCategory === ALLPRODUCTS ? 'active' : ''}`}>
              <button
                aria-label={ALLPRODUCTS}
                onClick={() => toggleCategory('')}
              >{ALLPRODUCTS}</button>
            </li>
            {Object.values(categories).map(row => (
              <li
                className={`${selectedCategory === row.name ? 'active' : ''}`}
                key={row.id}
              >
                <button
                  aria-label={`FAQ Kategorie ${row.name}`}
                  onClick={() => toggleCategory(`${row.id}`)}
                >{row.name}</button></li>
            ))}
          </ul>
        </div>

        <div>
          <Header as="h3">{selectedCategory}</Header>
          <FaqList />
        </div>
      </RowOfTwo>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;

.title {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

ul {
  font-size: 18px;
  letter-spacing: 0;
  list-style: none;
  padding-left: 0;
  text-align: left;

  button {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 12px;
    padding-left: 0;
  }

  .active button {
    color: var(--primary);
  }
}
`
const RowOfTwo = styled.div`
  border: 0;
  display: flex;
  flex-flow: row wrap;

  > *:not(:first-of-type) {
    flex: 1;
  }

  > div:first-of-type {
    min-width: 211px;
    padding-right: 2em;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    > div {
      flex-basis: 100%;
    }
  }

`
const SearchBar = styled.div`
display: flex;
flex-flow: row wrap;
gap: 12px;

> div {
  position: relative;
  white-space: nowrap;
}

button {
  border-radius: 4px;
}

svg {
  color: #8c8c8c;
  font-size: 18px;
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

input {
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  height: 38px;
  padding-left: 38px;
  width: 200px;
}

@media (max-width: 360px) {
  > div,
  button {
    flex-basis: 100%;
  }

  input {
    width: 100%;
  }
}
`
