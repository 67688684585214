import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { zeroFill } from 'Helpers'
export interface OrderState{
  byId: Record<string, Order>,
  preview: Record<string, Array<Order|null>>,
  total: number
}

export const initialState:OrderState = {
  byId: {},
  preview: {},
  total: 0
}

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Order[]>) => {
      state.byId = action.payload.reduce((c, i, idx) => {
        c[i.id] = process(i, `${idx + 1}`)
        return c
      }, state.byId)
    },
    setSupplier: (state, action: PayloadAction<{ orders: Order[], total: number}>) => {
      const { orders, total } = action.payload
      state.byId = orders.reduce((c, i, idx) => {
        c[i.id] = process(i, `${idx + 1}`)
        return c
      }, {})
      state.total = total
    },
    preview: (state, action: PayloadAction<{offset?: number, regionId: string, data: Partial<Order>[], total: number}>) => {
      const { regionId, offset = 0, total, data } = action.payload
      state.preview = state.preview || {}
      state.preview[regionId] = state.preview[regionId] || []
      state.preview[regionId].length = total
      state.preview[regionId] = Array.from(state.preview[regionId], (e, idx) => idx >= offset ? process(data[idx - offset] || e, `${idx + 1}`) : e || null)
    },
    resetPreview: (state) => {
      state.preview = {}
    },
    updateItem: (state, { payload }: PayloadAction<{orderId: string, itemId: string, data: Partial<OrderItem>}>) => {
      const order = state.byId[payload.itemId]
      if (!order) return
      const item = order.order_items.findIndex(r => `${r.ordered_item_id}` === `${payload.itemId}`)
      if (item === -1) return
      order[item] = {
        ...order[item],
        ...payload.data
      }
    }
  }
})

export default slice

export const { actions, reducer, name } = slice

function process (row: Order|Partial<Order>, id?:string) {
  if (!row) { return row }
  const item = {
    ...row,
    id: zeroFill(row.id || id),
    order_type: row.order_type || 'ProductOrder',
    translated_order_type: row.translated_order_type || 'Produkt-Bestellung',
    total_price: row.total_price || 0,
    count_items: row.count_items || 0,
    count_types: row.count_types || row.order_items?.length || 0,
    user: row.user || {
      id: '01',
      name: 'Smart Admin'
    },
    status: row.status || {
      pending: 0,
      accepted: 0,
      partially: 0,
      declined: 0
    }
  } as Order
  row?.order_items?.forEach(ri => {
    item.count_items += ri.amount
    item.total_price += Number(ri.price) * Number(ri.amount)
    item.status[ri.supplier_state] += 1
    item.created_at = item.created_at || new Date()
    item.updated_at = item.updated_at || new Date()
  })

  return item
}
