import { MenuWithArrow } from 'Components'
// import { useT } from 'Features/cart/translations'
import React from 'react'

import { FaTv } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

export function NavWidget () {
  // const { cartTypes } = useT()
  return (
    <Nav className="nav-widget">
      <NavLink
        tabIndex={0}
        aria-label="Dashboard"
        title="Dashboard"
        to="/dashboard"
      >
        <FaTv
          fontSize={28}
        />
      </NavLink>
      <div style={{ width: 21 }} />

      {/* <MenuWithArrow
        key={'products'}
        classes="products-dropdown"
        showOnHover
        arrowPosition="left"
        links={
          Object.entries(cartTypes)
            .map(([id, name]) => ({
              label: name,
              props: id === 'PrintingHouseOrder'
                ? {
                  href: 'https://telekom-onlinedruckerei-kambox.johnen-portal.de/shop/module/kamBox/Shop/authorize'
                  // style: { display: 'none' } // remove me to enable link
                }
                : id === 'MobilePoS'
                  ? {
                    href: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' ? 'https://lama.smart.telekom-dienste.de' : 'https://lama.smarttest.8s.de'
                  }
                  : { to: '/products/' + id }
            }))}
        title={''}
        label={'Warengruppen'}
      >
        {() => (
          <NavLink
            aria-haspopup
            aria-label={'Warengruppen'}
            tabIndex={0}
            onClick={e => e.preventDefault()}
            to="/products"
          > Warengruppen </NavLink>
        )}
      </MenuWithArrow> */}
      <NavLink
        tabIndex={0}
        aria-label="Zum Regio Shop"
        title="Zum Regio Shop"
        to="https://regioshop.smart.telekom-dienste.de"
      >
        {'Zum Regio Shop'}
      </NavLink>
      <div style={{ width: 21 }} />
      <MenuWithArrow
        key={'news'}
        classes="pages-dropdown"
        showOnHover
        arrowPosition="left"
        title={''}
        label={'Sonstiges'}
        links={[
          {
            label: 'News',
            props: { to: '/pages/news' }
          },
          {
            label: 'FAQs',
            props: { to: '/pages/faq' }
          },
          {
            label: 'Dokumente',
            props: { to: '/pages/documents' }
          }
        ]}
      >
        {() => (
          <NavLink
            tabIndex={0}
            aria-haspopup
            aria-label={'Sonstiges'}
            onClick={e => e.preventDefault()}
            to="/pages"
          > Sonstiges </NavLink>
        )}
      </MenuWithArrow>
    </Nav>
  )
}

const Nav = styled.div`
  align-items: center;
  display: flex;
  flex: 1;

  a,
  button {
    color: #222;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
  }

  > div:hover > nav {
    display: block;
    transform: translateY(0);
  }

  @media (max-width: ${breakpoint.size.sm}) {
    .pages-dropdown {
      nav {
        left: unset;
        right: 0;

        &::before {
          left: unset;
          right: 30px;
        }

        &::after {
          left: unset;
          right: 0;
          width: 80px;
        }
      }
    }
  }
`
