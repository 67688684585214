/* eslint-disable */
import React, { MouseEventHandler, useEffect, useState } from 'react'

// import { FiUser } from 'react-icons/fi'
import { useLocation } from 'react-router'
import { auth } from 'Features/auth/redux'
import { useAppDispatch, useAppSelector } from 'Config'
import { MenuWithArrow, UserIcon } from 'Components'
import styled from 'styled-components'
import { chat } from 'Features/chat/redux'
import { REACT_APP_KAMBOX_API_URL } from 'Env'

export function UserDropdown ({showChat}:any) {
  const location = useLocation()
  const [active, toggleActive] = useState(false)
  const dispatch = useAppDispatch()
  const user = useAppSelector(auth.selectUser)
  const badgeCount = useAppSelector(chat.selectCount)

  const logout = (includeSSo = false):MouseEventHandler => e => {
    e.preventDefault()
    dispatch(auth.attemptLogout(includeSSo))
  }

  const menuLinks=[
    {label: 'Profil', props: {to: '/profile'}},
    {label: 'Bestellungen', props: {to: '/orders'}},
    {label: `Nachrichten (${badgeCount})`, props: {to: '/chat',onClick: showChat}},
    {label: 'Vertretungen', props: {to: '/representative'}},
    (user?.role.toLowerCase() === 'admin' || user?.role.toLowerCase() === 'sub_admin') && {label: 'Verwaltung/Admin', props: {href: `${REACT_APP_KAMBOX_API_URL||''}`.split('/').slice(0,-3).join('/')+'/admin'}},
    {label: 'Vom SSO abmelden', props: {to: '/logout/full',onClick: logout(true)}},
  ]


  useEffect(() => {
    toggleActive(menuLinks.some(item => item && item.props.to === location.pathname));
  }, [location])
 
  return (
    <MenuWithArrow classes={"user-dropdown"}
      links={menuLinks.filter(Boolean)}
      title={`${user?.first_name} ${user?.last_name}`}
      arrowOffset={14}
      label={"Nutzerprofil"}>
        
      <UserIconR className={active && 'active'} style={{
        fontSize: 24
      }}/>
      {badgeCount && badgeCount>0 ? <Badge>{badgeCount}</Badge> : null}
    </MenuWithArrow>
  )
}

const UserIconR = styled(UserIcon)`
  &.active {
    box-shadow: 0 3px var(--primary);
  }
`

const Badge = styled.span`
  background-color: var(--primary, rgba(206, 17, 38, 0.7));
  border-radius: 10px;
  bottom: 4%;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  left: 50%;
  line-height: 1rem;
  padding: 0.185rem 0.5rem 0.125rem 0.5rem;
  position: absolute;
  top: 51%;
`