import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState):typeof initialState => state[name] || initialState

export const last5 = (state: RootState) => {
  const n = slice(state)
  return Object.keys(n.byId).sort((a, b) => Number(a) < Number(b) ? -1 : 1).slice(0, 5).map(id => n.byId[id])
}
