
const size = {
  xs: '475px',
  sm: '900px',
  lg: '1200px'
}

const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`
}

export const breakpoint = {
  size,
  device
}
