import { RootState } from 'Config/store'
import { region } from 'Features/region'

import { name } from './reducer'

export const slice = (state: RootState) => state[name].carts

export const products = (state: RootState) => {
  const n = slice(state)
  const rkey = region.selectCurrentRegion(state)?.region_key

  return n ? n[rkey || 0] || [] : []
}

export const formula = (state: RootState) => state[name].formulas
export const selectFormula = name => (state: RootState) => formula(state)[name]
