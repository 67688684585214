import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Modal, PrimaryButton, ShoppingCart } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { product } from '../redux'
import { clickTrapStopPropagation, FormatMoney } from 'Helpers'
import { cart } from 'Features/cart'
import ImageSlider from './Image'
import ImageZoom from './ImageZoom'
import { FiCheck } from 'react-icons/fi'
import { useToasts } from 'react-toast-notifications'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { useT } from '../translations'
// eslint-disable-next-line sonarjs/cognitive-complexity
export function ProductDetail () {
  const t = useT()
  const navigate = useNavigate()
  const location = useLocation(); const dispatch = useAppDispatch()
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname
  })
  const params = useParams<{ type: string; id: string }>()
  const item = useAppSelector(product.selectProducts)[params.id || 0]
  const [amount, setAmount] = useState<Number | ''>('')
  const { addToast } = useToasts()
  const [zoom, toggleZoom] = useState(false)

  useEffect(() => {
    setRoute((prev) => ({
      to: location.pathname,
      from: prev.to
    }))
  }, [location])

  const addItem = () => {
    dispatch(cart.addItemToCart({
      id: item.id,
      type: item.line_item_type,
      amount: Number(amount || 1),
      unit: item.packaging_unit || 1
    })).then(() => {
      setAmount('')
    }).catch(e => {
      if (typeof e === 'string') {
        addToast(<div role="alert">{e}</div>, {
          appearance: 'error',
          autoDismiss: true
        })
      }
    })
  }
  // const back = () => history.push(location.pathname.split('/').slice(0, -1).join('/'))
  if (!item) { return null }

  return (
    <Modal
      title={item.name}
      onRequestClose={() => {
        if (zoom) {
          toggleZoom(false)
          return false
        } else {
          const routeFrom = route.from.split('/')
          const locationPath = location.pathname.split('/')
          if (
            route.from !== route.to &&
            JSON.stringify(routeFrom.slice(0, 3)) === JSON.stringify(locationPath.slice(0, 3)) &&
            routeFrom[3]
          ) {
            return navigate([...locationPath.slice(0, -1), routeFrom[3]].join('/'))
          }
          return navigate(locationPath.slice(0, -1).join('/'))
        }
      }}
    >
      <Details
        role="presentation"
        onKeyPress={(e) => e.stopPropagation()}
        onClick={clickTrapStopPropagation}
      >
        {zoom
          ? (
            <ImageSlider
              className="zoom"
              src={item.line_item_pictures}
              name={item.name}
            />
          )
          : (
            <Row>
              <aside>
                <ImageZoom
                  title={item.name}
                  src={item.line_item_pictures}
                  onClickZoom={() => toggleZoom(true)}
                  {...(item.badge && {
                    ribbon: {
                      location: 'top-right',
                      content: <><FiCheck size={13} /> {t.product_ordered}</>
                    }
                  })}
                />
              </aside>
              <div>
                {item.artno && (
                  <HeaderRow>
                    <span>Art-Nr. {item.artno}</span>
                  </HeaderRow>
                )}
                <div style={{
                  padding: '1em',
                  maxWidth: 699
                }}
                >
                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                  <SpaceBetween>
                    <span>Verpackungseinheiten: 1</span>
                    <span
                      title="price"
                      style={{
                        fontWeight: 'bold',
                        color: 'var(--success, #defefe)'
                      }}
                    >{FormatMoney(item.price)}</span>
                  </SpaceBetween>
                  <SpaceBetweenForm
                    onSubmit={(e) => {
                      e.preventDefault()
                      addItem()
                    }}
                  >
                    <div>
                      <input
                        id={`quantity_${item.artno}`}
                        aria-label={`Menge ${item.name}`}
                        title={`Maximal ${item.max_per_order || 99} Produkte`}
                        placeholder={`Maximal ${item.max_per_order || 99} Produkte`}
                        type="number"
                        max={item.max_per_order || 99}
                        min={item.packaging_unit || 1}
                        step={item.packaging_unit || 1}
                        value={`${amount}`}
                        required
                        onChange={e => setAmount(Number(e.target.value))}
                      />
                    </div>
                    <PrimaryButtonSubmit
                      disabled={item.badge || item.state !== 'active'}
                      type="submit"
                      aria-label={'In den Warenkorb'}
                    >
                      <ShoppingCart />
                      In den Warenkorb
                    </PrimaryButtonSubmit>
                  </SpaceBetweenForm>
                </div>
              </div>
            </Row>
          )
        }</Details>
    </Modal>
  )
}

const Details = styled.section`
border: 0 !important;
display: flex;
font-size: 1.5rem;
line-height: 44px;
margin-top: 0;
position: relative;

button {
  font-size: 1em;
}

figure {
  border: 0;
  height: 100%;
  margin: 0;
  min-height: 200px;
  min-width: 200px;
  padding: 0.25em;

  img {
    background-color: #ccc;
    display: block;
    margin: 0;
    max-height: 100%;
    width: 100%;
  }
}

`

const Row = styled.div`
display: flex;
flex-flow: row wrap;
min-width: 300px;

> aside > div {
  flex: 1;
  position: relative;

  img {
    height: 100%;
  }

  .button-zoom {
    bottom: 32%;
  }
}

> div {
  border: 1px solid #dfdfdf;
  box-shadow: 0 3px 6px #0000001a;
  flex: 3;
  margin-left: 1em;
}

p {
  line-height: 1.5em;
  white-space: pre-line;
}
`
const SpaceBetween = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
margin-top: 1em;

div > input {
  margin-right: 80px;
  padding: 0.5em 1em;
}

svg {
  margin-right: 0.5em;
  top: 3px;
}

@media (max-width: ${breakpoint.size.sm}) {
  flex-flow: row wrap;
  gap: 5px;

  div > input {
    margin: 0;
  }

  > span:first-of-type,
  > div:first-of-type {
    flex: 1 0 100%;
  }
}
`

const SpaceBetweenForm = styled.form`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
margin-top: 1em;

div > input {
  margin-right: 80px;
  padding: 0.5em 1em;
  width: 240px;
  text-align: center;
}

svg {
  margin-right: 0.5em;
  top: 3px;
}

@media (max-width: ${breakpoint.size.sm}) {
  flex-flow: row wrap;
  gap: 5px;

  div > input {
    margin: 0;
  }

  > span:first-of-type,
  > div:first-of-type {
    flex: 1 0 100%;
  }
}
`

const PrimaryButtonSubmit = styled(PrimaryButton)`
min-width: 240px;
`

const HeaderRow = styled.header`
  align-items: center;
  border-bottom: 1px solid #8c8c8c;
  display: flex;
  height: 2.5em;
  padding-left: 1em;

  span {
    font-weight: bold;
    top: 0;
  }

`
