import { useT } from '../translations'
import React from 'react'

import styled from 'styled-components'
import { PrimaryButton } from 'Components'
import { Link } from 'react-router-dom'
import { WizardSteps } from 'Components/WizardSteps'

export function CheckoutSuccess () {
  const t = useT()

  return (
    <div style={{ paddingTop: 24 }}>
      <WizardSteps
        steps={['Produkt-Bestellung', 'Bestellübersicht', 'Bestellbestätigung']}
        onClick={console.log}
        disabled
        activeIndex={2}
      />

      <H1>{t.checkoutMsg.title}</H1>

      <Row>
        <p>{t.checkoutMsg.body}</p>
      </Row>
      <Row>
        <Link to="/orders">
          <PrimaryButton
            aria-label={'Zum Dashboard'}
          >
            {t.checkoutMsg.submit}
          </PrimaryButton>
        </Link>
      </Row>
    </div>
  )
}
export default CheckoutSuccess

const H1 = styled.h1`
  margin-bottom: 24px;
  margin-top: 60px;
`
const Row = styled.div`
  display: block;

  > p {
    color: #262626;
    font-size: 18px;
    letter-spacing: 21px;
    letter-spacing: 0;
    margin: 2em 0;
    max-width: 700px;
    opacity: 1;
    text-align: left;
  }
`
