const categoryPage = {
  required: ' muss ausgefüllt werden',
  checkoutMsg: {
    title: 'Ihre Bestellung wurde erfolgreich abgesendet.',
    body: 'Zusätzlich haben wir Ihnen eine Bestellbestätigung per E-Mail zukommen lassen. Bitte beachten Sie, dass die Bearbeitung Ihrer Bestellung einige Zeit in Anspruch nehmen kann.',
    submit: 'Zum Dashboard'
  },
  datePicker: {
    placeholderDate: 'tt.mm.jjjj'
  },
  fileInput: {
    copyright: 'Copyright'
  },
  cartTypes: {
    Product: 'Produkte',
    Event: 'Events',
    CommMedium: 'Kommunikationsmittel',
    MobileMedium: 'Mobile Medien',
    OnlineCommunication: 'Online-Kommunikation',
    DigitalInfoEvent: 'Digitale Infoveranstaltungen',
    PrintingHouseOrder: 'Individuelle Werbemittel',
    MobilePoS: 'LaMa (Mobile PoS)'
  },
  formula: {
    webbanner: {
      id: 'webbanner',
      label: 'Webbanner',
      type: 'OnlineCommunication'
    },
    generic_webbanner: {
      id: 'generic_webbanner',
      label: 'Generischer Webbanner',
      type: 'OnlineCommunication'
    },
    facebook_post_expansion: {
      id: 'facebook_post_expansion',
      label: 'Facebook Post - Ausbauinformation (Dark Post)',
      type: 'OnlineCommunication'
    },
    facebook_post_event: {
      id: 'facebook_post_event',
      label: 'Facebook Post - Veranstaltung (Event-Posting)',
      type: 'OnlineCommunication'
    },
    landingpage: {
      id: 'landingpage',
      label: 'Beauftragung Landingpage',
      type: 'OnlineCommunication'
    },
    // COMMedia
    product_ad: {
      id: 'product_ad',
      label: 'Produktanzeige',
      type: 'CommMedium'
    },
    announcement_ad: {
      id: 'announcement_ad',
      label: 'Ankündigungsanzeige',
      type: 'CommMedium'
    },
    kvz: {
      id: 'kvz',
      label: 'KVZ-Aufkleber',
      type: 'CommMedium'
    },
    election_poster: {
      id: 'election_poster',
      label: 'Wahlplakate',
      type: 'CommMedium'
    },
    pos: {
      id: 'pos',
      label: 'Zuführung POS',
      type: 'CommMedium'
    },
    newspaper_supplement: {
      id: 'newspaper_supplement',
      label: 'Zeitungsbeileger/50-100 MBit/s',
      type: 'CommMedium'
    },
    newspaper_announcement: {
      id: 'newspaper_announcement',
      label: 'Zeitungsbeileger/Ankündigung',
      type: 'CommMedium'
    },
    roll_bag: {
      id: 'roll_bag',
      label: 'Brötchentüte',
      type: 'CommMedium'
    },
    election_poster_through_agency: {
      id: 'election_poster_through_agency',
      label: 'Wahlplakat (Anbringung durch Agentur)',
      type: 'CommMedium'
    }
  }
} as const

export default categoryPage
