import React, { FC, useCallback } from 'react'

import { ifEnter } from 'Helpers'
import t from 'prop-types'
import styled from 'styled-components'

export interface LinkSwitchProps{
  values: {id: string; label: string;}[],
  value: string;
  onChange: (id: string)=>void;
}

export const LinkSwitch: FC<LinkSwitchProps> = ({ values, value, onChange, ...props }) => {
  const onClick = useCallback(e => {
    const currValue = e.target.parentNode.dataset.active

    e.preventDefault()
    if (currValue === e.target.name) return
    onChange(e.target.name)
  }, [onChange])

  return (
    <div
      data-testid="linkswitch"
      role="radiogroup"
      {...props}
      data-active={value}
      tabIndex={-1}
    >
      {values.map(({ id, label }, idx) => (
        <A
          role="radio"
          aria-checked={value === id}
          aria-label={label}
          name={id}
          tabIndex={idx + 1}
          active={value === id}
          key={id}
          onClick={onClick}
          onKeyDown={ifEnter(onClick)}
        >
          {label}
        </A>
      ))}
    </div>
  )
}

LinkSwitch.propTypes = {
  value: t.string.isRequired,
  values: t.arrayOf(t.shape({
    id: t.string.isRequired,
    label: t.string.isRequired
  }).isRequired).isRequired,
  onChange: t.func.isRequired
}

LinkSwitch.defaultProps = {
  onChange: console.log
}

const A = styled.button<{name: string; active: boolean}>`
  background-color: none;
  border: 0;
  color: var(--${({ active }) => active ? 'primary' : 'dark'});
  cursor: pointer;
  display: inline-block;
  min-width: 32px;
  outline: none;
`
