import { PrimaryButton } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { chat } from 'Features/chat/redux'
import { region } from 'Features/region'
import React, { useEffect, useState } from 'react'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

import styled from 'styled-components'

export function ChatSearchBar () {
  const dispatch = useAppDispatch()
  const filters = useAppSelector(chat.selectChatFilters)
  const regions = useAppSelector(region.selectRegions)
  const [orderId, setOrderId] = useState(filters?.order_id || '')

  useEffect(() => {
    orderId && setOrderId(filters?.order_id || '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <Container>
      <input
        placeholder="Bestellnummer"
        value={orderId || ''}
        onChange={e => setOrderId(e.target.value) }
      />

      <PrimaryButton
        aria-label={'Suchen'}
        onClick={() => dispatch(chat.setFilters({
          page: 0,
          region_id: filters?.region_id,
          order_id: orderId
        }))}
      >
        {(() => {
          if (orderId !== filters?.order_id) {
            return 'Suchen'
          }
          if (orderId) return 'zurücksetzen'
          return 'Suchen'
        })()}
      </PrimaryButton>

      <div style={{
        flex: 1,
        minWidth: '1em'
      }}
      />

      <select
        onChange={e => dispatch(chat.setFilters({
          page: 0,
          region_id: e.target.value,
          order_id: orderId
        }))}
        value={filters?.region_id || ''}
      >
        <option value="">Filter nach Region</option>
        {Object.values(regions).map(row => (
          <option
            value={row.id}
            key={row.region_key}
          >{row.name}</option>
        ))}
      </select>

    </Container>
  )
}

const Container = styled.div`
display: flex;
position: relative;

select {
  background-color: var(--gray-200);
  max-width: calc(100% - 210px);
}

> input {
  border: 1px solid var(--grey-500, #8c8c8c);
  border-radius: 4px;
  flex: 1;
  margin-right: 0.5rem;
  padding: 0.5em 1em;
}

@media (max-width: ${breakpoint.size.sm}) {
  flex-flow: row wrap;
  gap: 0.5rem;
  input {
    margin: 0;
    flex: 1 0 calc(100% - 100px);
  }

  input {
    margin: 0;
    flex: 1 0 calc(100% - 100px);
  }

  > div:first-of-type {
    display: none;
  }

  select {
    padding: 0.5em 1em;
    min-width: 200px;
  }
} 

`
