import React from 'react'
import styled from 'styled-components'

import ImageSlider from 'Features/product/components/Image'
import { FiZoomIn } from 'react-icons/fi'

export default function ImageZoom ({ src = [], title, size = 55, onClickZoom, ribbon }: { src: LineItemPicture[], title?: string, size?: number, onClickZoom: any, ribbon?: Ribbon }) {
  return (
    <ImageZoomContainer className={'zoom-container'}>
      <button
        className="button-zoom"
        title={`Zoom-Bild für ${title}`}
        aria-label= {`Zoom-Bild für ${title}`}
        onClick={onClickZoom}
      >
        <FiZoomIn
          color="rgba(255,255,255,.8)"
          size={size}
        />
      </button>
      <ImageSlider
        className={'image-slider'}
        src={src}
        name={title}
        ribbon={ribbon}
      />
    </ImageZoomContainer>
  )
}

const ImageZoomContainer = styled.div`
.button-zoom {
  bottom: 25%;
  width: fit-content;
  position: absolute;
  opacity: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
}

&:hover .button-zoom,
.button-zoom:focus {
  opacity: 1;
}
`
