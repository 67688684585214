import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const ID_PARAM = 'id'

export const documentsAdapter = createEntityAdapter()

export const initialState = documentsAdapter.getInitialState()

const slice = createSlice({
  name: 'Document',
  initialState,
  reducers: {
    add: documentsAdapter.setAll
  }
})

export default slice

export const { actions, reducer, name } = slice
