import { Checkbox, TextInput, DateTimePicker } from 'Components'
import React from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import { useT } from '../../translations'
import styled from 'styled-components'
import { FormInput } from './Form'
import { useAppDispatch } from 'Config'
import { orders } from 'Features/orders'

// const patterns = {
//   receiver_phone: '^\\+?[0-9 ]{7,}',
//   receiver_name: '[a-zA-Z0-9 ]+',
//   company_name: '[a-zA-Z0-9 ]+',
//   email: '[^@]{1,}@[a-z0-9][^.]*\\.[a-z]+',
//   receiver_houseno: '.{2,}'
// }

interface I{
  i: FormInput,
  onChange: any,
  onChangeCopyright: any,
  onChangeTime: any,
  prefix?: string|null,
  value: Record<string, any>,
  errors?: Record<string, any>,
  readOnly?: boolean | {},
  orderDetailsView?: boolean,
  orderId?: string;
}

const fileStyle = (hasError, editDigitaleActive = false) => ({
  display: 'block',
  width: '100%',
  border: '1px solid',
  borderColor: hasError ? 'var(--danger)' : 'var(--gray-600)',
  borderRadius: 8,
  margin: '1em 0',
  padding: '0.5em',
  color: editDigitaleActive ? 'var(--cyan)' : 'var(--gray-600)'
})

// eslint-disable-next-line sonarjs/cognitive-complexity
export function FormRow ({ i, onChange, onChangeCopyright, onChangeTime, prefix, value, orderId, errors = {}, readOnly, orderDetailsView }: I) {
  const t = useT()
  const dispatch = useAppDispatch()
  const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]'
  const name = prefix ? `${prefix}.${i.name}` : i.name
  const isReadOnly = isObject(readOnly) ? readOnly?.[name] || false : readOnly

  if (i.depend_on && !value[prefix ? `${prefix}.${i.depend_on}` : i.depend_on] && !(orderDetailsView && value[prefix ? `${prefix}.${i.name}` : i.name])) {
    return null
  }

  if (i.type === 'boolean') {
    return (
      <Checkbox
        key={name}
        name={name}
        disabled={isReadOnly}
        width={`calc(${Math.floor(Number(i.width) || 100) + '%'} - 1em)`}
        onChange={onChange(i)}
        label={i.localized}
        isChecked={!!value[name]}
        required={!!i.meta_data?.required}
      />
    )
  }

  const selectOption = (v: string) => {
    const values = v.split('|')
    const optionValue = values.length > 1 ? values[1] : values[0]
    return (
      <option
        key={optionValue}
        value={optionValue}
      >{values[0]}</option>
    )
  }

  if (Array.isArray(i.collection) && i.collection.length > 1) {
    return (
      <Label
        id={`${name}_label`}
        width={`calc(${Math.floor(Number(i.width) || 100) + '%'} - 1em)`}
        htmlFor={name}
      >
        <span>{i.localized + `${i.meta_data?.required ? '*' : ''}`}</span>
        <select
          id={name}
          value={value[name] || ''}
          disabled={!!isReadOnly}
          onChange={onChange(i)}
          name={name}
          required={!!i.meta_data?.required}
        >
          <option label="--"></option>
          {i.collection.map(v => selectOption(v))}
        </select>
      </Label>
    )
  }
  if (i.type === 'file') {
    return (
      <Label
        id={`${name}_label`}
        width={`calc(${Math.floor(Number(i.width) || 100) + '%'} - 1em)`}
        htmlFor={name}
      >
        <span style={{
          position: 'relative',
          color: 'var(--gray-600, #d90000)',
          top: '1em'
        }}
        >{i.localized}</span>
        {!!value[name] && !Array.isArray(value[name]) && (isReadOnly || isObject(readOnly)) && value[name].url && value[name].url.indexOf('/') > -1
          ? (
            <>
              <a
                style={fileStyle(!!errors[name], !isReadOnly && isObject(readOnly))}
                target="_blank"
                rel="noreferrer"
                href={value[name].url}
              >{value[name].name || `${value[name].url}`.split('/').pop()?.split('?')[0]}</a>
              {isObject(readOnly) && isReadOnly && (
                <DeleteIcon onClick={() => {
                  dispatch(orders.deleteVideo(value[name].id)).then(() => dispatch(orders.fetchOrderDetails(orderId ?? '')))
                }}
                />
              )}
              {value[name].copyright && (
                <>
                  <TextInput
                    key={`${value[name].name}_copyright`}
                    className={'has-value '}
                    type={'string'}
                    block
                    width={'100%'}
                    readOnly={true}
                    label={`${t.fileInput.copyright} ${value[name].name}`}
                    name={`copyright_${value[name].name}`}
                    value={value[name].copyright}
                    mode={'default'}
                  />
                </>
              )}
            </>
          )
          : null }
        {!!value[name] && Array.isArray(value[name]) && (isReadOnly || isObject(readOnly)) && (
          value[name].map((file, index) =>
            (file.url && file.url.indexOf('/') > -1 && (
              <React.Fragment key={`${name}_${index}`}>
                <a
                  key={`${name}_${index}`}
                  style={fileStyle(!!errors[name], !isReadOnly && isObject(readOnly))}
                  target="_blank"
                  rel="noreferrer"
                  href={file.url}
                >{file.name || `${file.url}`.split('/').pop()?.split('?')[0] }</a>
                {file.copyright && (
                  <TextInput
                    key={`copyright_${file.name}_${index}`}
                    className={'has-value'}
                    type={'string'}
                    block
                    width={'100%'}
                    readOnly={true}
                    label={`${t.fileInput.copyright} ${file.name}`}
                    name={`copyright_${file.name}`}
                    value={file.copyright}
                    mode={'default'}
                  />
                )}
              </React.Fragment>
            )))
        )}

        <input
          disabled={isReadOnly}
          type={!orderDetailsView && !(isReadOnly && isObject(readOnly)) && value[name]?.id === undefined ? 'file' : 'hidden'}
          name={name}
          placeholder={value[name]}
          required={!!i.meta_data?.required && value[name] === undefined}
          multiple={!!i.meta_data?.multiple_files}
          style={fileStyle(!!errors[name], !isReadOnly && isObject(readOnly))}
          onChange={(event:React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, HTMLInputElement>) => onChangeCopyright(i)(event.target.files?.length, event.target.name)}
        />

        {errors[name] && (
          <small style={{
            position: 'relative',
            color: 'var(--danger, #d90000)',
            top: '-1em'
          }}
          >{errors[name]}</small>
        )}
      </Label>
    )
  }

  if (i.type === 'date' || i.type === 'datetime' || i.type === 'time') {
    return (
      <>
        <DateTimePicker
          key={name}
          className={'has-value'}
          type={i.type}
          containerStyle={{ margin: '0 1em 0 0' }}
          block
          width={`calc(${Math.floor(Number(i.width) || 100) + '%'} - 1em)`}
          readOnly={!!isReadOnly}
          onChangeTime={onChangeTime(i)}
          label={i.localized}
          name={name}
          value={value[name] || ''}
          placeholder={t.datePicker.placeholderDate}
          mode={errors[name] ? 'error' : 'default'}
          {...propsFromMetaData(i, value)}
          message={errors[name] ? errors[name].toString() : ''}
        ></DateTimePicker>
      </>
    )
  }

  const isDate = (i.type === 'datetime' || i.type === 'date' || i.type === 'time')
  return (
    <TextInput
      key={name}
      className={[(isDate || i.type === 'file') ? 'has-value' : '', !isReadOnly && isObject(readOnly) ? 'editDigitaleActive' : ''].join(' ') }
      type={i.type === 'datetime' ? 'datetime-local' : i.type}
      containerStyle={{ margin: '0 1em 0 0' }}
      block
      width={`calc(${Math.floor(Number(i.width) || 100) + '%'} - 1em)`}
      readOnly={!!isReadOnly}
      onChange={onChange(i)}
      label={i.localized}
      name={name}
      value={value[name] || ''}
      mode={errors[name] ? 'error' : 'default'}
      {...propsFromMetaData(i, value)}
      message={errors[name]?.toString() || ''}
    />
  )
}

function propsFromMetaData (i: FormInput, currentFormValue = {}) {
  const { days_offset, ...extraProps } = i.meta_data
  const props: Record<string, any> = {}

  /** ******* calculate days_offset from meta *************/
  const offset = Number(days_offset)

  if (Number.isNaN(offset)) {
    if (days_offset && currentFormValue[days_offset]) {
      // offset contain another field name.
      const today = new Date(currentFormValue[days_offset])
      if (`${today}` !== 'Invalid Date') {
        const min = new Date(today.setDate(today.getDate() + 1))
        props.min = min.toJSON().split('T')[0]
      }
    }
  } else {
    const today = new Date()
    const min = new Date(today.setDate(today.getDate() + offset))
    if (i.type === 'date') {
      props.min = min.toJSON().split('T')[0]
    } else {
      props.min = min.toJSON().split('.')[0]
    }
  }

  return {
    ...props,
    ...extraProps
  }
}
const Label = styled.label<{width: any}>`
color: var(--gray-600);
display: block;
font-size: 18px;
margin: 0 1em 0 0;
padding-bottom: 1em;
position: relative;
width: ${p => p.width};

> span {
  display: block;
  font-size: 1em;
  padding: 0.5em 0;
}

> select {
  border: 1px solid;
  border-color: var(--gray-600);
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
}
`
const DeleteIcon = styled(BsFillTrashFill)`
display: inline-block !important;
height: 1em;
position: absolute;
right: 0.5em;
stroke-width: 0;
top: 4.3em;
transform: translateY(-50%);
width: 1em;

`
