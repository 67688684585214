import React, { Fragment, ReactElement, useCallback, useState } from 'react'
import { MenuAccordion } from 'Components'
import { ScaleAppHeader } from '@telekom/scale-components-react'
import logo from 'Assets/brand/dt-logo.svg'
import { BudgetWidget } from 'Features/region'
import { UserDropdown } from 'Features/region/components/UserDropdown'
import { ChatModal } from 'Features/chat/screens/chatModal'
import { NavWidget, SupplierWidget } from 'Features/dashboard'
// import { useT } from 'Features/cart/translations'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export interface HeaderProps {
  /**
   * name to display under banner
   */
  subheader?: string;
  isSupplier?: boolean
}

export function BrandHeader ({ subheader, isSupplier }: HeaderProps): ReactElement {
  // const { cartTypes } = useT()
  const [chatVisible, toggleChat] = useState(false)
  const showChat = useCallback((e?: any) => {
    if (e) e?.preventDefault()
    toggleChat(true)
  }, [])
  const hideChat = useCallback(() => toggleChat(false), [])

  // const cartTypesLink = Object.entries(cartTypes).map(([id, name]) => ({
  //   name,
  //   id,
  //   ...(id === 'PrintingHouseOrder'
  //     ? {
  //         href: 'https://telekom-onlinedruckerei-kambox.johnen-portal.de/shop/module/kamBox/Shop/authorize',
  //       }
  //     : id === 'MobilePoS'
  //     ? {
  //         href:
  //           process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production'
  //             ? 'https://lama.smart.telekom-dienste.de'
  //             : 'https://lama.smarttest.8s.de',
  //       }
  //     : { to: '/products/' + id }),
  // }))

  const newsLinks = [
    {
      name: 'News',
      id: 'news',
      to: '/pages/news'
    },
    {
      name: 'FAQs',
      id: 'faqs',
      to: '/pages/faq'
    },
    {
      name: 'Dokumente',
      id: 'dokumente',
      to: '/pages/documents'
    }
  ]

  return (
    <Fragment>
      {chatVisible ? <ChatModal toggleChat={hideChat} /> : null}

      <ScaleAppHeaderContainer
        slot="header"
        id="header"
        logoHref="/"
        logoTitle={'Home Page'}
        iconNavigation={[
          {
            id: 'menu',
            icon: 'action-loop',
            defaultName: '',
            openedName: ''
          }
        ]}
      >
        <div slot="logo">
          <Link
            to="/"
            className="img-app-logo"
          >
            <img
              src={logo}
              alt="Telekom Logo"
              title={'Home Page'}
            />
            <span className="sr-only">Telekom Logo</span>
          </Link>
        </div>

        <div slot="menu-addon">
        </div>

        <div slot="menu-main">
          { isSupplier
            ? ((subheader && typeof subheader === 'string') && (
              <div
                id={'home-link'}
                className="home-link"
              >
                <h1>
                  {subheader?.split(' ')[0]}{' '}
                  <strong>{subheader?.split(' ').slice(1).join(' ')}</strong>
                </h1>
              </div>
            ))
            : <NavWidget />}
        </div>

        <div slot="menu-icon">
          {!isSupplier && <BudgetWidget />}
          {isSupplier ? <SupplierWidget showChat={showChat} /> : <UserDropdown showChat={showChat} />}
        </div>

        {!isSupplier && (
          <div slot="menu-mobile">
            <MenuAccordion
              loopTab={true}
              closeOnSelect={true}
              onClose={() => {
                const closeBtn = document.querySelector<HTMLAnchorElement>('scale-nav-icon a')
                closeBtn && closeBtn.click()
                closeBtn && closeBtn.focus()
              }}
              menuItems={[
                // {
                //   name: 'Warengruppen',
                //   id: 'product-groups',
                //   to: '/products',
                //   children: cartTypesLink,
                //   disabled: true,
                // },
                {
                  name: 'Zum Regio Shop',
                  id: 'to_regio',
                  href: 'https://regioshop.smart.telekom-dienste.de'
                },
                {
                  name: 'Sonstiges',
                  id: 'pages-groups',
                  to: '/pages',
                  children: newsLinks
                }
              ]}
            />
          </div>
        )}
      </ScaleAppHeaderContainer>
    </Fragment>
  )
}

const ScaleAppHeaderContainer = styled(ScaleAppHeader)`
  app-logo {
    display: flex;
    align-items: center;
  }

  .img-app-logo {
    img {
      height: 38px;
    }
  }

  .header .header__nav__mobile-menu--opened {
    back: red;
    border: 1 px solid red;
    border-radius: 10px;
  }
`
