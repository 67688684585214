import { Card } from 'Components'
import { FormatDate } from 'Helpers'
import React from 'react'

import styled from 'styled-components'
import { NewsState } from '../redux/reducer'

export const NewsList: React.FC<{data: NewsState, year: string}> = ({ data, year }) => {
  const ids = Object.keys(data.byId).filter(r => data.byId[r].created_at.indexOf(year) > -1)
  return (
    <Container>
      {ids.length === 0
        ? (
          <h5>
            Für das Schlüsselwort.
          </h5>
        )
        : null}
      {Array.from(ids).sort((a, b) => Number(a) < Number(b) ? -1 : 1).map(id => (
        <FaqCard
          key={id}
          nopadding
          visible
        >
          <div className="news-header">
            <h3>{data.byId[id].title}</h3>
          </div>
          <p dangerouslySetInnerHTML={{ __html: data.byId[id].body }} />
          <footer>
            <span>{ FormatDate(data.byId[id].updated_at) }</span>
          </footer>
        </FaqCard>
      ))}
    </Container>
  )
}

const Container = styled.section`
h5 {
  color: #8c8c8c;
  font-size: 18px;
}

div.news-header {
  display: flex;
  justify-content: flex-end;

  div {
    display: flex;
  }
}
`
const FaqCard = styled(Card)<{visible: boolean}>`
color: #262626;
font-size: 18px;
line-height: 21px;
padding: 0;

a,
em {
  color: #00739f;
}

p {
  display: ${({ visible }) => visible ? 'block' : 'none'};
  padding: 0 24px;
  white-space: pre-wrap;
}

div.news-header {
  align-items: center;
  border-bottom: 1px solid var(--gray-300, #3c3c3c);
  display: flex;
  flex-flow: row nowrap;
  padding: 24px;

  h3 {
    flex: 1;
    line-height: 32px;
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
  }

  span {
    align-self: baseline;
    background-color: #4c4c4c;
    color: #fff;
    line-height: 16px;
    margin: 0 8px;
    padding: 8px 12px;
  }

  svg {
    color: var(--gray-300, #3c3c3c);
    font-size: 2em;
  }
}

footer {
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin: 24px;

  span {
    color: #262626;
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
  }

  button {
    margin-left: 24px;
  }
}
`
