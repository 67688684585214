import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState) => state[name] || initialState

export const selectFaqs = (state: RootState) => {
  return slice(state).byId
}

export const selectFiltered = (state: RootState) => {
  return slice(state).ids
}

export const selectCategory = (state: RootState) => {
  const { category, filter } = slice(state)

  return filter.category ? category[filter.category] : null
}

export const selectCategories = (state: RootState) => {
  const { category } = slice(state)

  return category
}

export const selectFilters = (state: RootState) => {
  return slice(state).filter
}
