import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState) => state[name] || initialState

export const selectRegions = (state: RootState) => {
  return slice(state).byId
}

export const myRegions = (state: RootState) => {
  return Object.values(slice(state).byId).filter(r => !!r.managed)
}

export const selectCurrentRegion = (state: RootState):Region | null => {
  const { byId } = slice(state)
  const selected = Object.values(byId).filter(row => !!row.selected)[0]

  return selected || null
}
