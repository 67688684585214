import React from 'react'
import styled from 'styled-components'

interface I{
  name: string,
  label: string,
  onChange: any
  width?: string;
  isChecked?:boolean,
  disabled?:boolean,
  required?:boolean,
}

export function Checkbox ({ name, width, isChecked, disabled, label, onChange, required }:I) {
  return (
    <Label
      htmlFor={name}
      width={width}
    >
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={!!isChecked}
        required={required}
        disabled={disabled}
        onChange={onChange}
      />
      {label} {required ? '*' : ''}
    </Label>
  )
}

const Label = styled.label<{block?: boolean; size?: string, label?:string, disabled?:boolean, width?:string}>`
color: ${pickColorMixin};
display: ${({ block }) => block ? 'block' : 'inline-block'};
font-size: ${({ size = '18px' }) => size};
line-height: 1em;
margin: 2em 0 1em;
outline: none;
position: relative;
width: ${({ block, width }) => width || (block ? '100%' : 'auto')};

input {
  height: 1em;
  margin: 4px 1em 1px 1px;
  width: 1em;
}
`
function pickColorMixin ({ disabled, mode }: any) {
  if (disabled) {
    return `var(--${MAP_STATE_TO_CLASSNAME.mute})`
  }

  const color = MAP_STATE_TO_CLASSNAME[mode] || MAP_STATE_TO_CLASSNAME.default

  return `var(--${color})`
}
const MAP_STATE_TO_CLASSNAME = {
  error: 'danger',
  info: 'info',
  success: 'success',
  mute: 'gray-250',
  default: 'gray-600',
  primary: 'primary'
} as const
// const CLASSES = (Object.keys(MAP_STATE_TO_CLASSNAME) as Array<keyof typeof MAP_STATE_TO_CLASSNAME>)
