/* eslint-disable sonarjs/cognitive-complexity */
import React, { KeyboardEventHandler, ReactNode, useState, useEffect } from 'react'

import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { BsChevronUp, BsChevronDown } from 'react-icons/bs'

interface MenuItemBasic {
  name: string;
  id: string;
  expanded?: boolean;
  component?: ReactNode;
  children?: MenuItem[];
  disabled?: boolean;
}

interface LinkHref extends MenuItemBasic {
  href: string;
  to?: never;
}
interface LinkTo extends MenuItemBasic {
  href?: never;
  to: string;
}

interface NoLink extends MenuItemBasic {
  href?: never;
  to?: never;
}

export type MenuItem = LinkHref | LinkTo | NoLink

interface MenuAccordionProps {
  menuItems: MenuItem[]
  onClose?: () => void;
  loopTab?: boolean
  closeOnSelect?: boolean,
  style?: any,
  classes?: string
  onEmpty?: ReactNode
}

function useForceUpdate () {
  const [value, setValue] = useState(0)
  return () => setValue(value + 1)
}

export function MenuAccordion ({
  menuItems,
  style,
  classes,
  onClose,
  closeOnSelect,
  loopTab
}:MenuAccordionProps) {
  const [stateMenuItems, setMenuItems] = useState(menuItems)
  const forceUpdate = useForceUpdate()
  const ref = React.useRef<HTMLElement & {idx: number}>(null)
  const querySelectorItems = 'nav > [tabIndex]'

  const handleFocusFirstItem = () => {
    ref.current && (ref.current.idx = 0)
  }

  useEffect(() => {
    const firstItem = ref.current && ref.current.querySelector(querySelectorItems)
    if (firstItem) {
      firstItem.removeEventListener('focus', handleFocusFirstItem)
      firstItem.addEventListener('focus', handleFocusFirstItem)
    }
  }, [ref])

  const tab: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!ref.current) return
    const links = ref.current.querySelectorAll<HTMLAnchorElement>(querySelectorItems)
    ref.current.idx = ref.current.idx || 0
    let isNavigationEvent = true
    switch (e.key) {
    case 'Tab':
      ref.current.idx++
      if (!loopTab && ref.current.idx === links.length) {
        isNavigationEvent = false
      }
      break
    case 'ArrowDown':
      ref.current.idx++
      break
    case 'ArrowUp':
      ref.current.idx--
      break
    case 'Escape':
      isNavigationEvent = false
      if (onClose) {
        ref.current.idx = 0
        onClose()
      }
      return
    default:
      isNavigationEvent = false
      return
    }
    if (!isNavigationEvent) return

    e.stopPropagation()
    e.preventDefault()
    // roll
    if (ref.current.idx > links.length) {
      ref.current.idx = 0
    }
    if (ref.current.idx < 0) {
      ref.current.idx = links.length - 1
    }
    if (!links[ref.current.idx]) {
      ref.current.idx = 0
    }

    links[ref.current.idx]?.focus()
  }

  const closeMenu = () => {
    ref.current && (ref.current.idx = 0)
    onClose && onClose()
  }

  const menuItemsRender = (items: MenuItem[], level: number) => {
    return (
      <>
        { items.map((item: MenuItem, idx) => {
          return (
            <React.Fragment key={`{item-${level}-${idx}`}>
              {item.component
                ? item.component
                : item.children
                  ? (
                    <NavLink
                      id={item.id}
                      {...{ menu_tree: `item-${level}-${idx}` }}
                      style={{ paddingLeft: `calc( ${level} * 10px)` }}
                      className='has-children'
                      title={item.name}
                      aria-label={item.name}
                      tabIndex={0}
                      to={item.to || ''}
                      onClick={(e) => {
                        e.preventDefault()
                        const links = ref.current && ref.current.querySelectorAll(querySelectorItems)
                        links?.forEach((link, index) => {
                          if (link.getAttribute('menu_tree') === `item-${level}-${idx}`) {
                            ref.current && (ref.current.idx = index)
                          }
                        })

                        item.expanded = !item.expanded
                        setMenuItems(stateMenuItems)
                        forceUpdate()
                      }}
                    >
                      {item.name}
                      {item.children && (item.expanded ? <BsChevronUp size={30} /> : <BsChevronDown size={30} />)}
                    </NavLink>
                  )
                  : item.href
                    ? (
                      <a
                        id={item.id}
                        {...{ menu_tree: `item-${level}-${idx}` }}
                        style={{ paddingLeft: `calc( ${level} * 10px)` }}
                        tabIndex={0}
                        key={item.id + idx}
                        target="_blank"
                        rel="noreferrer"
                        title={item.name}
                        aria-label={item.name}
                        href={item.href}
                        {
                          ...(closeOnSelect &&
                            {
                              onClick: closeMenu
                            })
                        }
                      >{item.name}</a>
                    )
                    : item.to
                      ? (
                        <NavLink
                          id={item.id}
                          {...{ menu_tree: `item-${level}-${idx}` }}
                          style={{ paddingLeft: `calc( ${level} * 10px)` }}
                          tabIndex={0}
                          key={item.id}
                          title={item.name}
                          aria-label={item.name}
                          to={item.to || ''}
                          {
                            ...(closeOnSelect &&
                            {
                              onClick: closeMenu
                            })
                          }
                        >{item.name}</NavLink>
                      )
                      : (
                        <button
                          disabled={item.disabled}
                          id={item.id}
                          {...{ menu_tree: `item-${level}-${idx}` }}
                          style={{ paddingLeft: `calc( ${level} * 10px)` }}
                          tabIndex={0}
                        >
                          {item.name}
                        </button>
                      )
              }
              {item.children && item.expanded && menuItemsRender(item.children, level + 1)}
            </React.Fragment>
          )
        })
        }
      </>
    )
  }

  return (
    <MenuContainer
      className={classes}
      style={style}
      role="presentation"
    >
      <nav
        ref={ref}
        role="presentation"
        onKeyDown={tab}
      >
        { stateMenuItems.length === 0 ? 'onEmpty' : null}
        {
          menuItemsRender(stateMenuItems, 0)
        }
      </nav>

    </MenuContainer>
  )
}

export const MenuContainer = styled.section`

nav {
  display: flex;
  flex-flow: column;
  padding: 1rem 34px;
  

  .has-children {
    border-bottom: 1px solid #efefef;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 3.33;
  }
   > * {
    align-items: center;
    box-sizing: border-box;
    color: #222;
    display: flex;
    font-size: 1.5rem;
    font-weight: 400;
    justify-content: space-between;
    line-height: 2.5;
    text-decoration: none;

    &:hover:enabled {
      font-weight: bold;
    }

    &:disabled,
    &[disabled] {
      cursor: default;
      opacity: 80%;
    }
  }

  .active svg {
    box-shadow: none;
  }
}

`
