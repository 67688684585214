/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect } from 'react'

import styled from 'styled-components'

// import DashboardBreadCrumb from '../components/breadcrumb'
import { Card, Header, PrimaryButton } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { documents } from '../redux'
import { Helmet } from 'react-helmet'

export default function FaqFeature () {
  const dispatch = useAppDispatch()
  const data = useAppSelector(documents.selectAll) as Documents[]

  useEffect(() => {
    dispatch(documents.fetchDocs())
  }, [dispatch])

  return (
    <Container>
      <Helmet>
        <title>Dokumente</title>
      </Helmet>
      <Header as="h2">
        Dokumente
      </Header>
      <Content>
        {Array.from([...data] || []).map((doc) => (
          <Card
            key={doc.id}
            nopadding
          >
            <Header as="h3">{doc.name}</Header>
            <p dangerouslySetInnerHTML={{ __html: doc.description }} />
            <div className="center">
              <PrimaryButton
                aria-label={'Dokument herunterladen ' + doc.name}
                as="a"
                href={`${doc.download_url}`}
              >Dokument herunterladen</PrimaryButton>
            </div>
          </Card>
        ))}
      </Content>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;
`
const Content = styled.div`
border: 0;
display: grid;
grid-column-gap: 2rem;
grid-row-gap: 1rem;
grid-template-columns: repeat(auto-fit, minmax(300px, 473px));
justify-content: center;

@media screen and (max-width: 600px) {
  justify-content: center;
}

> div {
  flex: 1;
}

> div {
  font-size: 18px;
  margin: 0;
  max-width: 473px;
  min-width: 300px;
  padding-bottom: 80px;
  position: relative;

  h3 {
    border-bottom: 1px solid #ccc;
    font-weight: 600;
    padding: 24px;
    text-transform: unset;
    margin: 0;
  }

  p {
    padding: 12px 24px 0;
    white-space: pre-line;
    width: 100%;
    word-break: break-all;
  }

  .center {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  a {
    line-height: 38px;
    margin: 24px 0;
    padding-left: 40px;
    padding-right: 40px;
    text-decoration: none;
  }
}

`
