import { Card, PrimaryButton, Header } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

import styled from 'styled-components'
import { faq } from '../redux'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const FaqList: React.FC = () => {
  const ref = React.useRef<HTMLElement& {idx?:number}>(null)
  const ids = useAppSelector(faq.selectFiltered)
  const products = useAppSelector(faq.selectFaqs)
  const dispatch = useAppDispatch()
  const searchText = useAppSelector(faq.selectFilters).searchText || ''
  const [visible, toggle] = useState('-1')
  const show = id => toggle(xid => `${xid}` === `${id}` ? `-${id}` : id)

  return (
    <Container
      ref={ref}
    >
      {ids.length === 0 && searchText.length > 3
        ? (
          <h5>
            Für das Schlüsselwort "{searchText}" wurde kein Ergebnis gefunden.
          </h5>
        )
        : null}
      {Array.from(ids).map(id => (
        <FaqCard
          key={id}
          nopadding
          aria-expanded={`${visible}` === `${id}`}
          visible={`${visible}` === `${id}`}
        >
          <div
            className={'faq-header'}
            aria-expanded={`${visible}` === `${id}`}
            aria-label={visible === id ? 'Details ausblenden' : 'zeige Details'}
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                show(id)
              } else if (e.key === 'Escape') {
                // @ts-ignore
                show(null)
              } else if (e.key === 'Tab' && `${visible}` === `${id}`) {
                e.stopPropagation()
                e.preventDefault()
                // @ts-ignore
                document.activeElement?.blur()
              }
            }}
            onClick={() => {
              show(id)
            }}
          >
            <Header as='h4'>{products[id].question}</Header>
            <span>{products[id].faq_category.name}</span>
            {visible === id ? <FiChevronUp /> : <FiChevronDown />}
          </div>
          {`${visible}` === `${id}`
            ? (
              <div
                role="region"
                aria-label="answer"
              >
                <p
                  dangerouslySetInnerHTML={{ __html: products[id].answer }}
                />
                <form
                  className="footer"
                  onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation() // prevent focus lose
                    dispatch(faq.helpful(id))
                    console.log('helpull')
                  }}
                >
                  <span>{products[id].helpful_count ? products[id].helpful_count + ' Person fand dies hilfreich' : ''}</span>
                  <PrimaryButton
                    aria-label={'Diese Info war hilfreich'}
                    tabIndex={0}
                    type="submit"
                  >Diese Info war hilfreich</PrimaryButton>
                </form>
              </div>
            )
            : null}
        </FaqCard>
      ))}
    </Container>
  )
}

const Container = styled.section`
h5 {
  color: #8c8c8c;
  font-size: 18px;
}

div.faq-header {
  display: flex;
  justify-content: flex-end;

  div {
    display: flex;
  }
}
`
const FaqCard = styled(Card)<{visible: boolean}>`
color: #262626;
font-size: 18px;
line-height: 21px;
padding: 0;

a,
em {
  color: #00739f;
}

[role='region'] {
  display: ${({ visible }) => visible ? 'block' : 'none'};
}

p {
  display: 'block';
  padding: 0 24px;
  white-space: pre-wrap;
}

div.faq-header {
  align-items: center;
  border-bottom: 1px solid var(--gray-300, #3c3c3c);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  padding: 24px;

  h4 {
    flex: 1;
    line-height: 32px;
    margin: 0;
    text-transform: unset;
    font-size: 1.5em;
    font-weight: bold;
  }

  span {
    align-self: baseline;
    background-color: #4c4c4c;
    border-radius: 12px;
    color: #fff;
    line-height: 16px;
    margin: auto 8px;
    padding: 8px 12px;
  }

  svg {
    color: var(--gray-300, #3c3c3c);
    font-size: 2em;
  }
}

.footer {
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin: 24px;

  span {
    color: #262626;
    font-size: 18px;
    font-weight: 900;
    line-height: 36px;
  }

  button {
    margin-left: 24px;
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  div.faq-header {
    flex-flow: row wrap;
    justify-content: center !important;

    h2 {
      flex-basis: 100%;
    }
  }
}
`
