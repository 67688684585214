/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

export const fetchFaq = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<Faq[]>('/faqs').then(({ data }) => {
    dispatch(actions.set(data))
  })

export const helpful = (id): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.put(`/faqs/${id}/helpful`).then(({ data }) => {
    dispatch(actions.incrementHelpful({
      id,
      count: data.helpful_count,
      marked: data.marked
    }))
  })
