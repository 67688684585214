import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormInput } from '../screens/formula/Form'

export const ID_PARAM = 'id'

interface I{
  carts: Record<string, CartItem[]>,
  formulas: Record<string, Record<string, { attributes: FormInput[], formular_name?: string }>>
}

export const initialState = {
  carts: {},
  formulas: {}
} as I

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (state, { payload: { regionId, data } }:PayloadAction<{data: CartItem[], regionId: string}>) => {
      state.carts[regionId] = [...data]
    },
    clear: (state, { payload: { regionId, type } }: PayloadAction<{type: string, regionId: any}>) => {
      if (!type) {
        state.carts[regionId] = []
      } else {
        state.carts[regionId] = state.carts[regionId]?.filter(item => `${item.line_item_type}` !== type) || []
      }
    },
    addFormulas: (state, { payload }: PayloadAction<I['formulas']>) => {
      state.formulas = {
        ...state.formulas,
        ...payload
      }
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
