import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const ID_PARAM = 'id'

export interface AuthState{
  user: User | null;
  refresh: string;
  auth: string;
  lastAttempt: number;
  target_groups: TargetGroup[] | null;
}

export const initialState:AuthState = {
  user: null,
  refresh: '',
  auth: '',
  lastAttempt: 0,
  target_groups: null
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<User|null>) => {
      state.user = action.payload
    },
    update: (state, action: PayloadAction<Partial<User>>) => {
      const id = typeof action.payload.id === 'string' ? action.payload.id : state.user?.id
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
          id: String(id)
        }
      } else {
        console.error('cannot update user info,  since there were no intial values')
      }
    },
    setTargetGroups: (state, action: PayloadAction<TargetGroup[]|null>) => {
      state.target_groups = action.payload
    },

    setCurrentTargetGroup: (state, action: PayloadAction<number>) => {
      console.assert(!!state.target_groups?.some((targetGroup) => targetGroup.id === action.payload), 'cannot select a TargetGroup we dont have !')
      for (const targetGroup in state.target_groups) {
        if (state.target_groups[targetGroup].id === action.payload) {
          state.target_groups[targetGroup].selected = true
        } else {
          state.target_groups[targetGroup].selected = false
        }
      }
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
