import styled from 'styled-components'

export const Table = styled.table`
border: 0;
border-collapse: collapse;
margin: 0;
width: 100%;

thead {
  background: #ededed;
}

th {
  border: 0;
  font-weight: 900;
  padding: 12px;
  text-align: left;
}

tbody tr {
  border-bottom: 1px solid #ccc;

  &:last-of-type {
    border: 0;
  }

  th,
  td {
    padding: 12px;
  }
}

`
