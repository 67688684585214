import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitialState {
  byRegion: Record<string, string[]>
  byId: Record<string, Representative>
  users: {id: number; email: string}[],
  assignedToMe: string[]
}

export const initialState: InitialState = {
  byRegion: {},
  assignedToMe: [],
  byId: {},
  users: []
}

const slice = createSlice({
  name: 'representative',
  initialState,
  reducers: {
    add: (state, { payload }: PayloadAction<{data: Representative[], myId: string, reset?:boolean}>) => {
      const cache = {} as Record<string, Set<string>>
      if (payload.reset) {
        state.byRegion = {}
        state.assignedToMe = []
        state.byId = {}
        state.users = []
      }
      payload.data.forEach(row => {
        state.byId[row.id] = row
        if (!cache[row.region.region_key]) {
          cache[row.region.region_key] = new Set(state.byRegion[row.region.region_key] || [])
        }
        cache[row.region.region_key].add(`${row.id}`)

        if (!state.assignedToMe) {
          state.assignedToMe = []
        }
        if (row.substitute.id === payload.myId && state.assignedToMe.indexOf(`${row.id}`) === -1) {
          state.assignedToMe.push(`${row.id}`)
        }
      })
      for (const key in cache) {
        if (Object.prototype.hasOwnProperty.call(cache, key)) {
          const element = cache[key]
          state.byRegion[key] = Array.from(element)
        }
      }
    },
    remove: (state, { payload }: PayloadAction<string>) => {
      delete state.byId[payload]
    },
    update: (state, { payload }: PayloadAction<Representative>) => {
      state.byId[payload.id] = payload
    },
    setUsers: (state, { payload }: PayloadAction<{id: number; email: string}[]>) => {
      state.users = payload
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
