import React from 'react'

import { FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'

export function DashboardBreadCrumb () {
  return (
    <Nav>
      <a href="/">Startseite</a>
      <FaChevronRight />
      <a href="/dashboard">Dashboard</a>
    </Nav>
  )
}

const Nav = styled.nav`
  color: #262626;
  display: flex;
  flex-flow: row nowrap;
  font-weight: 100;
  line-height: 16px;
  margin-bottom: 2.5rem;
  padding: 0;

  > * {
    color: #262626;
    font-size: 16px;
    margin-right: 1em;
    text-decoration: none;
  }

  >svg {
    font-size: 12px;
    line-height: 16px;
  }
`
