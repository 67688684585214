/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect } from 'react'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

import styled from 'styled-components'

// import DashboardBreadCrumb from '../components/breadcrumb'
import { Header, PrimaryButton } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { batch } from 'react-redux'

import { Link, useNavigate } from 'react-router-dom'
import { region } from 'Features/region/redux'
import { representative } from '../redux'
import { PostParam } from '../redux/actions'
import { Helmet } from 'react-helmet'

export default function Feature () {
  const dispatch = useAppDispatch()
  const users = useAppSelector(representative.users)
  const myRegions = useAppSelector(region.myRegions)
  const navigate = useNavigate()

  useEffect(() => {
    batch(() => {
      dispatch(representative.getUsers())
    })
  }, [dispatch])

  const create = (e) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    // eslint-disable-next-line compat/compat
    const data = Object.fromEntries(new FormData(target).entries()) as unknown as PostParam

    if (data.substitute_user_id && data.region_id) {
      data.start_date = new Date(data.start_date || null)
      data.end_date = new Date(data.end_date || null)
      dispatch(representative.create(data)).then(() => {
        navigate('/representative', { replace: true })
      })
    } else {
      alert('missing data... ! How did you reach here anyway ! *hacker :D')
    }
  }

  return (
    <Container>

      <Helmet>
        <title>{'Vertretung erstellen'}</title>
      </Helmet>
      <Header as="h2">
        Vertretung erstellen
      </Header>
      <Content onSubmit={create}>
        <div>
          <div>
            <span>Vertretung</span>
            <select
              required
              name="substitute_user_id"
            >
              {users.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >{user.email}</option>
              ))}
            </select>
          </div>

          <div>
            <span>Ausbaugebiet</span>
            <select
              required
              name="region_id"
            >
              {myRegions.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >{user.name}</option>
              ))}
            </select>
          </div>

          <div>
            <span>Vertretungszeitraum</span>
            <Space>
              <input
                required
                placeholder="von"
                type="date"
                name='start_date'
              />
              <input
                required
                placeholder="bis"
                type="date"
                name='end_date'
              />
            </Space>
          </div>
        </div>

        <Space style={{ marginTop: '2em' }}>
          <PrimaryButton
            aria-label={'Abbrechen'}
            bg="#4C4C4C"
            color="#FFF"
            as={Link}
            to="/representative"
          >Abbrechen</PrimaryButton>
          <PrimaryButton
            aria-label={'Vertretung anlegen'}
            type="submit"
          >Vertretung anlegen</PrimaryButton>
        </Space>
      </Content>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
font-size: 16px;
margin: 2rem;

span {
  margin: 1em 0;
}

`
const Space = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoint.size.xs}) {
    flex-flow: column !important;
    gap: 1em;

    a,
    button {
      text-align: center;
      width: 200px;
    }
  }
`
const Content = styled.form`
border: 0;

> div {
  display: flex;
  flex-flow: row wrap;
}

> div > div {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

select {
  background-color: #ededed;
  border: 1px solid #8c8c8c;
  color: #262626;
  padding: 0 12px;
}

input,
select {
  font-size: 18px;
  margin-right: 1em;
  min-height: 40px;
  min-width: 200px;
}

@media screen and (max-width: 600px) {
  justify-content: center;
}

`
