import React from 'react'

import styled from 'styled-components'

import { useLocale } from 'Config'

export function LocalSwitch () {
  const [state, change] = useLocale()

  return (
    <Row>
      <A
        data-testid="de"
        className={`${state === 'de' ? 'active' : ''}`}
        onClick={() => state === 'en' && change('de')}
      >DE</A>
      <A
        data-testid="en"
        className={`${state !== 'de' ? 'active' : ''}`}
        onClick={() => state !== 'en' && change('en')}
      >EN</A>
    </Row>
  )
}

const Row = styled.div`align-items: center;
  display: flex;
  flex-flow: row nowrap;
`
const A = styled.button`
  background-color: transparent;
  border: 0;
  color: var(--dark);
  cursor: pointer;
  display: inline-block;
  min-width: 32px;
  outline: none;

  .active {
    color: var(--primary, #d20000);
  }
`
