/* eslint-disable sonarjs/cognitive-complexity */
import React, { KeyboardEventHandler, useCallback, useRef, useEffect } from 'react'

import styled from 'styled-components'

export function InlineSearchInput ({ visible, numberOItems, children, onClick, ...props }: any) {
  const [showNav, setShowNav] = React.useState(false)
  const ref = useRef<HTMLElement & {idx: number}>(null)
  const refInput = useRef<HTMLElement>(null)
  const refButton = useRef<HTMLButtonElement>(null)
  const wrapperRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowNav(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const tab: KeyboardEventHandler<HTMLElement> = useCallback((e) => {
    if (!ref.current) return
    const isInputTarget = e.target === refInput.current
    const isButtonTarget = e.target === refButton.current
    if (isInputTarget) {
      setShowNav(true)
      ref.current.idx = -1
    } else {
      ref.current.idx = (ref.current.idx || 0)
    }

    const links = ref.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]')
    if (links.length === 0) return
    switch (e.key) {
    case 'Tab':
      if (e.shiftKey) {
        if (isInputTarget) {
          setShowNav(false)
          return
        } else if (ref.current.idx - 1 < 0) {
          setShowNav(false)
          refInput.current?.focus()
          e.preventDefault()
          e.stopPropagation()
          return
        }
        ref.current.idx = (ref.current.idx || 0) - 1
      } else {
        ref.current.idx = (ref.current.idx || 0) + 1
        if (numberOItems === ref.current.idx && refButton.current) {
          refButton.current.focus()
          e.preventDefault()
          e.stopPropagation()
          return
        } else if (isButtonTarget) {
          setShowNav(false)
          return
        }
      }
      e.stopPropagation()
      break
    case 'ArrowUp':
      ref.current.idx--
      e.stopPropagation()
      break
    case 'ArrowDown':
      ref.current.idx++
      e.stopPropagation()
      break
    case 'Enter':
      if (!isInputTarget && !isButtonTarget) {
        (ref.current.querySelector(`div > [tabIndex="${ref.current.idx}"]`) as HTMLAnchorElement)?.click()
        return
      }
      return onClick(e)
    case 'Escape':
      if (isInputTarget && refButton.current) {
        refButton.current.focus()
        return
      } else if (isButtonTarget) {
        return
      }
      refInput.current && refInput?.current.focus()
      return
    default:
      return
    }
    e.preventDefault()

    if (ref.current.idx === -1) {
      ref.current.idx = links.length - 1
    }
    if (!links[ref.current.idx]) {
      ref.current.idx = 0
    }
    const link = links[ref.current.idx]
    link?.focus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick])
  return (
    <Container
      ref={wrapperRef}
      role="presentation"
      onClick={(() => null)}
      onKeyDown={tab}
    >
      <SearchInput
        ref={refInput}
        aria-label="Produkt Suche"
        title={'Produkt Suche'}
        {...props}
        onFocus={ () => setShowNav(true)}
      />
      {visible && showNav && (
        <nav ref={ref}>
          {children}
        </nav>
      )}
      <button
        ref={refButton}
        aria-label="Produkt Suchen"
        title={'Produkt Suchen'}
        onClick={onClick}
      >
        Suchen
      </button>

    </Container>
  )
}

const SearchInput = styled.input`
  z-index: 3;
`

const Container = styled.div`
display: flex;
position: relative;

nav > div {
  max-height: 30vh;
  overflow-y: auto;
}

nav::before {
  background-color: #fff;
  border-top: 1px solid;
  border-left: 1px solid;
  content: ' ';
  height: 20px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  transform: rotate(45deg);
  width: 20px;
  z-index: -1;
}

nav::after {
  background-color: transparent;
  content: ' ';
  display: block;
  height: 3em;
  left: 0;
  position: absolute;
  top: -2.5em;
  width: 50%;
}

nav > *{
  &:first-of-type {
    padding-top: 1em;
  }
  &:last-of-type {
    padding-bottom: 1em;
  }
}

nav {
  background-color: #fff;
  border: 1px solid var(--gray-500, #7c7c7c);
  border-radius: 4px;
  box-shadow: 0 0 12px var(--gray-500, #7c7c7c);
  font-size: 18px;
  left: 0;
  line-height: inherit;
  min-width: 272px;
  position: absolute;
  text-align: left;
  top: 56px;
  z-index: 2;

  h5 {
    border-bottom: 1px solid var(--gray-500, #7c7c7c);
    color: #8c8c8c;
    font-size: 18px;
    font-weight: normal;
    line-height: inherit;
    margin: 0 0 0.5em;
    padding: 0 0.5em;
    position: relative;
    text-transform: capitalize;
  }

  a {
    color: #222;
    display: block;
    line-height: inherit;
    padding: 0 0.5em;
    text-decoration: none;

    span {
      color: var(--primary);
      display: inline-block;
    }
  }

  a:hover {
    font-weight: bold;
  }
}

> input {
  border: 1px solid var(--grey-500, #8c8c8c);
  border-radius: 4px;
  flex: 1;
  margin-right: 0.5rem;
  padding: 0.5em 1em;
}

> button {
  background-color: var(--primary);
  border: 1px solid var(--primary,#e20074);
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  height: 36px;
  margin: 0 -1em 0 1em;
  outline: none;
  width: 89px;

  > svg {
    position: relative;
    top: 2px;
  }
}
`
