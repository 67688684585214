import React from 'react'
import styled from 'styled-components'

import { Modal } from 'Components'
import ImageSlider from 'Features/product/components/Image'
import { clickTrapStopPropagation } from 'Helpers'

export default function ImageModal ({ src = [], title, onCloseModal }: { src: LineItemPicture[], title?: string, onCloseModal: any }) {
  return (
    <Modal
      title={title}
      onRequestClose={() => onCloseModal()}
    >
      <Details
        role="presentation"
        onKeyPress={(e) => e.stopPropagation()}
        onClick={clickTrapStopPropagation}
      >
        <ImageSlider
          className="zoom"
          src={src}
          name={title}
        />
      </Details>
    </Modal>
  )
}

const Details = styled.section`
border: 0 !important;
display: flex;
font-size: 1.5rem;
line-height: 44px;
margin-top: 0;
position: relative;

button {
  font-size: 1em;
}

figure {
  border: 0;
  height: 100%;
  margin: 0;
  min-height: 200px;
  min-width: 200px;
  padding: 0.25em;

  img {
    background-color: #ccc;
    display: block;
    margin: 0;
    max-height: 100%;
    width: 100%;
  }
}
`
