import Feature from './screens/ListPage'

const routes = [
  {
    path: '/static/:slug',
    Component: Feature
  }
]

export default routes
