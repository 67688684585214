/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

export const fetchUnreadCount = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<{unread_count: number}>('/notifications/unread_count').then(({ data }) => {
    dispatch(actions.setUnreadCount(data?.unread_count))
  })

interface ChatFetchParams{
  limit?: number;
  page?: number;
  region_id?: string;
  order_id?: string;
}

export const fetchThreads = (params = {} as ChatFetchParams): AppAsyncThunk => (dispatch, _getState, { api }) => {
  return api.get<{total_count: number, page_size: number, notifications: ChatListItem[]}>('/notifications', { params }).then(({ data }) => {
    dispatch(
      actions.set({
        ...data,
        offset: ((params?.page || 1) - 1) * data.page_size
      })
    )
  })
}

export const reply = (id, text): AppAsyncThunk => (dispatch, _getState, { api }) => {
  return api.put<ChatListFragment>(`/notifications/${id}/reply`, { text }).then(({ data }) => {
    dispatch(actions.addFragment({
      id,
      data
    }))
  })
}

export const read = (id): AppAsyncThunk<ChatListItem> => (dispatch, _getState, { api }) => {
  return api.get<ChatListItem>(`/notifications/${id}`).then(({ data }) => {
    dispatch(actions.update(data))
    return data
  })
}

export const createThread = (order_id: string, text: string, target_group_id: number): AppAsyncThunk<any> => (dispatch, _getState, { api }) => {
  return api.post<ChatListItem>('/notifications', {
    text,
    order_id,
    target_group_id
  }).then(({ data }) => {
    dispatch(actions.update(data))
    return data
  }).catch((e) => {
    console.error(e)
  })
}

export const markAllAsRead = () : AppAsyncThunk<any> => (dispatch, _getState, { api }) =>
  api.put('/notifications/mark_all_as_read')
    .then(() => dispatch(actions.markThreadsAllAsRead()))
