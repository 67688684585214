import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { clickTrapStopPropagation } from 'Helpers'
import { GrClose } from 'react-icons/gr'
import styled from 'styled-components'
import { Layer } from 'react-layers-manager'
import FocusTrap from 'focus-trap-react'
import { Helmet } from 'react-helmet'
import { useToasts } from 'react-toast-notifications'

import { Card } from '../Card'
import { Backdrop } from './Backdrop'
import { useT } from './translations'

export interface ModalProps{
  children?: ReactNode;
  /**
   * if given will set title on card
   */
  id?: string;
  title?: string;
  /**
   * Card style
   */
  boxStyle?: Record<string, any>;
  /**
   * backdrop style
   */
  backdropStyle?: Record<string, any>;
  /**
   * a callback to call in case user want to hide modal
   */
  onRequestClose: ()=>any
}
const stack = [] as Array<(x:boolean)=>unknown>

function onStackUpdate () {
  const lastIndex = stack.length - 1
  stack.forEach((instance, index) => {
    instance(index === lastIndex)
  })
}
export const Modal: FC<ModalProps> = ({ id, children, onRequestClose, title, backdropStyle, boxStyle = {}, ...props }) => {
  const toastManager = useToasts()
  const t = useT()
  const [slide, toggle] = useState(false)
  const [onTop, setonTop] = useState(false)
  const ref = React.useRef<HTMLButtonElement>()
  const close = useCallback(() => {
    toggle(false)
    setTimeout(() => {
      const r = onRequestClose()
      if (r === false) {
        console.log('prevent sliding up... reopening modal')
        toggle(true)
      }
    }, 150)
  }, [onRequestClose])

  const onMount = useCallback(appRoot => {
    toastManager.removeAllToasts()
    if (stack.length === 0) {
      appRoot.setAttribute('aria-hidden', 'true')
    }
    // Add a reference to the component instance
    // to the stack.
    stack.push(setonTop)
    toggle(true)
    onStackUpdate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = useCallback(appRoot => {
    stack.pop()
    if (stack.length === 0) {
      appRoot.removeAttribute('aria-hidden')
    }
    onStackUpdate()
  }, [])

  const closeOnEscape = useCallback((e) => {
    if (e.key === 'Escape') {
      console.log('close on escape')
      close()
      e.stopPropagation()
    }
    if (e.key === 'Enter' || e.key === ' ') {
      console.log('stoping enter or space propagation')
      e.stopPropagation()
    }
  }, [close])
  useEffect(() => {
    ref.current?.focus()
  }, [])
  return (
    <Layer
      onMount={onMount}
      onUnmount={onUnmount}
    >
      <Backdrop
        data-testid="Modal"
        {...props}
        aria-modal="true"
        role={'dialog'}
        aria-hidden={!onTop}
        aria-label={`${title} dialog`}
        style={backdropStyle}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>
        <FocusTrap active={slide}>
          <div>
            <Back
              onClick={close}
              tabIndex={-1}
              aria-label={`${t.close} ${title}`}
            />
            <AnimatedCard
              id={id}
              onKeyDown={closeOnEscape}
              style={boxStyle}
              visible={slide}
              onClick={clickTrapStopPropagation}
              title={title as string}
            >
              <div>
                <CloseButton
                  onClick={close}
                  aria-label={`${t.close} ${title}`}
                  title={`${t.close} ${title}`}
                  ref={ref as any}
                >
                  <GrClose />
                </CloseButton>
                {children}
              </div>
            </AnimatedCard>
          </div>
        </FocusTrap>
      </Backdrop>
    </Layer>
  )
}
const Back = styled.button`
background-color: transparent;
height: 100%;
position: absolute;
right: 0;
top: 0;
width: 100%;
z-index: 99;
`
const AnimatedCard = styled(Card)<{visible: boolean}>`
  max-height: 90vh;
  max-width: 100vw;
  opacity: ${({ visible }) => visible ? '1' : '0'};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2rem 2rem inherit inherit;
  position: relative;
  transform: ${({ visible }) => visible ? 'translateY(0px)' : 'translateY(-20vh)'};
  transition: all 0.2s ease;
  z-index: 100;

  > header {
    padding-right: 2em;
  }

  > h2 {
    padding-right: 2em;
  }
`
const CloseButton = styled.button`
background: none;
border: 0;
color: var(--gray-400, #ccc);
font-size: 20px;
font-weight: bold;
height: 36px;
position: absolute;
right: 10px;
top: 14px;
width: 36px;
`
