import { Header, PrimaryButton, ResetButton } from 'Components'
import { useT } from 'Features/cart/translations'
import React, { ChangeEventHandler, useRef, useState, useEffect } from 'react'
import { FiFilter } from 'react-icons/fi'
import styled from 'styled-components'

const noob = {}
const status = [
  ['pending', 'Offen'],
  ['accepted', 'Bearbeitet'],
  ['partially', 'Teilweise'],
  ['declined', 'Abgelehnt']
]

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SupplierFilters = ({ onChange, targetGroup }) => {
  const changed = useRef(noob)
  const synced = useRef(false)
  const [data, setData] = useState<any>(noob)
  const t = useT()
  const types = Object.entries(t.cartTypes)

  useEffect(() => {
    synced.current = false
    changed.current = {}
    setData({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetGroup])

  const changeData: ChangeEventHandler<HTMLInputElement|HTMLSelectElement> = (e) => {
    const { name, value } = e.target
    synced.current = false
    if (data[name] !== value) {
      setData({
        ...data,
        [name]: value
      })
    }
  }

  const changeDataPrice: ChangeEventHandler<HTMLInputElement|HTMLSelectElement> = (e) => {
    const name = e.target.name
    let value = e.target.value.replace(',', '') // just in case user pasted value with comma
    synced.current = false
    // only accept numbers for max, min
    if (['max', 'min'].indexOf(name) > -1 && isNaN(Number(value))) {
      value = '0'
    }

    if (data[name] !== value) {
      setData({
        ...data,
        [name]: value
      })
    }
  }
  const hasChanged = (() => {
    if (synced.current === true) return null
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key]
        if (!changed.current[key] || element !== changed.current) {
          return true
        }
      }
    }
    return false
  })()
  const submit = () => {
    synced.current = true
    changed.current = { ...data }
    onChange(data)
  }
  const onRESET = () => {
    synced.current = false
    changed.current = {}
    setData({})
    onChange(null)
  }

  return (
    <>
      <Header as="h2"><FiFilter />{' Filter'}</Header>

      <Row>
        <input
          value={data.id || ''}
          name='id'
          type="text"
          onChange={changeData}
          aria-label="Bestellnummer"
          title="Bestellnummer"
          placeholder="Bestellnummer"
        />
        <input
          value={data.artno || ''}
          aria-label="Artikel-Nummer"
          title="Artikel-Nummer"
          name='artno'
          type="text"
          onChange={changeData}
          placeholder="Artikel-Nummer"
        />
        <select
          name="type"

          title="Bestellungsart"
          aria-label="Bestellungsart"
          value={data.type || ''}
          onChange={changeData}
        >
          <option value="">{'Bestellungsart'}</option>
          {types.map(([val, label]) => (
            <option
              value={val}
              key={val}
              aria-label={label}
            >{label}</option>
          ))}
        </select>
        <select
          name="status"
          title="Versandstatus"
          aria-label="Versandstatus"
          value={data.status || ''}
          onChange={changeData}
        >
          <option value="">{'Versandstatus'}</option>
          {status.map(([val, label]) => (
            <option
              key={val}
              value={val}
              aria-label={label}
            >{label}</option>
          ))}
        </select>
        <input
          value={data.min || ''}
          name='min'
          onChange={changeDataPrice}
          aria-label="Preis ab in Euro"
          title="Preis ab in Euro"
          placeholder="Von (€)"
        />
        <input
          value={data.max || ''}
          name='max'
          onChange={changeDataPrice}
          aria-label="Preis bis in Euro"
          title="Preis bis in Euro"
          placeholder="Bis (€)"
        />
        <div style={{ flex: 1 }} />
        {hasChanged === null
          ? (
            <ResetButton
              aria-label={'Filter zurücksetzen'}
              onClick={onRESET}
            >X Zurücksetzen</ResetButton>
          )
          : (
            <PrimaryButton
              disabled={!hasChanged}
              onClick={submit}
              title="Filtern"
              aria-label="Filtern"
            > Filtern </PrimaryButton>
          )}
      </Row>
    </>
  )
}

const Row = styled.div`
display: flex;
margin-bottom: 59px;

> input,
> select {
  width: 150px;
}

> [type='number'] {
  min-height: 36px;
  width: 100px;
}

>select {
  background: #ededed;
  min-width: 10em;
}

> * {
  border-radius: 3px;
  margin-right: 1.5em;
  min-width: 7em;
  min-height: 40px;
}

> button {
  margin-right: 0;
}

>input {
  background: #fff;
  border: 1px solid var(--gray-500, #7c7c7c);
}
`
