import { MenuWithArrow, UserIcon } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import { chat } from 'Features/chat/redux'

import React from 'react'
import styled from 'styled-components'

export function SupplierWidget ({ showChat }) {
  const dispatch = useAppDispatch()
  const user = useAppSelector(auth.selectUser)
  const badgeCount = useAppSelector(chat.selectCount)

  const logout = (includeSSo = false) => e => {
    e.preventDefault()
    dispatch(auth.attemptLogout(includeSSo))
  }

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end'
    }}
    >

      <MenuWithArrow
        links={[
          {
            label: 'Profil',
            props: { to: '/profile' }
          },
          {
            label: 'Bestellungen',
            props: { to: '/supplier' }
          },
          {
            label: `Nachrichten (${badgeCount})`,
            props: {
              to: '/chat',
              onClick: showChat
            }
          },
          {
            label: 'Vom SSO abmelden',
            props: {
              to: '/logout/full',
              onClick: logout(true)
            }
          }
        ]}
        arrowOffset={14}
        title={`${user?.first_name} ${user?.last_name}`}
        label={'Nutzerprofil'}
        aria-haspopup
      >
        <UserIcon
          aria-haspopup
          style={{ fontSize: 26 }}
        />
        {badgeCount && badgeCount > 0 ? <Badge>{badgeCount}</Badge> : null}
      </MenuWithArrow>
    </div>
  )
}

const Badge = styled.span`
  background-color: var(--primary, rgba(206, 17, 38, 0.7));
  border-radius: 10px;
  bottom: 4%;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  left: 50%;
  line-height: 1rem;
  padding: 0.185rem 0.5rem 0.125rem 0.5rem;
  position: absolute;
  top: 51%;
`
