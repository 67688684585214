import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function TrackPage () {
  const location = useLocation()
  const { trackPageView } = useMatomo()

  React.useEffect(() => {
    trackPageView({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return null
}
