import React, { FC, HTMLAttributes, MouseEventHandler, ReactNode } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  title?: string
  icon?: ReactNode;
  nopadding?: boolean;
  action?:string;
  onClick?: MouseEventHandler
}

export const Card: FC<CardProps> = ({ children, nopadding, icon, title, action, onClick, ...props }) => {
  return (
    <CardComponent
      nopadding={nopadding}
      data-testid="card"
      {...props}
    >
      {title || icon
        ? (
          <Header>{icon ? <span>{icon}</span> : null} {title} {action
            ? (
              <ActionLink
                onClick={onClick}
                aria-label={action}
                title={action}
              >{action}</ActionLink>
            )
            : null}</Header>
        )
        : null}
      {children || 'Welcome to @smart Project: <Card /> file'}
    </CardComponent>
  )
}

Card.propTypes = {
  children: t.node
}

Card.defaultProps = {
  children: null
}

const CardComponent = styled.div<{nopadding?: boolean}>`
background: #fff 0% 0% no-repeat padding-box;
border: 1px solid #dfdfdf;
box-shadow: 0 3px 6px #0000001a;
margin-bottom: 24px;
padding: ${({ nopadding }) => nopadding ? 0 : '24px'};

`
const Header = styled.h2`
align-items: center;
color: #262626;
display: block;
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 18px;
margin-bottom: 24px;
margin-top: 0px;
text-align: left;
text-transform: uppercase;

span {
  display: inline-block;
  margin-right: 12px;
}
`
const ActionLink = styled.button`
background: transparent;
border: 0;
color: var(--primary, #e20074);
float: right;
font-size: 0.8em;
outline: none;
text-decoration: underline;

&:hover {
  font-weight: bold;
}
`
