import React from 'react'

import styled, { keyframes } from 'styled-components'

const alignments = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end'
}

export const Spinner = ({ size = 100, align, top, color }) => (
  <div
    style={{
      display: 'flex',
      flexGrow: 1
    }}
  >
    <div
      style={{
        justifyContent: alignments[align],
        display: 'flex',
        flexGrow: 1
      }}
    >
      <SpinnerDiv
        className="spinner"
        style={{
          width: size,
          marginTop: top
        }}
      >
        <svg
          viewBox="25 25 50 50"
        >
          <circle
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
            stroke={color}
          />
        </svg>
      </SpinnerDiv>
    </div>
  </div>
)

Spinner.defaultProps = {
  size: 60,
  top: 0,
  align: 'center',
  color: 'currentColor'
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`
const SpinnerDiv = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100px;

  ::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  > svg {
    animation: ${rotate} 2s linear infinite;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    stroke: currentColor;
    top: 0;
    transform-origin: center center;
    width: 100%;
  }

  > svg > circle {
    animation: ${dash} 1s ease-in-out infinite;
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }
`
