/* eslint-disable sonarjs/cognitive-complexity */
import { IconButton, PrimaryButton, Table, Pagintaion, Spinner } from 'Components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { useAppDispatch, useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import { chat } from 'Features/chat/redux'
import { orders } from 'Features/orders'
import { FormatDate, FormatMoney } from 'Helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { FaInfo } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { SupplierFilters } from './components/SupplierFilter'
import { SupplierTargetGroups } from './components/SupplierTargetGroups'

export function SupplierView () {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()
  const selectedTargetGroup = useAppSelector(auth.selectCurrentTargetGroup)
  const data = useAppSelector(orders.selectSortedOrders)
  const total = useAppSelector(orders.selectTotalOrders)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const pageSize = 15
  const [filtersFileds, setFiltersFileds] = useState({})
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const filterdData = (filters: any = null) => {
    const newFilter = {
      ...(filters?.min && { min: filters?.min }),
      ...(filters?.max && { max: filters?.max }),
      ...(filters?.type && { order_type: filters?.type }),
      ...(filters?.status && { supplier_state: filters?.status }),
      ...(filters?.id && { order_id: filters?.id })
    }
    setFiltersFileds(newFilter)
    setPage(0)
  }

  const send = useCallback((id) => {
    const text = prompt('Bitte geben Sie hier Ihre Nachricht an den Besteller ein')
    if (!text || text.length < 1 || !selectedTargetGroup) return
    dispatch(chat.createThread(id, text, selectedTargetGroup.id)).then((r) => {
      r.message || addToast(<div role="alert">Erfolgreich gespeichert!</div>, { appearance: 'success' })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, dispatch])

  useEffect(() => {
    if (selectedTargetGroup) {
      setLoading(true)
      dispatch(orders.fetchSupplierOrders(
        {
          ...filtersFileds,
          limit: pageSize,
          page: page + 1,
          target_group_id: selectedTargetGroup?.id
        }
      ))
        .finally(() => setLoading(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersFileds, page, selectedTargetGroup])

  const download = () => {
    dispatch(
      (_, __, { api }) => api.request({
        url: '/supplier/orders/export',
        method: 'GET',
        responseType: 'blob',
        params: {
          target_group_id: selectedTargetGroup?.id
        }
      })
        .then(({ data }) => {
          // eslint-disable-next-line compat/compat
          const downloadUrl = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `kambox-orders-${Math.floor(Date.now() / 10000)}.xlsx`) // any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    )
  }

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  const handleChange = (event) => {
    if (event.target.files[0]) { upload(event.target.files[0]) }
  }

  const upload = (fileUploaded: File) => {
    const headers = {}
    headers['Content-Type'] = 'multipart/form-data'
    const data = new FormData()
    data.append('excel[file]', fileUploaded)
    dispatch(
      (_, __, { api }) => api.request({
        url: '/supplier/orders/import',
        method: 'PUT',
        headers,
        params: {
          target_group_id: selectedTargetGroup?.id
        },
        data
      }).then(() => {
        addToast(<div role="alert">Der Bericht wurde geladen. Überprüfen Sie Ihre E-Mail, um den Status des Vorgangs anzuzeigen.</div>, { appearance: 'success' })
      })
    )
  }

  return (
    <div>
      <SupplierHeader>
        <h2>
          Bestellungen
        </h2>
        <div className='upload-container'>
          {(selectedTargetGroup && ['Mobile Medien', 'Dark-Posting (Facebook)', 'Dark-Posting (Dynamisch)'].includes(selectedTargetGroup.name)) && (
            <>
              <PrimaryButton
                onClick={handleClick}
                style={{ minWidth: '173px' }}
              > Bericht hochladen </PrimaryButton>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />

            </>
          )}
        </div>
        <div className='download-container'>
          {(selectedTargetGroup && ['Mobile Medien', 'Dark-Posting (Facebook)', 'Dark-Posting (Dynamisch)', 'Digitale Infoveranstaltungen'].includes(selectedTargetGroup.name)) && (
            <PrimaryButton
              style={{ minWidth: '173px' }}
              title={`Herunterladen ${selectedTargetGroup.name}`}
              aria-label={`Herunterladen ${selectedTargetGroup.name}`}
              onClick={download}
            > Bericht herunterladen </PrimaryButton>

          )}
        </div>
        <SupplierTargetGroups />
      </SupplierHeader>
      <SupplierFilters
        targetGroup={selectedTargetGroup?.name}
        onChange={(data) => filterdData(data)}
      />
      {loading
        ? <Spinner size={50} />
        : (
          <Table2>
            <thead>
              <tr>
                <th> Bestellnummer </th>
                <th> Bestellungsart </th>
                <th> Artikel </th>
                <th> Artikel Typen </th>
                <th> Preis </th>
                <th> Besteller </th>
                <th> Versandstatus </th>
                <th> Bestelldatum </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row: any) => (
                <tr
                  key={row.id}
                >
                  <th>{row.id}</th>
                  <td> {row.translated_order_type} </td>
                  <td> {row.count_items} </td>
                  <td> {row.count_types} </td>
                  <td> {FormatMoney(row.total_price)}</td>
                  <td> {row.user?.name} </td>
                  <TabelStatusCell>
                    <span
                      aria-label="Bearbeitet"
                      title="Bearbeitet"
                      className="success"
                    >{row.status.accepted} Bearbeitet</span>
                    <span
                      aria-label="Teilweise"
                      title="Teilweise"
                      className="warn"
                    >{row.status.partially} Teilweise</span>
                    <span
                      aria-label="Abgelehnt"
                      title="Abgelehnt"
                      className="danger"
                    >{row.status.declined} Abgelehnt</span>
                    <span
                      aria-label="Offen"
                      title="Offen"
                      className="info"
                    >{row.status.pending} Offen</span>
                  </TabelStatusCell>
                  <td>
                    {FormatDate(row.updated_at, true)}
                  </td>
                  <td>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-evenly'
                    }}
                    >
                      <IconButton
                        aria-label={`Bestellnummer ${row.id} info`}
                        as={Link}
                        to={'/supplier/' + row.id}
                        bg={'#4C4C4C'}
                      >
                        <FaInfo />
                      </IconButton>
                      <IconButton
                        aria-label={`Bestellnummer ${row.id} message`}
                        onClick={() => send(row.id)}
                        bg={'#4C4C4C'}
                      >
                        <FiMail />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {total > pageSize
              ? (
                <tfoot>
                  <tr style={{ borderTop: '1px solid #ccc' }}>
                    <td colSpan={9}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      >
                        <Pagintaion
                          currentPage={page}
                          onSelect={setPage}
                          totalCount={total}
                          pageSize={pageSize}
                        />
                      </div>
                    </td>
                  </tr>
                </tfoot>
              )
              : null}
          </Table2>
        )
      }
    </div>
  )
}

const SupplierHeader = styled.div`
align-items: center;
column-gap: 1em;
display: flex;

h2 {
  display: flex;
  flex: 1 1 0%;
  font-size: 2em;
  margin: 2em 0px;
}

@media (max-width: ${breakpoint.size.sm}) {
  flex-flow: wrap;
  h2 {
    display: flex;
    flex: 1 1 100%;
    margin: 2em 0px 1em 0;
  }
 
  .upload-container {
    flex: 1 1 0;
    justify-content: flex-end;
    display: flex;
  }
  
  > div {
    margin: 1em 0px;
  }
}

@media (max-width: 690px) {
  flex-flow: column;
  align-items: flex-start;
  row-gap: 1em;
  margin-bottom: 1em;

  h2 {
    margin: 0;
  }

  > div {
    margin: 0;
  }
}
`

const Table2 = styled(Table)`
  height: 0;
  min-height: 100px;
  width: 100%;

  tbody::before {
    content: '';
  }

  tbody td a,
  tbody td button {
    height: 35px;
    margin: 0;
    width: 35px;
  }
 
`

const TabelStatusCell = styled.td`
column-gap: 4px;
display: flex;
flex-flow: wrap;

.success {
  color: var(--success);
}

.danger {
  color: var(--danger);
}

.info {
  color: #006DFF;
}

.warn {
  color: #BD5B0B;
}

span {
  border-top: 3px solid;
  color: #fff;
  display: inline-block;
  flex: 1 0;
  font-weight: bold;
  min-width: 0;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: ${breakpoint.size.lg}) {
  span {
    flex: 1 0 calc(50% - 2px);
  }
}

@media (max-width: ${breakpoint.size.sm}) {
  span {
    flex: 1 0 100%;
  }
}
`
