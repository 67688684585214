/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

// list of all representative records
// params: initiator_id ?: allows to filter representatives which were initiated only by appropriate user
export const fetch = (initiator_id?: string, reset = false): AppAsyncThunk => (dispatch, getState, { api }) =>
  api.get<Representative[]>('/substitute_user_regions', { params: { initiator_id } }).then(({ data }) => {
    const myId = `${getState().auth?.user?.id}`
    data = data.filter((region) => new Date(region.end_date) > new Date())
    dispatch(actions.add({
      data,
      myId,
      reset
    }))
  })

export interface PostParam{
  substitute_user_id: number | string;
  region_id:string;
  start_date:Date
  end_date:Date
}
export const create = (params : PostParam): AppAsyncThunk<any> => (_dispatch, _getState, { api }) =>
  api.post<Representative>('/substitute_user_regions', {
    substitute_user_id: Number(params.substitute_user_id),
    region_id: Number(params.region_id),
    start_date: params.start_date.toISOString(),
    end_date: params.end_date.toISOString()
  })
  // .then(({ data }) => {
  //   const myId = `${getState().auth?.user?.id}`
  //   dispatch(actions.add({
  //     data: [data],
  //     myId
  //   }))
  // })

export const updateRecord = (id: string, params : PostParam): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.patch<Representative>('/substitute_user_regions/' + id, params).then(({ data }) => {
    dispatch(actions.update(data))
  })

export const removeRecord = (id: string): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.delete('/substitute_user_regions/' + id).then(() => {
    dispatch(actions.remove(id))
  })

export const getUsers = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<{id: number; email: string}[]>('/users/substitutes').then(({ data }) => {
    dispatch(actions.setUsers(data))
  })
