import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import claim from 'Assets/brand/dt-claim.svg'
import logo from 'Assets/brand/dt-logo.svg'
import styled from 'styled-components'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'

export interface HeaderProps {
  /**
   * name to display under banner
   */
  subheader?: string;
  children?: ReactNode
}
let d:any = -1

export function AppHeader ({ subheader, children }: HeaderProps): ReactElement {
  const [isSticky, setSticky] = useState(false)
  const id = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (d !== -1) {
      clearTimeout(d)
    }
    d = setTimeout(() => {
      if (id.current !== null) {
        const measure = id.current?.getBoundingClientRect()
        if (!measure || measure.y === undefined || measure.height === undefined || window.innerHeight < (measure.height * 4)) {
          return
        }

        const offset = Math.max(measure.y, measure.height, 60)
        const shouldStick = window.scrollY >= offset

        if (isSticky !== shouldStick) {
          setSticky(shouldStick)
        }
      }
    }, d !== -1 ? 1 : 30)
  }, [isSticky, id])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const height = id.current?.offsetHeight || 60

  return (
    <Fragment>
      <Container
        ref={id}
        data-testid="appheader"
        role="banner"
      >
        <div>
          <div className="brand-logo">
            <a
              href="/"
              tabIndex={0}
              aria-label="Home-Link"
              title="Home-Link"
            >
              <img
                src={logo}
                alt="Telekom Logo"
              />
              <span className="sr-only">Telekom Logo</span>
            </a>
          </div>

          <div
            className="brand-claim"
            style={{ marginTop: 25 }}
          >
            <img
              src={claim}
              alt="Telekom Brand Claim"
            />
            <span className="sr-only">Brand Claim</span>
          </div>
        </div>
      </Container>
      {isSticky && (
        <div
          data-testid="placeholder"
          style={{ height }}
        />
      )}
      {(subheader && typeof subheader === 'string') && (
        <SubHeader
          data-testid="SubHeader"
          isSticky={!!isSticky}
        >
          <div>
            <div className="home-link">
              <h1>
                {subheader.split(' ')[0]}{' '}
                <strong>{subheader.split(' ').slice(1).join(' ')}</strong>
              </h1>
            </div>
            {children}
          </div>
        </SubHeader>
      )}
    </Fragment>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: var(--magenta);
  display: flex;
  margin: 0;
  max-height: 33vh;
  min-height: 7rem;
  padding: 0 2rem;
  z-index: 8;

  > div {
    align-items: center;
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--max-width, 2500px);
  }
`
const SubHeader = styled.div<{ isSticky: boolean }>`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid var(--grey-250, #dcdcdc);
  border-top: 4px solid var(--magenta, #e20074);
  display: flex;
  min-height: 7rem;
  padding: 1.3rem;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 9;

  @media all and (max-width: 52em) {
    position: static;
  }
  ${({ isSticky }) => isSticky &&
  `position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 44px;
  `}
  > div {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: var(--max-width, 2500px);
    width: 100%;

    @media (max-width: 1000px) {
      justify-content: center;
    }

    > nav {
      @media (max-width: 1000px) {
        align-items: center;
        flex: 1;
        flex-flow: row nowrap;
        justify-content: flex-end;
        min-width: 500px;
      }

      @media (max-width: 800px) {
        justify-content: center;
        margin-bottom: 0.67em;
      }
    }
  }

  h1 {
    color: #262626;
    display: block;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    margin-right: 30px;
    padding: 0;
  }

  > h1 > strong {
    font-weight: 700;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    .home-link {
      order: 1;
    }

    .user-dropdown {
      order: 3;
    }

    .nav-widget {
      order: 2;
    }

    .budget-widget {
      flex: 0 1 100%;
      order: 4;
    }
  }

  @media (max-width: ${breakpoint.size.xs}) {
    .home-link {
      flex: 0 1 calc(100% - 64px);
      order: 1;
    }

    .user-dropdown {
      flex: 0 1 64px;
      order: 2;
    }

    .nav-widget {
      flex: 0 1 100%;
      justify-content: space-around;
      order: 3;
    }

    .budget-widget {
      order: 4;
    }
  }
`
