import { useAppDispatch, useAppSelector } from 'Config'
import api from 'Config/api'
import { REACT_APP_KAMBOX_FE_UID, REACT_APP_KAMBOX_URL } from 'Env'
import jwtDecode from 'jwt-decode'
// import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router'
import SplashScreen from 'Splash'
import { params, redirect_uri } from './const'
import { auth } from './redux'
import LoginPage from './screens/loginPage'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AuthProvider: React.FC<{ App: React.ComponentType<any>}> = ({ App }) => {
  const isLoggedIn = useAppSelector(auth.selectUser)

  api.defaults.headers.common.Authorization = `Bearer ${isLoggedIn?.access_token}`

  return (
    <Routes>
      <Route
        path="/oauth/callback"
        Component={OnSuccess}
      />
      <Route
        path='*'
        element={ isLoggedIn ? <App /> : <LoginPage />}
      />
    </Routes>
  )
}

function OnSuccess () {
  const [ready, toggle] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (params.code) {
      api.post<User>(REACT_APP_KAMBOX_URL + 'smart_sso_layer/api/v1/resources/token', {
        client_id: REACT_APP_KAMBOX_FE_UID,
        code: params.code,
        redirect_uri,
        grant_type: 'authorization_code'
      }).then(({ data }) => {
        if (data && data.access_token) {
          api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
          const membership_id = jwtDecode<{user:User}>(data.access_token)?.user?.membership_id
          dispatch(auth.set({
            ...data,
            membership_id
          }))
        }
      }).catch(console.error)
        .then(() => toggle(true))
    } else {
      toggle(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ready) { return <SplashScreen /> }

  return <Navigate to={sessionStorage.getItem('originalpath') || '/'} />
}
