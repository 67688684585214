import React, { KeyboardEventHandler } from 'react'
import styled from 'styled-components'

interface WizardStepsProps{
  activeIndex: number;
  steps: string[];
  disabled?: boolean;
  onClick?: (i:number) => void;
}

function onEnter (fn, idx): KeyboardEventHandler {
  return (e) => {
    e.key === 'Enter' && fn(idx, e)
  }
}
export function WizardSteps ({
  activeIndex = 1,
  onClick,
  steps = [],
  disabled,
  ...props
}:WizardStepsProps) {
  return (
    <Container
      {...props}
      role="listbox"
    >
      {steps.map((step, idx) => (
        <li
          role="option"
          aria-selected={idx === activeIndex}
          key={step}
          onKeyDown={onEnter(onClick, idx)}
          onClick={() => onClick && onClick(idx)}
          aria-label={step}
          className={`${idx === activeIndex ? 'active' : ''} ${disabled && 'disabled'}`}
        >
          <Step active={idx === activeIndex}>{idx + 1}</Step>
          <b>{step}</b>
        </li>
      ))}
    </Container>
  )
}

const Container = styled.ul`
  align-items: center;
  display: flex;
  flex-flow: row nowarp;
  justify-content: space-between;
  overflow: hidden;
  padding: 0;

  > li {
    align-items: center;
    display: flex;
    flex-flow: column;
    position: relative;

    ::before {
      content: '';
      border: 1px solid var(--primary);
      height: 0.2em;
      position: absolute;
      right: 50%;
      top: 25px;
      width: 100vw;
      z-index: -1;
    }
  }

  > li:not(.disabled) {
    cursor: pointer;
  }

  li ~ li::before {
    background: var(--primary);
    z-index: 1;
  }

  > li:first-child::before {
    background: #fff;
    border: 0;
    z-index: 2;
  }

  > .active ~ li::before {
    background: var(--gray-600);
    border: 1px solid var(--gray-600);
    z-index: 0;
  }

  .active ~ li > div {
    background: var(--gray-600);
    border: 1px solid var(--gray-600);
  }
`
const Step = styled.div<{ active: boolean }>`
  background-color: var(--primary);
  border: 1px solid;
  border-radius: 100%;
  color: #fff;
  line-height: 50px;
  margin-bottom: 0.5em;
  text-align: center;
  width: 50px;
  z-index: 3 !important;
`
