import Feature from './screens/ProfilePage'

const routes = [
  {
    path: '/profile',
    Component: Feature
  }
]

export default routes
