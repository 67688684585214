/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { region } from 'Features/region'
import { FormInput } from '../screens/formula/Form'
import { actions } from './reducer'

interface FormError{
  error: boolean;
  data: Record<string, string[]>
}

export const validateFormula = (type: string, data: any, headers = {}): AppAsyncThunk<FormError> => (_dispatch, _getState, { api }) =>
  api.post<FormError>('/orders/validate', data instanceof FormData
    ? data
    : {
      type,
      data
    }, { headers })
    .then(({ data }) => data)

export const getFormula = (type: string): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<Record<string, {attributes: FormInput[], formular_name: string}>>('/orders/new?type=' + type)
    .then(({ data }) => {
      dispatch(actions.addFormulas({
        [type]: data
      }))
    })

export const checkout = (type: string, data: any, headers = {}): AppAsyncThunk<any> => (dispatch, _getState, { api }) =>
  api.post('/orders', data instanceof FormData
    ? data
    : {
      type,
      data
    }, { headers })
    .then(({ data }) => {
      dispatch(region.fetch())
      return data
    })

export const fetch = (): AppAsyncThunk => (dispatch, getState, { api }) =>
  api.get<CartItem[]>('/carts').then(({ data }) => {
    const regionId = region.selectCurrentRegion(getState())?.region_key
    if (regionId) {
      dispatch(actions.add({
        data,
        regionId
      }))
    } else {
      alert('no region selected')
    }
  })

type LineItem = {
  id: string|number,
  type: string;
  amount: number,
  unit: number
}
export const addItemToCart = (line_item: LineItem): AppAsyncThunk<any> => (dispatch, _getState, { api }) => {
  const { unit, ...rest } = line_item
  const requestedAmount = rest.amount

  if (requestedAmount % unit !== 0) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`Bitte beachten Sie die Verpackungseinheit. Sie müssen mindestens ${unit} Artikel oder ein Vielfaches von ${unit} auswählen.`)
  }

  return api.post('/carts', { line_item: rest })
    .then((r) => Promise.all([
      dispatch(fetch()),
      Promise.resolve(r)
    ]))
}
export const removeItem = (line_item: Partial<LineItem>): AppAsyncThunk<any> => (dispatch, _getState, { api }) =>
  api.delete('/carts', { data: { line_item } })
    .then((data) => Promise.all([
      dispatch(fetch()),
      Promise.resolve(data)
    ]))

export const cleanCart = (type: string): AppAsyncThunk<any> => (dispatch, getState, { api }) =>
  api.delete(`/carts/remove/${type}`)
    .then(() => {
      const regionId = region.selectCurrentRegion(getState())?.region_key
      dispatch(actions.clear({
        type,
        regionId
      }))
    })
