/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import styled from 'styled-components'

// import DashboardBreadCrumb from '../components/breadcrumb'
import { Header, PrimaryButton, Spinner } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { region } from 'Features/region'
import { orders } from '../redux'
import { RegionOrdersCard } from '../components/OrderTableRow'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { Helmet } from 'react-helmet'
import { useT } from '../translations'

export default function OrdersFeature () {
  const t = useT()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const viewCanceledOrders = location.pathname === '/orderscanceled'
  const viewActiveOrders = location.pathname === '/ordersactive'
  const regions = useAppSelector(region.selectRegions)
  const dispatch = useAppDispatch()
  const ordersStore = useAppSelector(orders.selectOrdersPreview)

  useEffect(() => {
    setLoading(true)
    dispatch(orders.resetPreview())
    dispatch(orders.fetchOrders({
      page: 1,
      limit: 5,
      ...(viewCanceledOrders ? { state: 'canceled' } : viewActiveOrders ? { state: 'active' } : {})
    })).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Container>
      <Helmet>
        <title>Bestellungen</title>
      </Helmet>

      <HeaderContainer>
        <Header as="h2">{viewCanceledOrders ? t.orders.cancelled : t.orders.title }</Header>
        <ButtonsContainer>

          <HeaderButton
            title={t.orders.all}
            aria-label={t.orders.all}
            disabled={(!viewCanceledOrders && !viewActiveOrders) || loading}
            onClick={() => navigate('/orders')}
          >{t.orders.all}</HeaderButton>

          <HeaderButton
            title={t.orders.active}
            aria-label={t.orders.active}
            disabled={viewActiveOrders || loading}
            onClick={() => navigate('/ordersactive')}
          >{t.orders.active}</HeaderButton>

          <HeaderButton
            title={t.orders.cancelled}
            aria-label={t.orders.cancelled}
            disabled={viewCanceledOrders || loading}
            onClick={() => navigate('/orderscanceled')}
          >{t.orders.cancelled}</HeaderButton>

        </ButtonsContainer>
      </HeaderContainer>
      {loading
        ? (<Spinner size={50} />)
        : (Object.values(regions).map(r => {
          return (
            <RegionOrdersCard
              key={r.region_key}
              fetch={page => dispatch(orders.fetchOrders({
                page: page + 1,
                limit: 5,
                region_key: r.region_key,
                ...(viewCanceledOrders ? { state: 'canceled' } : viewActiveOrders ? { state: 'active' } : {})
              }))}
              region={r}
              total={ordersStore[r.id]?.length || 0}
              data={ordersStore[r.id]}
            />
          )
        }))}

    </Container>
  )
}

const HeaderContainer = styled.div`
align-items: center;
display: flex;
flex-flow: wrap;
justify-content: space-between;
margin: 1rem 0 1.5rem 0;
position: relative;

h2 {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
}
`
const ButtonsContainer = styled.div`
column-gap: 15px;
display: flex;
padding: 0.7rem;
padding-left: 0;
`

const HeaderButton = styled(PrimaryButton)`
border: 1px solid;
min-width: 125px;
`

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;

@media (max-width: ${breakpoint.size.sm}) {
  overflow-y: auto;

  > div {
    overflow-y: auto;
  }
}
`
