/* eslint-disable sonarjs/cognitive-complexity */
import { Card } from 'Components'
// import { groupBy } from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormRow } from './Row'
export interface FormInputMetaData {
  required: boolean;
  pattern: string;
  notes: string;
  maxLength: number;
  minLength: number;
  days_offset: string | number;
  multiple_files: boolean,
  maximum: number,
  minimum: number
}

export interface FormInput {
  addresses_attributes?: Array<FormInput[]>
  speakers_attributes?: Array<FormInput[]>
  videos_attributes?: Array<FormInput[]>
  files_attributes?: Array<FormInput[]>
  publication_dates_attributes?: Array<FormInput[]>
  name: string;
  type: string;
  section: string;
  localized: string;
  width: string;
  order: number;
  collection: string[] | null
  depend_on: string | null;
  default_attribute: string | null;
  meta_data: FormInputMetaData;
}

interface I {
  errors?: Record<string, string[]>,
  initialValue?: Record<string, any>,
  readOnly?: boolean | {},
  orderDetailsView?: boolean,
  supplierForm?: boolean,
  data: FormInput[],
  prefix?:string|null,
  orderId?: string;
  isEditForm?: boolean;
}

export function Form ({ data, errors, prefix, initialValue = {}, orderId, readOnly = false, supplierForm = false, orderDetailsView = false, isEditForm = false }: I) {
  const [value, setData] = useState(initialValue)
  const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]'

  useEffect(() => {
    if (supplierForm || isObject(readOnly) || isEditForm) {
      setData(initialValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  const onChange = useCallback((_i:FormInput) => (e:any) => {
    const target = e.target
    const value = target.type === 'file' ? target.files[0] : target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setData(data => ({
      ...data,
      [name]: value
    }))
  }, [])
  const onChangeTime = useCallback((_i:FormInput) => (name: any, value: any) => {
    setData(data => ({
      ...data,
      [name]: value
    }))
  }, [])
  const groups = {} as Record<string, FormInput[]>
  function addToGroup (i) {
    if (i.section) {
      groups[i.section] = groups[i.section] || []
      groups[i.section].push(i)
    }
  }

  const onChangeCopyright = useCallback((_i:FormInput) => (numberOfFiles: number | undefined, name: string) => {
    Array(Number(_i.meta_data.maximum || 0)).fill('').forEach((_, index) => {
      const filedName = name + '.' + index
      setData(data => ({
        ...data,
        [filedName]: index < (numberOfFiles || -1)
      }))
    })
    setData(data => ({
      ...data,
      [name]: numberOfFiles
    }))
  }, [])

  const approvalOrContactInfo = (data || []).find(({ name }) => name === 'approval_or_contact_info')
  const userUpload = (data || []).find(({ name }) => name === 'user_upload')
  const selection = (data || []).find(({ name }) => name === 'selection')
  const regex = /^copyright_\d+$/i
  Array.from(data || []).forEach(i => {
    if (i.addresses_attributes) {
      i.addresses_attributes.forEach((row, idx) => {
        const key = initialValue?.addresses ? 'addresses' : 'addresses_attributes'
        row.forEach(ix => {
          addToGroup({
            ...ix,
            name: key + '.' + idx + '.' + ix.name,
            depend_on: ix.depend_on && idx > 0 ? key + '.' + (idx - 1) + '.' + ix.depend_on : ix.depend_on
          })
        })
      })
    } else if (i.speakers_attributes) {
      i.speakers_attributes.forEach((row, idx) => {
        const key = initialValue?.speakers ? 'speakers' : 'speakers_attributes'
        row.forEach(index => {
          addToGroup({
            ...index,
            name: key + '.' + idx + '.' + index.name,
            depend_on: index.depend_on && idx > 0 ? key + '.' + (idx - 1) + '.' + index.depend_on : index.depend_on
          })
        })
      })
    } else if (i.videos_attributes) {
      i.videos_attributes.forEach((row, idx) => {
        const key = initialValue?.videos ? 'videos' : 'videos_attributes'
        row.forEach(videoIndex => {
          addToGroup({
            ...videoIndex,
            name: key + '.' + idx + '.' + videoIndex.name,
            depend_on: videoIndex.depend_on && idx > 0 ? key + '.' + (idx - 1) + '.' + videoIndex.depend_on : videoIndex.depend_on
          })
        })
      })
    } else if (i.files_attributes) {
      i.files_attributes.forEach((row, idx) => {
        const key = initialValue?.files ? 'files' : 'files_attributes'
        row.forEach(filesIndex => {
          addToGroup({
            ...filesIndex,
            name: key + '.' + idx + '.' + filesIndex.name,
            depend_on: filesIndex.depend_on && idx > 0 ? key + '.' + (idx - 1) + '.' + filesIndex.depend_on : filesIndex.depend_on
          })
        })
      })
    } else if (i.publication_dates_attributes) {
      i.publication_dates_attributes.forEach((row, idx) => {
        const key = initialValue?.publication_dates ? 'publication_dates' : 'publication_dates_attributes'
        row.forEach(publicationDatesIndex => {
          addToGroup({
            ...publicationDatesIndex,
            name: key + '.' + idx + '.' + publicationDatesIndex.name,
            depend_on: publicationDatesIndex.depend_on && idx > 0 ? key + '.' + (idx - 1) + '.' + publicationDatesIndex.depend_on : publicationDatesIndex.depend_on
          })
        })
      })
    } else if (selection && ['area_streets'].includes(i.name)) {
      if (i.name === 'area_streets') {
        if ((value[`${prefix}.selection`] === 'citizen_information_event' || value.selection === 'citizen_information_event')) {
          addToGroup({
            ...i,
            depend_on: undefined
          })
        } else {
          const name = prefix ? `${prefix}.${i.name}` : i.name
          value[name] && onChangeCopyright(i)(undefined, name)
        }
      }
    } else if (approvalOrContactInfo && ['approval', 'contact_name', 'contact_phone', 'contact_email'].includes(i.name)) {
      if ((i.name === 'approval' && value.approval_or_contact_info === 'file_upload') ||
       (['contact_name', 'contact_phone', 'contact_email'].includes(i.name) && value.approval_or_contact_info === 'contact_info')) {
        addToGroup({
          ...i,
          depend_on: undefined
        })
      }
      if ((i.name === 'approval' && value[`${prefix}.approval_or_contact_info`] === 'file_upload') ||
       (['contact_name', 'contact_phone', 'contact_email'].includes(i.name) && value[`${prefix}.approval_or_contact_info`] === 'contact_info')) {
        addToGroup({
          ...i,
          depend_on: undefined
        })
      }
    } else if (userUpload && ['use_regio_pic'].includes(i.name)) {
      if (i.name === 'use_regio_pic') {
        if ((value[`${prefix}.user_upload`] === 'use_regio_own' || value.user_upload === 'use_regio_own')) {
          addToGroup({
            ...i,
            depend_on: undefined
          })
        } else {
          const name = prefix ? `${prefix}.${i.name}` : i.name
          value[name] && onChangeCopyright(i)(undefined, name)
        }
      }
    } else if (regex.test(i.name)) {
      const matches = i.name.match(/\d+$/)
      matches && addToGroup({
        ...i,
        depend_on: i.depend_on + '.' + matches[0]
      })
    } else if (i.name === 'custom_motive_files') {
      if (!(value[`${prefix}.custom_motive`] || value.custom_motive)) {
        const name = prefix ? `${prefix}.${i.name}` : i.name
        value[name] && onChangeCopyright(i)(undefined, name)
      }
      addToGroup(i)
    } else {
      addToGroup(i)
    }
  })

  return (
    <>
      {Object.entries(groups).map(([name, attr]) => (
        <Card
          key={name}
          title={name}
          style={{ marginTop: '36px' }}
        >
          <Row>
            {attr.sort((a, b) => a.order === b.order ? 0 : a.order > b.order ? 1 : -1).map((i, index) => (
              <React.Fragment key={index}>
                {!(i.depend_on && !value[prefix ? `${prefix}.${i.depend_on}` : i.depend_on] && !(orderDetailsView && value[prefix ? `${prefix}.${i.name}` : i.name])) && (
                  <>
                    <FormRow
                      key={i.name}
                      readOnly={readOnly}
                      orderDetailsView={orderDetailsView}
                      errors={errors}
                      i={i}
                      prefix={prefix}
                      value={value}
                      onChange={onChange}
                      onChangeTime={onChangeTime}
                      onChangeCopyright={onChangeCopyright}
                      orderId={orderId}
                    />
                    <RowNotes>{i.meta_data?.notes }</RowNotes>
                  </>
                )}
              </React.Fragment>
            ))}
          </Row>
        </Card>
      ))}
    </>
  )
}

const Row = styled.div`
display: flex;
flex-flow: row wrap;
font-size: 18px;
`

const RowNotes = styled.div`
color: var(--gray-900);
`
