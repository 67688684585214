function appendSlash (str?: string) {
  if (!str) return str

  return str[str.length - 1] === '/' ? str : str + '/'
}

export const REACT_APP_SMART_SSO_URL = appendSlash(process.env.REACT_APP_SMART_SSO_URL)
export const REACT_APP_KAMBOX_API_URL = appendSlash(process.env.REACT_APP_KAMBOX_API_URL)
export const REACT_APP_KAMBOX_URL = REACT_APP_KAMBOX_API_URL?.split('/').slice(0, -3).join('/') + '/'
export const REACT_APP_KAMBOX_FE_UID = process.env.REACT_APP_KAMBOX_FE_UID
export const REACT_APP_TIMEOUT = 20000
export const REACT_APP_PWA = false
export const REACT_APP_VITALS = false
