import { keyframes } from 'styled-components'

export const pulse = keyframes`
0% {
  background-color: rgb(235, 235, 235);
}
50% {
  background-color: rgb(245, 245, 245);
}
100% {
  background-color: rgb(235, 235, 235);
}
`
