import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'

export const ID_PARAM = 'id'

export interface FaqState{
  filter: {
    category?: string;
    searchText?: string;
    sortKey?: string;
    sortDirection?: 'ASC' | 'DESC'
  };
  byId: Record<string, Faq>,
  category: Record<string, FaqCategory>,
  ids: string[];
}

export const initialState:FaqState = {
  filter: {},
  byId: {},
  category: {},
  ids: []
}

function filterFaqs (map: FaqState['byId'], filter: FaqState['filter']):string[] {
  let ids = Object.keys(map).filter(id => {
    if ('category' in filter && filter.category && `${map[id].faq_category.id}` !== `${filter.category}`) {
      return false
    }
    if (!filter.searchText || filter.searchText.length < 3) {
      return true
    }
    const q = map[id].question.toLowerCase() + ' ' + map[id].answer.toLowerCase()
    const txt = filter.searchText.toLowerCase()
    console.log('searching for ' + txt, 'in ' + q)
    return q.indexOf(txt) > -1
  })
  ids = sortBy(ids, [filter.sortKey || ID_PARAM])
  return ids
}

const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Faq[]>) => {
      action.payload.forEach((item) => {
        state.byId[item[ID_PARAM]] = item
        state.category[item.faq_category.id] = item.faq_category
      }, state)
      state.filter = { category: state.filter.category }
      state.ids = filterFaqs(state.byId, state.filter)
    },
    incrementHelpful: (state, action: PayloadAction<{id: string, count: number, marked: boolean}>) => {
      state.byId[action.payload.id].helpful_count = action.payload.count
      state.byId[action.payload.id].marked = !!action.payload.marked
    },
    setFilter: (state, action: PayloadAction<FaqState['filter'] | { __reset: true }>) => {
      if ('__reset' in action.payload) {
        state.filter = { category: state.filter.category }
      } else {
        state.filter = action.payload.category && action.payload.category !== state.filter.category
          ? { category: action.payload.category }
          : {
            ...state.filter,
            ...action.payload
          }
      }
      state.ids = filterFaqs(state.byId, state.filter)
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
