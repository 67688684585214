import styled from 'styled-components'

export const Header = styled.header`
align-items: center;
color: #262626;
display: block;
font-size: 18px;
font-weight: 900;
letter-spacing: 0;
line-height: 18px;
margin-bottom: 24px;
margin-top: 0px;
text-align: left;
text-transform: uppercase;

svg {
  float: left;
  margin-right: 12px;
}
`
