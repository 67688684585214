import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const ID_PARAM = 'id'

export interface ChatState{
  data: ChatListItem[],
  unread: number,
  filter?: {region_id?: string, order_id?: string, page?:number} | null ;
  pageSize: number,
  cacheKey: string;
  totalCount: number
}

export const initialState:ChatState = {
  data: [],
  unread: 1,
  pageSize: 15,
  totalCount: 15,
  cacheKey: ''
}

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    markThreadsAllAsRead: (state) => {
      state.unread = 0
      state.data = state.data.map(row => row
        ? {
          ...row,
          read: true
        }
        : row)
    },
    setFilters: (state, action: PayloadAction<ChatState['filter']>) => {
      state.filter = action.payload
    },
    set: (state, action: PayloadAction<{total_count: number, offset: number, page_size: number, notifications: ChatListItem[]}>) => {
      const { notifications, total_count, offset = 0 } = action.payload
      const cacheKey = JSON.stringify(state.filter)
      const reset = cacheKey !== state.cacheKey
      state.cacheKey = cacheKey
      state.data = reset ? [] : state.data || []
      state.data = Array.from({ length: total_count }, (_e, idx) => idx >= offset ? notifications[idx - offset] || state.data[idx] : state.data[idx] || null)
      state.pageSize = action.payload.page_size
    },
    update: (state, action: PayloadAction<ChatListItem>) => {
      const idx = state.data.findIndex(r => r && r.id === action.payload?.id)
      if (idx > -1) {
        if (state.data[idx].read !== action.payload.read) {
          state.unread = action.payload.read ? state.unread - 1 : state.unread + 1
        }
        state.data[idx] = action.payload
      } else {
        state.data = [...state.data, action.payload]
      }

      if (state.unread < 0) {
        state.unread = 0
      }
    },
    addFragment: (state, action: PayloadAction<{id: string, data: ChatListFragment}>) => {
      const idx = state.data.findIndex(r => r && `${r.id}` === `${action.payload.id}`)
      if (idx === -1 || !state.data[idx]) {
        console.error('couldnt find message in which i replyed to !!')
        return state
      }
      const fidx = state.data[idx].fragments?.findIndex(r => `${r?.id}` === `${action.payload.data.id}`)
      if (fidx !== -1) {
        console.warn('trying to add a fragment to thread,  but its already there')
        return state
      }
      state.data[idx].fragments.push(action.payload.data)
      return state
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.unread = action.payload || 0
      if (state.unread < 0) {
        state.unread = 0
      }
    }
  }
})

export default slice

export const { actions, reducer, name } = slice
