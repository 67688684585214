import { RootState } from 'Config/store'

import { name, initialState } from './reducer'

export const slice = (state: RootState) => state[name] || initialState

export const selectUser = (state: RootState) => {
  return slice(state).user
}

export const selectUserTargetGroups = (state: RootState) => {
  return slice(state).target_groups
}

export const selectCurrentTargetGroup = (state: RootState):TargetGroup | null => {
  const selected = slice(state).target_groups?.filter(row => !!row.selected)[0]

  return selected || null
}

export const selectUserToken = (state: RootState) => {
  return slice(state).user?.access_token
}

export const isSupplier = (state: RootState) => {
  return slice(state).user?.role === 'supplier'
}
