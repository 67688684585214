import { FormInput } from './Form'

export * from './Form'

/* @DEPRICATED: Removed after adding section attribute to formula */
export const labels = {
  basic: 'Empfänger'
}

export const getDefaultValue = (item: FormInput[]|FormInput, userInfo?: User, prefix?: string) => {
  if (Array.isArray(item)) {
    return item.reduce((carry, itemx) => {
      carry[prefix ? `${prefix}.${itemx.name}` : itemx.name] = getDefaultValue(itemx, userInfo)
      return carry
    }, {})
  }

  // set default value to user profile in some inputs that ask for it
  if (item.default_attribute) {
    const attr = item.default_attribute.split('user.')[1]
    return userInfo ? userInfo[attr] || '' : ''
  }
  return ''
}
export function getInitialFormValue (formula, val) {
  val = val ? JSON.parse(JSON.stringify(val)) : val
  if (!formula) {
    console.log('couldnt find formula', formula)
    return val
  }
  for (const key in val) {
    if (Object.prototype.hasOwnProperty.call(val, key)) {
      const e = val[key]
      if (Array.isArray(e)) {
        e.forEach((row, idx) => Object.entries(row).forEach(([name, value]) => {
          const newName = [key, idx, name].join('.')
          val[newName] = value
        }))
      }
    }
  }
  return val
}

export function getInitialFormValueWithPrefix (formula, val, pr) {
  val = val ? JSON.parse(JSON.stringify(val)) : val
  if (!formula) {
    console.log('couldnt find formula', formula)
    return val
  }
  for (const key in val) {
    if (Object.prototype.hasOwnProperty.call(val, key)) {
      const e = val[key]
      if (Array.isArray(e)) {
        e.forEach((row, idx) => Object.entries(row).forEach(([name, value]) => {
          const newName = [pr, key, idx, name].join('.')
          val[newName] = value
        }))
      } else {
        val[[pr, key].join('.')] = val[key]
      }
    }
  }
  return val
}
