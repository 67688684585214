import React, { FC, ReactNode } from 'react'

import styled from 'styled-components'

interface TabsInterface{
  readonly data: string[];
  readonly renderItem?: (a:string, idx: number)=>ReactNode
  readonly extractKey?: (a:string, idx: number)=>string
  readonly onChange: (idx: number, a:string)=>void|Promise<void>
  readonly active?: number;
}

export const Tabs: FC<TabsInterface> = ({ data, renderItem, onChange, active = 0, extractKey = String }) => {
  return (
    <Row>
      {data.map((item, idx) => (
        <Element
          key={extractKey ? extractKey(item, idx) : item}
          active={active === idx}
          aria-label={item}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onChange(idx, item)
          }}
        >
          {renderItem ? renderItem(item, idx) : item}
        </Element>
      ))
      }</Row>
  )
}

const Row = styled.nav`
align-items: center;
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
width: 100%;
`
const Element = styled.button<{active: boolean}>`
background: #fff;
border: 0;
border-bottom-width: 4px;
border-color: ${({ active }) => active ? 'var(--primary, #E20074)' : 'transparent'};
border-style: solid;
color: ${({ active }) => active ? 'var(--primary, #E20074)' : '#222'};
font-size: 18px;
font-weight: ${({ active }) => active ? '800' : '300'};
margin-right: 4px;
outline: 0;
overflow: hidden;
padding: 1em;
text-align: right;
`
