const categoryPage = {
  required: ' muss ausgefüllt werden',
  checkoutMsg: {
    title: 'Ihre Bestellung wurde erfolgreich abgesendet.',
    body: 'Zusätzlich haben wir Ihnen eine Bestellbestätigung per E-Mail zukommen lassen. Bitte beachten Sie, dass die Bearbeitung Ihrer Bestellung einige Zeit in Anspruch nehmen kann.',
    submit: 'Zum Dashboard'
  },
  fileInput: {
    copyright: 'Copyrights'
  },
  datePicker: {
    placeholderDate: 'dd.mm.yyy'
  },
  cartTypes: {
    Product: 'Produkte',
    CommMedium: 'Kommunikationsmittel',
    MobileMedium: 'Mobile Medien',
    Event: 'Event',
    OnlineCommunication: 'Online-Kommunikation',
    DigitalInfoEvent: 'Digital Info Events',
    PrintingHouseOrder: 'Individuelle Werbemittel',
    MobilePoS: 'LaMa (Mobile PoS)'
  }
} as const

export default categoryPage
