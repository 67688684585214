import Feature from './screens/FaqPage'

const routes = [
  {
    path: '/pages/faq',
    Component: Feature
  }
]

export default routes
