import { REACT_APP_SMART_SSO_URL } from 'Env'
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

export const refreshToken = () => console.error('not implemented ')

export const attemptLogout = (includeSSo): AppAsyncThunk => (dispatch, _getState, { api }) => {
  dispatch({
    type: 'RESET',
    payload: undefined
  })
  return includeSSo
    ? api.get('/api/v1/users/logout', {
      baseURL: REACT_APP_SMART_SSO_URL
    })
    : api.get('/users/logout')
}
interface ProfileError{
  title: string;
  detail: string;
  code: string;
  source: { pointer: string };
  status: string;
}

export const editProfile = (id: string, attributes: any): AppAsyncThunk<any, Record<string, string>> => (_dispatch, _getState, { api }) =>
  api
    .put(
      `/api/v2/profiles/${id}`,
      {
        data: {
          type: 'profiles',
          id,
          attributes
        }
      },
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        baseURL: REACT_APP_SMART_SSO_URL
      }
    ).catch(e => {
      if (e?.response?.status === 422) {
        const { errors } = e?.response?.data || { errors: [] }
        const newErrors = {}
        errors.forEach((err: ProfileError) => {
          const name = err.source.pointer.split('/').pop()?.split('.').pop() || '__general'
          const val = err.title
          newErrors[name] = newErrors[name] ? newErrors[name] + ', ' + val : val
        })
        throw newErrors
      }
    })

export const fetchTargetGroups = (): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get('/users').then(({ data }) => {
    dispatch(actions.setTargetGroups(data[0].target_groups))
  })
