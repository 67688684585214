import { PrimaryButton, Spinner } from 'Components'
import { WizardSteps } from 'Components/WizardSteps'
import { useAppDispatch, useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import { region } from 'Features/region'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ProductList } from '../components'
import { cart } from '../redux'
import { useT } from '../translations'
import { Form, getDefaultValue } from './formula'

export function appendToForm (data, obj, prefix = ['data']) {
  Object.entries(obj).forEach(([key, val]) => {
    if (Array.isArray(val) && Object.values(val).every(file => file instanceof File)) {
      Object.values(val).forEach(file => {
        data.append(prefix.map((i, idx) => idx === 0 ? i + '[' : i + '][').join('') + key + '][]', file)
      })
    } else if (val && typeof val === 'object' && val instanceof File === false) {
      appendToForm(data, val, [...prefix, key])
    } else {
      data.append(prefix.map((i, idx) => idx === 0 ? i + '[' : i + '][').join('') + key + ']', val)
    }
  })
}

export const dataHasFile = (data) => {
  if (Object.values(data).some(i => (i instanceof File) || (Array.isArray(i) && Object.values(i).some(ii => ii instanceof File)))) { return true }
  const formAttributes = data[Object.keys(data)[0]]
  if (Array.isArray(formAttributes)) {
    return formAttributes.reduce((a, c) => {
      Object.values(c).forEach((item) => {
        if (Array.isArray(item)) {
          a = item.reduce((pV, cV) => {
            return a || pV || Object.values(cV).some(i =>
              (i instanceof File) || (Array.isArray(i) && Object.values(i).some(ii => ii instanceof File)))
          }, false)
        }
      })
      return a || Object.values(c).some(i =>
        (i instanceof File) || (Array.isArray(i) && Object.values(i).some(ii => ii instanceof File)))
    }, false)
  }
  return false
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function CheckoutStep1 () {
  const [readOnly, toggleReadOnly] = useState<any>(null)
  const [loading, toggleLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams<{ category: string; step: string }>()
  const state = useAppSelector(cart.products).filter(row => row.line_item_type === params.category)
  const selectedRegion = useAppSelector(region.selectCurrentRegion)
  const t = useT()
  const form = useAppSelector(cart.formula)[params.category ?? ''] || {}
  const [error, setError] = useState<Record<string, string[]>>({})
  const user = useAppSelector(auth.selectUser)
  const prefix = params.category === 'OnlineCommunication'
    ? 'online_communication_order_infos_attributes'
    : params.category === 'MobileMedium'
      ? 'mobile_medium_order_infos_attributes'
      : params.category === 'DigitalInfoEvent'
        ? 'digital_info_event_order_infos_attributes'
        : 'comm_medium_order_infos_attributes'
  // onstartup, load products into cache if they are empty
  const update = useCallback(() => {
    if (state.length === 0) {
      dispatch(cart.fetch())
    }
    if (readOnly) { toggleReadOnly(null) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (readOnly) {
      const headers = {}
      const hasFile = dataHasFile(readOnly)
      let data = readOnly
      if (hasFile) {
        headers['Content-Type'] = 'multipart/form-data'
        data = new FormData()
        data.append('type', params.category)
        appendToForm(data, readOnly, ['data'])
      }
      toggleLoading(true)
      dispatch(cart.validateFormula(params.category ?? '', data, headers))
        .then(r => {
          if (r.error) {
            setError(r.data || {})
            toggleReadOnly(null)
          } else {
            setError({})
          }
        }).finally(() => { toggleLoading(false) })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onsubmit = (e) => {
    e.preventDefault()
    // eslint-disable-next-line compat/compat

    if (readOnly) {
      const headers = {}
      const hasFile = dataHasFile(readOnly)
      let data = readOnly
      if (hasFile) {
        headers['Content-Type'] = 'multipart/form-data'
        data = new FormData()
        data.append('type', params.category)
        appendToForm(data, readOnly, ['data'])
      }
      toggleLoading(true)
      dispatch(cart.checkout(params.category ?? '', data, headers))
        .then(() => {
          navigate('/checkout/success', { replace: true })
        })
        .catch((_e) => {
          toggleReadOnly(null)
          setError({})
        })
        .finally(() => { toggleLoading(false) })
    } else {
      if (!prefix) {
        const data = Object.fromEntries(new FormData(e.target).entries())
        toggleReadOnly(data)
        window.scrollTo(0, 0)
        return
      }
      const data = (new FormData(e.target)).entries()
      const formData = {}
      Array.from(data).forEach(([key, val]) => {
        const parts = key.split('.')

        parts.reduce((c, i, idx) => {
          if (idx === (parts.length - 1)) {
            if (val instanceof File) {
              if (val.size > 0) {
                if (e.target.elements[key].multiple) {
                  if (c[i]) {
                    c[i] = [...c[i], val]
                  } else {
                    c[i] = [val]
                  }
                } else {
                  c[i] = val
                }
              }
            } else {
              c[i] = val
            }
            return c
          }
          if (!c[i]) {
            c[i] = isNaN(Number(parts[idx + 1])) ? {} : []
            console.log(`${i} is not defined`, Array.isArray(c[i]))
          }
          return c[i]
        }, formData)
      })
      toggleReadOnly(formData)
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    dispatch(cart.getFormula(params.category ?? ''))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.category])

  if (state.length === 0) {
    return (
      <div style={{
        display: 'flex',
        marginTop: '2em',
        alignItems: 'center'
      }}
      >
        <h1>No items in cart</h1>
        <div style={{ marginLeft: '2em' }}>
          <PrimaryButton
            aria-label={'Abbrechen'}
            bg="#4C4C4C"
            as={Link}
            to="/"
          >Abbrechen</PrimaryButton>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div style={{
        maxWidth: 800,
        margin: '0 auto'
      } }
      >
        <WizardSteps
          steps={['Produkt-Bestellung', 'Bestellübersicht', 'Bestellbestätigung']}
          activeIndex={readOnly ? 1 : 0}
        />
      </div>
      <H1>{t.cartTypes[params.category ?? '']}{'-Bestellung, '}{selectedRegion?.name}</H1>

      <ProductList
        noTotal
        noDelete
        fetch={update}
        products={state}
      />

      <form
        onSubmit={onsubmit}
        style={{ padding: 4 }}
        id="checkout-form"
      >
        { form?.basic
          ? (
            <Form
              initialValue={user && getDefaultValue(form?.basic?.attributes, user)}
              data={form?.basic?.attributes}
              readOnly={!!readOnly}
              errors={error}
            />
          )
          : params.category === 'MobileMedium'
            ? (Array.from(state).map((item, idx) => {
              if (!form.main_attributes) {
                console.log('couldnt find main_attributes')
                return null
              }
              return (
                <fieldset
                  style={{ marginBottom: 40 }}
                  key={item.id + '_form'}
                >
                  <legend style={{
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                  >{item.name}</legend>
                  {prefix
                    ? (
                      <>
                        <input
                          type="hidden"
                          name={`${prefix}.${idx}.ordered_item_type`}
                          value={params.category}
                        />
                        <input
                          type="hidden"
                          name={`${prefix}.${idx}.ordered_item_id`}
                          value={item.id}
                        />
                      </>
                    )
                    : null}
                  <Form
                    key={item.id + '_form'}
                    errors={error}
                    initialValue={user && getDefaultValue(form?.main_attributes?.attributes, user, `${prefix}.${idx}`)}
                    data={form?.main_attributes?.attributes}
                    readOnly={!!readOnly}
                    prefix={prefix ? `${prefix}.${idx}` : null}
                  />
                </fieldset>
              )
            }))
            : (Array.from(state).map((item, idx) => {
              console.log('item.formular_identifier:', item.formular_identifier)
              const val = form[item.formular_identifier + '_attributes']
              if (!val) {
                console.log('couldnt find ' + item.formular_identifier, form)
                return null
              }
              return (
                <fieldset
                  style={{ marginBottom: 40 }}
                  key={item.id + '_fieldset'}
                >
                  <legend style={{
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                  >{item.name}</legend>
                  {prefix
                    ? (
                      <>
                        <input
                          type="hidden"
                          name={`${prefix}.${idx}.ordered_item_type`}
                          value={params.category}
                        />
                        <input
                          type="hidden"
                          name={`${prefix}.${idx}.ordered_item_id`}
                          value={item.id}
                        />
                      </>
                    )
                    : null}
                  <Form
                    key={item.id + '_form'}
                    errors={error}
                    initialValue={user && getDefaultValue(val.attributes, user, `${prefix}.${idx}`)}
                    data={val.attributes}
                    readOnly={!!readOnly}
                    prefix={prefix ? `${prefix}.${idx}` : null}
                  />
                </fieldset>
              )
            }))}
        <ActionsRow>
          {readOnly
            ? (
              <PrimaryButton
                aria-label={'Zurück'}
                disabled={!readOnly || loading}
                bg="#4C4C4C"
                type="reset"
                style={{ margin: 0 }}
                onClick={e => {
                  toggleReadOnly(null)
                  e.preventDefault()
                }}
              >Zurück</PrimaryButton>
            )
            : <div />}
          <PrimaryButton
            aria-label={(readOnly ? (params.category === 'MobileMedium' ? 'Bestellen' : 'Zahlungspflichtig bestellen') : 'Bestellübersicht anzeigen')}
            type="submit"
            disabled={loading}
            style={{ minWidth: 120 }}
          >
            {loading
              ? <Spinner size={30} />
              : (readOnly ? (params.category === 'MobileMedium' ? 'Bestellen' : 'Zahlungspflichtig bestellen') : 'Bestellübersicht anzeigen')
            }
          </PrimaryButton>
        </ActionsRow>

      </form>
    </div>
  )
}
export default CheckoutStep1

const H1 = styled.h1`
  margin-bottom: 24px;
  margin-top: 60px;
  text-transform: capitalize;
`
const ActionsRow = styled.div`
display: flex;
flex-flow: row wrap;
font-size: 18px;
justify-content: space-between;
margin-top: 2em;
`
