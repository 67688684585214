import Feature from './screens/NewsPage'

const routes = [
  {
    path: '/pages/news/:id?',
    Component: Feature
  }
]

export default routes
