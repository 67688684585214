import { useAppSelector } from 'Config'
import { region } from 'Features/region'
import React, { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import authFeature from 'Features/auth/routes'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { Modal, PrimaryButton } from 'Components'
import { useT } from 'Components/Modal/translations'
import styled from 'styled-components'
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function AppRouter ({ routes, isSupplier }) {
  const t = useT()
  const selectedRoute = useAppSelector(region.selectCurrentRegion)
  const [showRegioPopUp, setShowRegioPopUp] = useState<boolean>(true)

  return (
    <React.Fragment>
      {showRegioPopUp && (
        <Modal
          title={t.KAMBox_replaced_title}
          onRequestClose={() => setShowRegioPopUp(false)}
        >
          <ModalContainer>
            <ModalInfo>
              <div>{t.KAMBox_replaced}</div>
              <div>{t.KAMBox_replaced_info}</div>
            </ModalInfo>
            <div style={row}>
              <PrimaryButton
                onClick={() => {
                  window.location.replace(
                    'https://regioshop.smart.telekom-dienste.de'
                  )
                }}
              >
                {t.to_Regio}
              </PrimaryButton>

              <PrimaryButton
                onClick={() => setShowRegioPopUp(false)}
                bg="#4C4C4C"
              >
                {t.close}
              </PrimaryButton>
            </div>
          </ModalContainer>
        </Modal>
      )}

      {(selectedRoute || isSupplier)
        ? (
          <SentryRoutes>
            {routes.map(({ path, Component }) => (
              <Route
                path={path}
                Component={Component}
                key={`Component_${path}`}
              />
            ))}
            <Route
              path="/"
              element={isSupplier
                ? (
                  <Navigate
                    replace
                    to="/supplier"
                  />
                )
                : (
                  <Navigate
                    replace
                    to="/dashboard"
                  />
                // eslint-disable-next-line react/jsx-indent
                )
              }
            />
          </SentryRoutes>
        )
        : (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Kam Box</title>
            </Helmet>
            <SentryRoutes>
              {authFeature.map(({ path, Component }) => (
                <Route
                  path={path}
                  Component={Component}
                  key={`R_${path}`}
                />
              ))}
              <Route
                path="*"
                element={<h1>Bitte wählen Sie eine Region aus</h1>}
              />
            </SentryRoutes>
          </>
        )
      }
    </React.Fragment>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  position: relative;
`

const ModalInfo = styled.div`
display: flex;
flex-flow: column;
font-size: 1.2rem;
gap: 1rem;
margin-top: 30px;
`

const row = {
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'space-between'
}
