import styled from 'styled-components'

export const Content = styled.main`
  flex: 1;
  
  max-width: var(--max-width, 2500px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.5rem;
`

const fn = ({ maxWidth = '2500px' }) => maxWidth

export const Container = styled.div<{ maxWidth?: string }>`
  --max-width: ${fn};

  display: flex;
  flex-direction: column;
  max-width: inherit;
  min-height: 100vh;
`
