/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react'

import { FaChevronDown } from 'react-icons/fa'
import styled, { css } from 'styled-components'
import { CheckoutModal } from 'Features/cart/screens/CheckoutModal'

import { IconButton, LocationPin, MenuWithArrow, ShoppingCart } from 'Components'
import { region } from '../redux'
import { useAppDispatch, useAppSelector } from 'Config'
import { FormatMoney } from 'Helpers'
import { cart } from 'Features/cart'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { BsCart3, BsGeoAlt } from 'react-icons/bs'

export function BudgetWidget () {
  const dispatch = useAppDispatch()
  const selectedRegion = useAppSelector(region.selectCurrentRegion)
  const selectedRegionItems = useAppSelector(cart.products)
  const regions = useAppSelector(region.selectRegions)
  const [cartVisible, toggleCart] = useState(false)
  const showCart = useCallback(() => toggleCart(true), [])
  const hideCart = useCallback(() => toggleCart(false), [])

  const setRegion = useCallback((id) => {
    dispatch(region.setCurrentRegion(id))
  }, [dispatch])

  useEffect(() => {
    if (selectedRegion?.region_key) {
      dispatch(cart.fetch())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion?.region_key])
  const selectedOption = Object.entries(regions).filter(([_key, row]) => row.id === selectedRegion?.id)
  const options = selectedOption.concat(Object.entries(regions).filter(([_key, row]) => !!row.managed && row.id !== selectedRegion?.id))
  const substituteRegionExist = Object.values(regions).some((region) => region.substitute)

  const commonMenuProps = {
    title: '',
    label: 'Regionen',
    style: {
      zIndex: 8
    },
    links: options,
    onEmpty: (
      <Li
        role="presentation"
        style={{
          zIndex: 99
        }}
      ><span>Ihnen ist keine Region zugeteilt</span></Li>
    ),
    renderItem: ([key, row]) => row.region_key === selectedRegion?.region_key
      ? options.length === 1
        ? (
          <Li
            role="presentation"
            style={{
              zIndex: 99
            }}
          ><span>Ihnen ist keine Region zugeteilt</span></Li>
        )
        : (
          <LiRegion
            className="selected-region"
            substitute={row.substitute}
            style={{
              zIndex: 99
            }}
            key={key}
          >
            <span>{row.name}</span>
            <span>{FormatMoney(row.rest_budget)}</span>
            <IconButton
              disabled
              style={{
                width: 54,
                justifyContent: 'space-evenly'
              }}
            >
              <span>{row.region_line_items_count || 0}</span>
              <ShoppingCart />
            </IconButton>
          </LiRegion>

        )
      : (
        <LiRegion
          substitute={row.substitute}
          style={{
            zIndex: 99
          }}
          key={key}
          role="button"
          title={`Region: ${row.name}`}
          aria-label={`Region: ${row.name}`}
          tabIndex={0}
          onKeyPress={() => setRegion(row.region_key)}
          onClick={() => setRegion(row.region_key)}
        >
          <span>{row.name}</span>
          <span>{FormatMoney(row.rest_budget)}</span>
          <IconButton style={{
            width: 54,
            justifyContent: 'space-evenly'
          }}
          >
            <span>{row.region_line_items_count || 0}</span>
            <ShoppingCart />
          </IconButton>
        </LiRegion>
      ),
    legendItem: substituteRegionExist && (
      <WidgetLegend>
        <span>Vertretungsregionen sind blau markiert</span>
      </WidgetLegend>
    )
  }
  return (
    <Container
      className="budget-widget"
    >
      <MenuWithArrow
        id={'budget-menu'}
        {...commonMenuProps}
        arrowPosition= {'left'}
        arrowOffset= {20}
      >
        {() => (
          <DarkBtn
            disabled={true}
            aria-haspopup
            tabIndex={0}
            aria-label='Regionen'
            title='Regionen'
          >
            <LocationPin />
            <FaChevronDown />
          </DarkBtn>
        )}
      </MenuWithArrow>
      <MenuWithArrow
        id={'budget-menu-mobile'}
        {...commonMenuProps}
        arrowPosition= {'right'}
        arrowOffset= {148}
      >
        {() => (
          <ButtonMobile
            aria-haspopup
            tabIndex={0}
            aria-label='Regionen'
            title='Regionen'
          >
            <BsGeoAlt size={20} />
          </ButtonMobile>
        )}
      </MenuWithArrow>

      <SubstituteRegion
        substitute={selectedRegion?.substitute || false}
        className='name'
      >{options.length && selectedRegion ? selectedRegion.name : 'Ihnen ist keine Region zugeteilt'}
      </SubstituteRegion>

      <div className="budget">
        {options.length ? FormatMoney(selectedRegion?.rest_budget) : '--'}
      </div>

      <IconButton
        disabled={true}
        id="cart-button"
        aria-haspopup
        title='Warenkorb'
        aria-label='Warenkorb'
        onClick={showCart}
      >
        {selectedRegionItems.reduce((carry, i) => i.requested_amount + carry, 0)} <ShoppingCart />
      </IconButton>
      {cartVisible ? <CheckoutModal toggleCart={hideCart} /> : null}

      <ButtonMobile
        disabled={true}
        id="cart-button-mobile"
        aria-haspopup
        tabIndex={0}
        title='Warenkorb'
        aria-label='Warenkorb'
        onClick={showCart}
      >
        <BsCart3 size={20} />
        <span>
          {selectedRegionItems.reduce((carry, i) => i.requested_amount + carry, 0)}
        </span>
      </ButtonMobile>
    </Container>
  )
}

const ButtonMobile = styled.button`
display: block;
min-width: 2rem;
position: relative;

span {
  background: var(--primary);
  border-radius: 10px;
  bottom: 0;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  left: 50%;
  line-height: 1rem;
  padding: 0.125rem 0.5rem;
  position: absolute;
  top: 50%;
}
`

const DarkBtn = styled.button`
align-items: center;
background: #4c4c4c 0% 0% no-repeat padding-box;
border: 1px solid var(--gray-500,#7c7c7c);
border-radius: 4px 0 0 4px;
color: #fff !important;
display: flex;
font-size: 16px;
height: 100%;
justify-content: center;
padding-top: 4px;

svg {
  margin-right: 3px;
}
`

const Container = styled.div`
  --h: 38px;

  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: auto 2fr 1fr auto;
  height: var(--h, 38px);
  margin: 0 1rem;
  max-width: 90vw;
  min-width: 500px;
  pointer-events: none;
  position: relative;
  text-align: center;

  > div {
    border-bottom: 1px solid var(--gray-500, #7c7c7c);
    border-top: 1px solid var(--gray-500, #7c7c7c);
    font-size: 16px;
    line-height: var(--h);
    min-width: 82px;
    overflow: visible;
    padding: 0 1em;
    white-space: nowrap;
  }

  > * {
    height: 100%;
  }

  .name,
  .budget {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .budget {
    color: var(--success);
  }

  > div:not(:last-of-type) {
    border-right: 1px solid var(--gray-500, #7c7c7c);
  }

  > #cart-button {
    border-radius: 0 4px 4px 0;
    font-size: 16px;
    line-height: 16px;
    width: 64px;
    svg {
      margin-left: 0.5em;
    }
  }

  .selected-region,
  #budget-menu-mobile,
  #cart-button-mobile {
    display: none;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    grid-template-columns: auto auto;
    height: auto;
    column-gap: 1rem;
    min-width: unset;

    section {
      grid-column: 1;
      grid-row: 1;
    }

    #budget-menu,
    .name,
    .budget,
    #cart-button  {
      display: none;
    }

    .selected-region {
      display: flex;
      background-color: var(--gray-350);

      span {
        font-weight: bold! important;
       }
    }

    #budget-menu-mobile {
      display: block;
      nav {
        position: fixed;
        left: 1rem;
        right: 1rem;
      }
    }

    #cart-button-mobile {
      display: block;
      grid-column: 2;
      grid-row: 1;
    }
  }

`

const stylesSubstituteRegion = css`
&::before {
  background: #2aa1dd;
  content: '';
  display: inline-block;
  height: 10px;
  margin: auto 9px;
  vertical-align: middle;
  width: 25px;
}
`

const SubstituteRegion = styled.div<{substitute: boolean}>`
  ${({ substitute }) => substitute ? stylesSubstituteRegion : ''}
`

const Li = styled.div`
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    margin: 2px 0px;
    padding: 0 0.5em;
    text-align: left;
    width: 500px;

    &:hover {
      background-color: #d9d9d9;
    }

    > span {
      color: var(--success);
      font-size: 18px;
      font-weight: 500;
      line-height: 40px;
      margin-right: 0.5em;
    }

    > span:first-of-type {
      color: #222;
      flex: 1;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      border-radius: 4px;
      font-size: 16px;
    }

    @media (max-width: ${breakpoint.size.sm}) {
      width: unset;
    }

    @media (max-width: ${breakpoint.size.xs}) {
      flex-flow: wrap;

      > span {
        flex: 0 1 calc(100% - 54px);
        margin-right: 0;
      }

      > span:first-of-type {
        flex: 1 0 100%;
      }
    }
`

const LiRegion = styled(Li)<{substitute: boolean}>`
  ${({ substitute }) => substitute ? stylesSubstituteRegion : ''}
`

const WidgetLegend = styled.div`
    background-color: #2aa1dd;
    color: #fff;
    cursor: default;
    flex: 1 0 100%;
    font-weight: normal;
    overflow: hidden;
    padding: 0 0.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
`
