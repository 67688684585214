import React from 'react'

export const GoToIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="smart-icon-goToTool"
    width="1em"
    height="1em"
    viewBox="0 0 24 21.231"
    focusable="false"
    stroke="currentColor"
    fill="currentColor"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          d="M0 0h24v21.231H0z"
          className="cls-1"
        />
      </clipPath>
    </defs>
    <g>
      <path
        id="Path_914"
        d="M24.232 28.846H7.616a.234.234 0 0 1-.231-.231v-18a.234.234 0 0 1 .231-.231h16.616a.692.692 0 0 0 0-1.385H7.616A1.617 1.617 0 0 0 6 10.615v18a1.617 1.617 0 0 0 1.615 1.615h16.617a.692.692 0 0 0 0-1.385zM30 19.616l-5.538-5.539v4.846H13.386a.692.692 0 0 0 0 1.385h11.077v4.846z"
        data-name="Path 914"
        transform="translate(-6.001 -9)"
      />
    </g>
  </svg>
)
export const UserIcon = (props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 64 64"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  ><path d="M6,58V53.91A10.87,10.87,0,0,1,16.83,43h30.3A10.87,10.87,0,0,1,58,53.87V58H55V53.91A7.88,7.88,0,0,0,47.15,46H16.87A7.88,7.88,0,0,0,9,53.89V58ZM20,27V18A12.15,12.15,0,0,1,32,6,12.2,12.2,0,0,1,44,18v9A12.2,12.2,0,0,1,32,39,12.15,12.15,0,0,1,20,27Zm3,0a9,9,0,0,0,18,0V18a9,9,0,1,0-18,0Z"></path></svg>
)

export const ShoppingCart = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="1em"
    height="1em"
    viewBox="0 0 59.1 48"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path d={`M49,44c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4C47.2,40,49,41.8,49,44z M23,40c-2.2,0-4,1.8-4,4
c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C27,41.8,25.2,40,23,40z M59,7L54.7,26c-0.2,1.1-1.3,2-2.4,2H19.5l1,6.3c0.1,0.4,0.5,0.7,0.8,0.7
h30.1c0.8,0,1.5,0.7,1.5,1.5S52.3,38,51.4,38H21.3c-1.8,0-3.5-1.4-3.8-3.2l-1.3-8.5c0-0.1-0.1-0.2-0.1-0.3l-1.8-11.6L12.8,3.7
C12.7,3.3,12.3,3,11.9,3H1.5C0.7,3,0,2.3,0,1.5S0.7,0,1.5,0h10.4c1.8,0,3.5,1.4,3.8,3.2L16,5h41.5C58.6,5,59.3,5.9,59,7z M16.5,8
l0.6,4h37.7l0.9-4H16.5z M26,14v11h9V14H26z M37,25h9V14h-9C37,14,37,25,37,25z M24,25V14h-6.6l0,0l1.7,11H24z M54.4,14H48v11h3.9
L54.4,14z`}
    />
  </svg>
)

export const LocationPin = (props) => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 45.1 60"
    height="1em"
    width="1em"
    stroke="currentColor"
    fill="currentColor"
    {...props}
  >

    <path d="M22.5,0C10.1,0,0,10,0,22.5c0,4.8,1.5,9.3,4.1,13L22.5,60L41,35.5c2.6-3.7,4.1-8.1,4.1-13C45.1,10,35,0,22.5,0z
   M38.6,33.7L22.5,55l-16-21.3C4.2,30.4,3,26.5,3,22.5C3,11.7,11.8,3,22.5,3s19.5,8.7,19.5,19.5C42.1,26.5,40.9,30.4,38.6,33.7z
   M22.5,12c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10C32.5,16.5,28.1,12,22.5,12z M22.5,30c-4.4,0-8-3.6-8-8s3.6-8,8-8
  s8,3.6,8,8C30.5,26.4,27,30,22.5,30z"
    />
  </svg>
)

export const DownloadIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7z"
      fill="#e20074"
    />
  </svg>
)

export const ExitIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-8.92 12.58L11.5 17l5-5l-5-5l-1.42 1.41L12.67 11H3v2h9.67l-2.59 2.58z"
      fill="#e20074"
    />
  </svg>
)
