/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable compat/compat */
import { AppAsyncThunk } from 'Config/store'
import { actions } from './reducer'

export interface OrderResponse {
  current_page: number;
  page_size: number;
  regions_orders: {
    region_id: number;
    total: number;
    orders: Partial<Order>[];
  }[]
}

export const fetchOrders = (params): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<OrderResponse>('/orders', { params }).then(({ data }) => {
    data?.regions_orders.forEach(row => {
      // x={ total: 10, currentPage: 5, pageSize: 3, data:[{id:11},{id:12},{id:13}] }
      // fI = (x.currentPage-1) * x.pageSize;
      // o = o || []
      // o.length = x.total
      // o = Array.from(o, (e, idx) => idx>=fI ? x.data[idx-fI] : e||null)
      Array.isArray(row.orders) && row.orders.length &&
      dispatch(actions.preview({
        offset: (data.current_page - 1) * data.page_size,
        regionId: `${row.region_id}`,
        data: row.orders,
        total: row.total
      }))
    })
  })

export const cancelOrder = (id: string): AppAsyncThunk => (_dispatch, _getState, { api }) =>
  api.put(`/orders/${Number(id)}/cancel`).then(({ data }) => {
    console.log(data)
  })

export const fetchOrderDetails = (id: string): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<Order>(`/orders/${id}`).then(({ data }) => {
    dispatch(actions.set([data]))
  })

export const fetchOrdersByRegion = (region_key: string): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<OrderResponse>(`/orders?region_key=${region_key}`).then(({ data }) => {
    data.regions_orders.forEach(row => {
      dispatch(actions.preview({
        data: row.orders,
        regionId: `${row.region_id}`,
        total: row.total
      }))
    })
  })

export const fetchSupplierOrders = (params): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.get<{
    orders: Order[];
    total: number;
  }>('/supplier/orders', { params }).then(({ data }) => {
    dispatch(actions.setSupplier(data))
  })

export const edit = ({ orderId, itemId, supplier_comment, supplier_state }): AppAsyncThunk => (dispatch, _getState, { api }) =>
  api.patch(`/supplier/orders/${orderId}/order_items/${itemId}`, {
    order_item: {
      supplier_comment,
      supplier_state
    }
  }).then(() => {
    dispatch(actions.updateItem({
      orderId,
      itemId,
      data: {
        supplier_comment,
        supplier_state
      }
    }))
  })

export const updateOrder = (type: string, data: any, orderId: string, headers = {}): AppAsyncThunk<any> => (_dispatch, _getState, { api }) => {
  return api.patch(`/orders/${orderId}`, data instanceof FormData
    ? data
    : {
      type,
      data
    }, { headers }).then(({ data }) => data)
}

export const deleteVideo = (videoId: string): AppAsyncThunk<any> => (_dispatch, _getState, { api }) => {
  const data = {
    type: 'DigitalInfoEventOrderInfoVideo',
    videos: [{ id: videoId }]
  }
  return api.delete(`/attachments/${videoId}`, { data })
}
