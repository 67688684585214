/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

// import { DashboardBreadCrumb } from '../components/breadcrumb'
import { Filters } from '../components/Filters'
import { Header, Spinner } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { product } from '../redux'
import { FilteredProductsList } from '../components/FilteredProductsList'
import { useNavigate, useLocation, useParams } from 'react-router'
import { useT } from 'Features/cart/translations'
import { Helmet } from 'react-helmet'

export default function ProductsFeature () {
  const navigate = useNavigate()
  const t = useT()
  const lastKeyword = React.useRef('')
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const categories = useAppSelector(product.selectCategories)
  const params = useParams<{category: string, keyword?: string}>()
  const ALLPRODUCTS = `${params.category !== 'DigitalInfoEvent' ? 'Alle ' : ''}${t.cartTypes[params.category ?? '']}`
  const keyword = params.keyword || ''
  const selectedCategoryName = useAppSelector(product.selectCategory)?.name || ALLPRODUCTS
  const selectedCategoryId = useAppSelector(product.selectCategory)?.id || ''
  const toggleCategory = (category: string) => {
    navigate(location.pathname.split('/').slice(0, 3).join('/'))
    dispatch(product.setFilter({ category }))
  }
  const filteredCategoriesList = Object.values(categories).filter(row => `${row.line_item_type}` === params.category && (row.category_count !== undefined && row.category_count > 0))

  useEffect(() => {
    dispatch(product.setFilter({
      category: lastKeyword.current === params.category ? selectedCategoryId.toString() : '',
      keyword
    }))
    if (params.category) lastKeyword.current = params.category
  }, [dispatch, location])

  useEffect(() => {
    setLoadingProducts(true)
    dispatch(product.fetchProducts()).finally(() => {
      setLoadingProducts(false)
    })
  }, [dispatch, location])

  return (
    <Container>
      {/* <DashboardBreadCrumb /> */}

      <Helmet>
        <title>Warengruppen {t.cartTypes[params.category ?? '']}</title>
      </Helmet>
      <Filters />
      <RowOfTwo>

        <div>
          <Header as="h2">Kategorien</Header>
          <ul>
            {params.category !== 'DigitalInfoEvent' && (
              <li className={`${selectedCategoryName === ALLPRODUCTS ? 'active' : ''}`}>
                <button
                  aria-label={ALLPRODUCTS}
                  onClick={() => toggleCategory('')}
                >{ALLPRODUCTS}</button></li>
            )}
            {filteredCategoriesList.map(row => (
              <li
                className={`${selectedCategoryName === row.name ? 'active' : ''}`}
                key={row.id}
              >
                <button
                  aria-label={`Kategorie ${row.name}`}
                  onClick={() => toggleCategory(`${row.id}`)}
                >{row.name}</button></li>
            ))}
          </ul>
        </div>

        <div>
          <Header
            as="h2"
            role="heading"
            aria-level={2}
          >{selectedCategoryName}</Header>

          {loadingProducts
            ? (<Spinner size={50} />)
            : (
              <FilteredProductsList
                type={params.category ?? ''}
              />
            )}

        </div>
      </RowOfTwo>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;

h2 {
  text-transform: uppercase;
}

ul {
  font-size: 18px;
  letter-spacing: 0;
  list-style: none;
  padding-left: 0;
  text-align: left;

  button {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 12px;
    padding-left: 0;
  }

  .active button {
    color: var(--primary);
  }
}
`
const RowOfTwo = styled.div`
  border: 0;
  display: flex;
  flex-flow: row wrap;

  > *:not(:first-of-type) {
    flex: 1;
  }

  > div:first-of-type {
    min-width: 211px;
    padding-right: 2em;
  }
`
