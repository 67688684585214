import { MenuWithArrow } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { auth } from 'Features/auth/redux'
import { breakpoint } from 'Components/DefaultLayout/breakpoint'
import { FaChevronDown } from 'react-icons/fa'
import { useT } from '../translations'

import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

export function SupplierTargetGroups () {
  const t = useT()
  const dispatch = useAppDispatch()
  const userTargetGroups = useAppSelector(auth.selectUserTargetGroups)
  const selectedTargetGroup = useAppSelector(auth.selectCurrentTargetGroup)

  useEffect(() => {
    if (userTargetGroups?.length && !userTargetGroups?.some((targetGroup) => targetGroup.selected)) {
      setTargetGroup(userTargetGroups[0].id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTargetGroups])

  const setTargetGroup = useCallback((id) => {
    dispatch(auth.setCurrentTargetGroup(id))
  }, [dispatch])

  const options = userTargetGroups
  return (
    <MenuContainer className='target-group'>
      <MenuWithArrow
        classes='target-group-menu'
        title={t.target_groups}
        label={t.target_groups}
        links={options}
        style={{
          zIndex: 8,
          flex: 'none'
        }}
        arrowPosition="right"
        onEmpty={(
          <Li
            role="presentation"
            style={{
              zIndex: 99
            }}
          ><span>{t.no_target_group_assigned}</span></Li>
        )}
        renderItem={(row) => row.id === selectedTargetGroup?.id
          ? options?.length === 1
            ? (
              <Li
                key={row.id}
                role="presentation"
                style={{
                  zIndex: 99
                }}
              ><span>{t.no_other_target_group_assigned}</span></Li>
            )
            : null
          : (
            <Li
              style={{
                zIndex: 99
              }}
              key={row.id}
              role="button"
              tabIndex={0}
              onKeyPress={() => setTargetGroup(row.id)}
              onClick={() => setTargetGroup(row.id)}
              title={`Zielgruppe ${row.name}`}
              aria-label={`Zielgruppe ${row.name}`}
            >
              <span>{row.name}</span>
            </Li>
        // eslint-disable-next-line react/jsx-indent
          )
        }
      >
        {() => (
          <DarkBtn>
            <FaChevronDown />
            <div
              className='name'
            >{options && options.length && selectedTargetGroup ? selectedTargetGroup.name : t.no_target_group_assigned}
            </div>
          </DarkBtn>
        )}
      </MenuWithArrow>

    </MenuContainer>
  )
}

const MenuContainer = styled.div`
display: flex;
flex: unset;
justify-content: flex-end;



@media (max-width: ${breakpoint.size.sm}) {
  nav {
    min-width: 250px;
  }
}
`

const DarkBtn = styled.button`
background: #4c4c4c 0% 0% no-repeat padding-box;
border: 1px solid #4c4c4c;
border-radius: 4px;
color: #fff;
display: flex !important;
font-size: 16px;
justify-content: center;
line-height: 22px;
min-height: 38px;
min-width: 240px !important;
outline: none;
padding: 0.5em 1em;
text-decoration: none;

> svg {
  margin: auto 10px auto 0;
  position: relative;
}
`

const Li = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  margin: 2px 0px;
  padding: 0 0.5em;
  text-align: left;
  width: 300px;

  &:hover {
    background-color: #d9d9d9;
  }

  > span {
    color: var(--success);
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    margin-right: 0.5em;
  }

  > span:first-of-type {
    color: #222;
    flex: 1;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: ${breakpoint.size.sm}) {
    width: 250px;
  }

  @media (max-width: ${breakpoint.size.xs}) {
    flex-flow: wrap;

    > span {
      flex: 0 1 calc(100% - 54px);
      margin-right: 0;
    }

    > span:first-of-type {
      flex: 1 0 100%;
    }
  }
`
