/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

// import DashboardBreadCrumb from '../components/breadcrumb'
import { Header, PrimaryButton } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { batch, shallowEqual } from 'react-redux'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { region } from 'Features/region/redux'
import { representative } from '../redux'
import { PostParam } from '../redux/actions'
import { Helmet } from 'react-helmet'

export default function Feature () {
  const dispatch = useAppDispatch()
  const initial = useRef<PostParam>()
  const navigate = useNavigate()
  const params = useParams<{id: string}>()
  const users = useAppSelector(representative.users)
  const myRegions = useAppSelector(region.myRegions)
  const record = useAppSelector(representative.slice).byId[params.id || -1]
  const [data, setData] = useState<PostParam>({
    substitute_user_id: record?.substitute.id,
    region_id: record?.region.id,
    start_date: new Date(record?.start_date),
    end_date: new Date(record?.end_date)
  })

  useEffect(() => {
    initial.current = {
      substitute_user_id: record?.substitute.id,
      region_id: record?.region.id,
      start_date: new Date(record?.start_date),
      end_date: new Date(record?.end_date)
    }
    setData({ ...initial.current })
  }, [record])

  useEffect(() => {
    batch(() => {
      (!record) && dispatch(representative.fetch())
      dispatch(representative.getUsers())
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  if (!record) {
    alert('cannot access this record...')
    navigate('/representative', { replace: true })
    return null
  }

  const create = (e) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    // eslint-disable-next-line compat/compat
    const data = Object.fromEntries(new FormData(target).entries()) as unknown as PostParam

    if (data.substitute_user_id && data.region_id) {
      data.start_date = new Date(data.start_date || null)
      data.end_date = new Date(data.end_date || null)
      batch(() => {
        dispatch(representative.updateRecord(params.id ?? '', data)).then(() =>
          dispatch(representative.fetch(undefined, true))).then(() => {
          navigate('/representative', { replace: true })
        })
      })
    } else {
      alert('missing data... ! How did you reach here anyway ! *hacker :D')
    }
  }

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }
  const onDateChange = (e) => {
    setData({
      ...data,
      [e.target.name]: new Date(e.target.value)
    })
  }

  return (
    <Container>

      <Helmet>
        <title>{'Vertretung erstellen'}</title>
      </Helmet>
      <Header as="h2">
        Vertretung erstellen
      </Header>
      <Content onSubmit={create}>
        <div>
          <div>
            <span>Ausbaugebiet</span>
            <select
              required
              value={data.substitute_user_id}
              name="substitute_user_id"
              onChange={onChange}
            >
              {users.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >{user.email}</option>
              ))}
            </select>
          </div>

          <div>
            <span>Vertretung</span>
            <select
              required
              value={data.region_id}
              onChange={onChange}
              name="region_id"
            >
              {myRegions.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >{user.name}</option>
              ))}
            </select>
          </div>

          <div>
            <span>Vertretungszeitraum</span>
            <Space>
              <input
                required
                placeholder="from"
                type="date"
                onChange={onDateChange}
                value={(new Date(data.start_date || null)).toISOString().split('T')[0]}
                name='start_date'
              />
              <input
                required
                placeholder="to"
                type="date"
                onChange={onDateChange}
                value={(new Date(data.end_date || null)).toISOString().split('T')[0]}
                name='end_date'
              />
            </Space>
          </div>
        </div>

        <Space style={{ marginTop: '2em' }}>
          <PrimaryButton
            aria-label={'Abbrechen'}
            bg="#4C4C4C"
            color="#FFF"
            as={Link}
            to="/representative"
          >Abbrechen</PrimaryButton>
          <PrimaryButton
            aria-label={'Vertretung anlegen'}
            disabled={ shallowEqual(initial.current, data) }
            type="submit"
          >Vertretung anlegen</PrimaryButton>
        </Space>
      </Content>
    </Container>
  )
}

const Container = styled.div`
border: 0;
border-collapse: collapse;
margin: 2rem auto;
width: 100%;
`
const Space = styled.div`
  display: flex;
  justify-content: space-between;
`
const Content = styled.form`
border: 0;

> div {
  display: flex;
}

> div > div {
  display: flex;
  flex-direction: column;
}

input,
select {
  font-size: 18px;
  height: 40px;
  margin-right: 1em;
  min-width: 200px;
}

@media screen and (max-width: 600px) {
  justify-content: center;
}

`
