/* eslint-disable sonarjs/no-duplicate-string */
import styled from 'styled-components'
export const IconButton = styled.button<{bg?: string}>`
--bgColor:  ${({ bg }) => bg || 'var(--primary)'};
align-items: center;
background-color: var(--bgColor);
border: 1px solid var(--bgColor);
border-radius: 8px;
color: #fff;
display: flex;
flex-flow: row nowrap;
font-size: 16px;
height: 38px;
justify-content: center;
margin: 0;
outline: none;
width: 52px;

&:disabled {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #999999;
}

> svg {
  position: relative;
}
`

export const PrimaryButton = styled.button<{ flat?:boolean, bg?: string, color?: string }>`
${({ flat, bg, color }) => flat
    ? `color:${bg || 'var(--primary)'};
    background-color:${color || '#FFF'};
    border: 1px solid ${color || '#FFF'};`
    : `color: ${color || '#FFF'};
    background-color:${bg || 'var(--primary)'};
    border: 1px solid ${bg || 'var(--primary)'};`
}
border-radius: 4px;
font-size: 16px;
line-height: 22px;
min-height: 38px;
min-width: 52px;
outline: none;
padding: 0.5em 1em;
text-decoration: none;

&:disabled {
  background-color: rgba(0, 0, 0, 0.54);
  border: 1px solid #757575;
  pointer-events: none;
}

> svg {
  margin-left: 0.5em;
  position: relative;
}
`

export const ResetButton = styled.button`
  background-color: #D3D3D3;
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  color: #5C5C5C;
  min-width: 5em;
`
