import { Card, IconButton, Pagintaion, ShoppingCart, Modal } from 'Components'
import { useAppDispatch, useAppSelector } from 'Config'
import { cart } from 'Features/cart'
import { FormatMoney } from 'Helpers'
import React, { useEffect, useState } from 'react'
import { FaInfo } from 'react-icons/fa'
import { FiCheck } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { product } from '../redux'
import ImageSlider from './Image'
import ImageZoom from './ImageZoom'
import { useT } from '../translations'

const PAGE_SIZE = 20

export const FilteredProductsList: React.FC<{type: string}> = ({ type }) => {
  const t = useT()
  const [page, setPage] = useState(0)
  const [filteredIds, setIds] = useState<string[]>([])
  const [zoomImg, toggleZoomImg] = useState<boolean>(false)
  const [productPopup, setProductPopup] = useState<{title: string, pictures: LineItemPicture[], badge?: boolean}>({
    title: '',
    pictures: []
  })
  const location = useLocation()
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname
  })
  useEffect(() => {
    setRoute((prev) => ({
      to: location.pathname,
      from: prev.to
    }))
  }, [location])

  const [data, setData] = useState<Record<string, any>>({})
  const ids = useAppSelector(product.selectFilteredProducts)
  const products = useAppSelector(product.selectProducts)
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()
  useEffect(() => { setPage(0) }, [type])
  const onChange = id => (e) => {
    setData({
      ...data,
      [id]: e.target.value
    })
  }
  const addToCart = (uid: string, amount: number) => {
    dispatch(cart.addItemToCart({
      id: products[uid].id,
      type: products[uid].line_item_type,
      amount: amount || 1,
      unit: Number(products[uid].packaging_unit || 1)
    })).then(() => {
      setData({
        ...data,
        [uid]: ''
      })
    }).catch(e => {
      if (typeof e === 'string') {
        addToast(<div role="alert">{e}</div>, {
          appearance: 'error',
          autoDismiss: true
        })
      }
    })
  }
  useEffect(() => {
    setIds(Array.from(ids).filter(id => products[id].line_item_type === type))
  }, [type, ids, products])

  const ribbon: Ribbon = {
    location: 'top-right',
    content: <><FiCheck size={13} /> {t.product_ordered}</>
  }

  const list = filteredIds.slice(PAGE_SIZE * page, PAGE_SIZE * (page + 1))
  return (
    <>
      {zoomImg && (
        <Modal
          title={productPopup.title}
          onRequestClose={() => {
            toggleZoomImg(false); setProductPopup({
              title: '',
              pictures: [],
              badge: false
            })
          }}
        >
          <Details
            role="presentation"
          >
            <ImageSlider
              src={productPopup.pictures}
              className="zoom"
              name={productPopup.title}
              {...(productPopup.badge && {
                ribbon
              })}
            /></Details>
        </Modal>
      )}
      <CardsContainer>

        {list.map(id => (
          <Card
            key={id}
            nopadding
          >
            <Grid>
              <ImageContainer>
                <ImageZoom
                  title={products[id].name}
                  src={products[id]?.line_item_pictures}
                  onClickZoom={() => {
                    toggleZoomImg(true)
                    setProductPopup({
                      title: products[id].name,
                      pictures: products[id]?.line_item_pictures,
                      badge: products[id].badge
                    })
                    setRoute({
                      to: location.pathname,
                      from: location.pathname
                    })
                  }}
                  {...(products[id].badge && {
                    ribbon
                  })}
                />
              </ImageContainer>
              <h3 className="product-name">{products[id].name}</h3>
              <span
                aria-label="price"
                style={{ color: 'var(--success, #46a800) ' }}
              >{FormatMoney(products[id].price)}</span>
              <Actions
                onSubmit={(e) => {
                  e.preventDefault()
                  addToCart(id, data[id])
                }}
              >
                <IconButton
                  as={Link}
                  ref={iconButton => iconButton && `/products/${type}/${id}` === route.from && iconButton.focus()}
                  role="button"
                  title={products[id].name + ' Information'}
                  aria-label={products[id].name + ' Information'}
                  to={'/products/' + type + '/' + id}
                  bg={'#4C4C4C'}
                >
                  <FaInfo />
                </IconButton>
                <input
                  value={data[id] || ''}
                  onChange={onChange(id)}
                  type="number"
                  id={`quantity_${products[id].artno}`}
                  title = {`Maximal ${products[id].max_per_order || 99} Produkte`}
                  aria-label={products[id].name + ' Menge'}
                  pattern="[0-9]*"
                  placeholder={`<${products[id].max_per_order || 99}`}
                  min={products[id].packaging_unit || 1}
                  max={products[id].max_per_order || 99}
                  step={products[id].packaging_unit || 1}
                  required
                />
                <IconButton
                  type="submit"
                  disabled={products[id].badge}
                  title={products[id].name + ' in den Warenkorb legen'}
                  aria-label={products[id].name + ' in den Warenkorb legen'}
                >
                  <ShoppingCart />
                </IconButton>
                <legend>{`Maximal ${products[id].max_per_order || 99} Produkte`}</legend>
                <legend className={(products[id].packaging_unit || 1) === 1 ? 'opacity-0' : 'opacity-1'}>{`Packungen mit ${products[id].packaging_unit || 1}`}</legend>
              </Actions>

            </Grid>
          </Card>
        ))}
      </CardsContainer>
      <Pagintaion
        currentPage={page}
        onSelect={setPage}
        totalCount={filteredIds.length}
        pageSize={PAGE_SIZE}
      />
    </>
  )
}

const ImageContainer = styled.aside`
height: 100%;
> div {
  height: 100%;
  position: relative;
}
`

const Details = styled.section`
border: 0 !important;
display: flex;
font-size: 1.5rem;
line-height: 44px;
margin-top: 0;
position: relative;

button {
  font-size: 1em;
}

figure {
  border: 0;
  height: 100%;
  margin: 0;
  min-height: 200px;
  min-width: 200px;
  padding: 0.25em;

  img {
    background-color: #ccc;
    display: block;
    margin: 0;
    max-height: 100%;
    width: 100%;
  }
}
`
const Grid = styled.div`
align-items: center;
background-color: rgba(0, 0, 0, 0.1);
display: grid;
flex: 1;
grid-template-rows: 50% 40px 40px 1fr;
text-align: center;

h3,
span {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.product-name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin: 1px;
  overflow: hidden;
}
`
export const CardsContainer = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: flex-start;

/* cards */
> * {
  display: flex;
  flex-flow: column nowrap;
  margin-right: 1.5em;
  min-height: 340px;
  overflow: hidden;
  width: 210px;
}
`
export const Actions = styled.form`
align-items: center;
display: flex;
flex-flow: wrap;
justify-content: space-evenly;
padding: 0 0.5em;
row-gap: 0.7em;

> * {
  flex-shrink: 1;
}

> input {
  flex: 1 0 auto;
  font-size: 18px;
  height: 38px;
  max-width: 65px;
  text-align: center;
}

> button {
  border-radius: 4px;
}

> legend {
  flex: 1 1 100%;

  &.opacity-0 {
    background-color: lime;
    opacity: 0;
  }
}

`
