/* eslint-disable sonarjs/no-duplicate-string */
import axios, { AxiosError } from 'axios'
import { auth } from 'Features/auth/redux'
import * as Sentry from '@sentry/react'
import { store } from './store'
import { REACT_APP_KAMBOX_API_URL, REACT_APP_KAMBOX_URL, REACT_APP_TIMEOUT } from 'Env'

export const AUTH_REFRESH_KEY = '_session_refreshtoken'
export const AUTH_TOKEN_KEY = '_session_authtoken'

const api = axios.create({
  baseURL: REACT_APP_KAMBOX_API_URL || 'http://localhost:5000/api/v1/',
  timeout: Number(REACT_APP_TIMEOUT) || 5000
})

api.defaults.headers.common.Authorization = 'Bearer nothing'
// api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
api.defaults.headers.common['Content-Type'] = 'application/json'

// api.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded'
api.interceptors.response.use(undefined, oauthInterceptor)
export default api

let refreshing = null as any
async function oauthInterceptor (error: AxiosError & {isRetry?: boolean}) {
  // intercept errors
  if (error.response?.status !== 401) {
    throw error
  }

  if (error.response?.status === 401) {
    Sentry.captureException(error)
  }

  if (error.isRetry) {
    store.dispatch(auth.set(null))
    throw error
  }

  const refresh_token = store.getState().auth.user?.refresh_token
  refreshing = refreshing || (refresh_token
    ? api.post(REACT_APP_KAMBOX_URL + 'smart_sso_layer/api/v1/resources/token', {
      grant_type: 'refresh_token',
      refresh_token
    }).then(r => r.data).catch(() => null)
    // eslint-disable-next-line compat/compat
    : Promise.resolve(null))

  const u = await refreshing as User
  refreshing = null

  if (!u || !u.access_token) {
    store.dispatch(auth.set(null))
    throw error
  }
  api.defaults.headers.common.Authorization = `Bearer ${u.access_token}`
  error.isRetry = true
  if (error.config) {
    error.config.headers.Authorization = `Bearer ${u.access_token}`
  }
  store.dispatch(auth.update(u))
  return api(error.config ?? {})
}
