import Feature from './screens/DocumentsPage'

const routes = [
  {
    path: '/pages/documents',
    Component: Feature
  }
]

export default routes
